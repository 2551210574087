import React, { useRef, useEffect, useState, useCallback } from "react";
import TM_likeIcon from "./../Assets/Images/Like Icon.png";
import TM_Ellipse from "./../Assets/Images/Ellipse 25.png";
import TM_bucketlist from "./../Assets/Images/bucketlist.png";
import TM_ShareIcon from "./../Assets/Images/Share Icon.png";
import TM_Vector19 from "./../Assets/Images/Vector 19.png";
import likecon from "./../Assets/Images/likecon.png";
import locationpointer from "./../Assets/Images/locationpointer .png";
import { useSearch } from "./../Assets/queryprovider";
import { PostLikes } from "./../Assets/PostLikes";
import PopularPost from "../Components/UserHomepage/PopularPosts/PopularPost";
import Config from "./../Assets/Config";
import like from "./../Assets/Images/Like.png";
import bucketList from "./../Assets/Images/bucketlist.png";
import Location from "./../Assets/Images/loaction_pointer.png";
import PopularAndTrending from "../Components/UserHomepage/Popular_Trending/PopularAndTrending";
import Backbutton from "./../Assets/Images/Backbutton.png";
import BoardCreationModal from "../Components/BucketList/BoardCreationModal/BoardCreationModal";
import "./SearchPostandBoards.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import BucketListPopup from "../Components/BucketList/MyFiles/Posts/BucketListPopup";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const SearchPostandBoards = () => {
  console.log("In Search Filter component");
  const [isExpanded, setIsExpanded] = useState(false);
  const username = sessionStorage.getItem("username");
  const { query, setQuery, removeQuery } = useSearch();
  const [showLogout, setShowLogout] = useState(false);
  const [likedBoards, setLikedBoards] = useState({});
  const [boardsData, setBoardsData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [boardName, setBoardName] = useState(""); // Local state for board name
  const [showPopularPost, setShowPopularPost] = useState(false);
  const [popularPostsData, setPopularPostsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const toggleLogoutOption = () => {
    setShowLogout(!showLogout);
  };
  const location = useLocation();
  const {
    filterSearch: initialFilterSearch = "",
    filterFlag: initialFilterFlag = "",
  } = location.state || {};

  // Local state that corresponds to the values in location.state
  const [filterSearch, setFilterSearch] = useState(initialFilterSearch);
  const [filterFlag, setFilterFlag] = useState(initialFilterFlag);

  const [filterList, setFilterList] = useState([]);

  console.log("filterList in search page: ", filterList);

  // Handlers might now accept a single new filter
  const handleLocationClick = (location) => {
    console.log("In Search handle location:", location);
    navigate("/filtersearch", {
      state: {
        filterSearch: location,
        filterFlag: "l",
      },
    });
  };

  useEffect(() => {
    setFilterSearch(initialFilterSearch);
    setFilterFlag(initialFilterFlag);
  }, [initialFilterSearch, initialFilterFlag]);

  const handleHashtagClick = (hashtag) => {
    console.log("In Search handle hashtag:", hashtag);
    navigate("/filtersearch", {
      state: {
        filterSearch: hashtag,
        filterFlag: "h",
      },
    });
  };

  const storeData = (key, value) => {
    const now = new Date();
    // `expiry` is current time + expiry in milliseconds
    const expiry = now.getTime() + 200 * 1000;
    const data = { value, expiry };
    sessionStorage.setItem(key, JSON.stringify(data));
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem("filterList");
    console.log("storedData", storedData);
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (new Date().getTime() < parsedData.expiry) {
        if (Array.isArray(parsedData.value)) {
          // Remove duplicates by converting the array to a Set and back to an array
          const uniqueValues = Array.from(new Set(parsedData.value));
          setFilterList(uniqueValues);
        } else {
          // If parsedData.value is not an array, just set it directly
          setFilterList(parsedData.value);
        }
      } else {
        // If the data has expired, remove it from sessionStorage
        sessionStorage.removeItem("filterList");
      }
    }
  }, []);

  // const handleRemoveItem = (item) => {
  //   console.log("In Handle Remove");
  //   const updatedList = filterList.filter((filterItem) => filterItem !== item);
  //   console.log(updatedList);

  //   if (updatedList.length === 0) {
  //     navigate("/home"); // Navigate to home if the list is empty
  //   } else {
  //     setFilterList(updatedList);
  //     storeData("filterList", updatedList);
  //     navigate("/filtersearch", {
  //       state: { filterFlag: "r", filterSearch: "" },
  //     });
  //   }
  // };

  const firstLetter = username ? username.charAt(0).toUpperCase() : "";

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  const [popuarPost, setRecentPosts] = useState([]);
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDatarecent, setImageDatarecent] = useState([]);
  const [likedPostss, setLikedPosts] = useState({});

  const fetchPosts = useCallback(async () => {
    setIsLoading(true);

    const userId = sessionStorage.getItem("user_id"); // Assuming the user ID is stored in sessionStorage
    // setFilterList(retrieveData('filterList'))
    // console.log("In Search Filter:", "filterSearch:", {filterSearch },  "filterFlag:", { filterFlag },  "filterList:", retrieveData('filterList'));
    const params = {
      filter_search: filterSearch,
      filter_flag: filterFlag,
      "filter_list[]": filterList,
      user_id: userId,
    };
    console.log("Search Filter params:", params);
    const type = "hashtag";
    const baseUrl = `${Config.apiBaseUrl}hashtags/${type}`;

    try {
      const response = await axios.get(baseUrl, { params });
      console.log("Response data:", response.data);
      if (response.data) {
        setImageDatarecent(response.data.filtered_posts);
        setBoardsData(response.data.filtered_boards);
        setFilterList(response.data.filtered_list);
        storeData("filterList", response.data.filtered_list);
        console.log("location state on API call:", response.data.filtered_list);
        window.scroll(0, 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [filterSearch, filterFlag]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  const handleRemoveItem = (item) => {
    console.log("In Handle Remove");
    const updatedList = filterList.filter((filterItem) => filterItem !== item);
    console.log(updatedList);

    if (updatedList.length === 0) {
      navigate("/home"); // Navigate to home if the list is empty
    } else {
      console.log("calling search filter", updatedList);
      const newFilterFlag = Math.random().toString(36).substr(2, 5); // Generates a random string
      setFilterList(updatedList);
      storeData("filterList", updatedList);
      console.log("calling search filter2", filterList);
      navigate("/filtersearch", {
        state: { filterFlag: newFilterFlag, filterSearch: "" },
      });
    }
  };

  const handleLikeClick = async (postId, isFromPopup = false) => {
    try {
      const isLiked = !likedPostss[postId]; // Determine the new liked status

      // Update the liked posts state
      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        [postId]: isLiked,
      }));

      // Update the image data state
      setImageDatarecent((currentImageDatarecent) =>
        currentImageDatarecent.map((image) => {
          if (image.post_id === postId) {
            return {
              ...image,
              liked: isLiked,
              likes_count: image.likes_count + (isLiked ? 1 : -1),
            };
          }
          return image;
        })
      );

      // If the like action came from the popup, update the selectedImage state
      if (isFromPopup && selectedImage && selectedImage.post_id === postId) {
        setSelectedImage((prevImage) => ({
          ...prevImage,
          liked: isLiked,
          likes_count: prevImage.likes_count + (isLiked ? 1 : -1),
        }));
      }
      // Make the API call to update the like status
      const likeData = {
        like_type_id: postId,
        like_type: "post",
        public_id: sessionStorage.getItem("public_id"),
      };
      console.log("likeData", likeData);
      await PostLikes(likeData);
    } catch (error) {
      console.error("Error handling like:", error);
    }
  };

  // ########################################################################
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [boards, setBoards] = useState([]);

  const publicId = sessionStorage.getItem("public_id");
  useEffect(() => {
    async function fetchBoards() {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/user/${publicId}`
      ); //     const data = await response.json();
      console.log("boardsdata", response.data);
      setBoards(response.data.data);
    }

    fetchBoards();
  }, []);

  const togglePopup = (postId) => {
    console.log(
      "postid",
      postId,
      imageDatarecent,
      selectedPostId === postId,
      selectedPostId
    );
    const selectedImageDatarecent = imageDatarecent.find(
      (image) => image.post_id === postId
    );

    if (selectedPostId === postId) {
      // If the current post is already selected, hide the popup
      setIsPopupVisible(false);
      setSelectedPostId(null); // Reset the selected post ID
      // setSelectedImage(null); // Reset the selected image data
    } else {
      // Show the popup for the new post
      setIsPopupVisible(true);
      setSelectedPostId(postId); // Set the new post ID
      setSelectedImage(selectedImageDatarecent); // Reset the selected image data
    }
  };

  // ########################################################################
  // Popular Post Code Start

  const postImage = (image) => {
    //
    setSelectedImage(image);
  };

  const postImageCost = () => {
    setSelectedImage(null);
  };

  const [commentInputs, setCommentInputs] = useState({});
  // State to keep track of comments fetched from the server
  const [postComments, setPostComments] = useState({});
  const [popupCommentInput, setPopupCommentInput] = useState("");
  const [activeComment, setActiveComment] = useState(null); // Track the active comment being replied to

  const [replies, setReplies] = useState([]);
  const [imageData, setImageData] = useState([]);

  const [replyTexts, setReplyTexts] = useState({});
  const [newReplyPosted, setNewReplyPosted] = useState(false);
  const [repliesVisibility, setRepliesVisibility] = useState({});
  const [postLike, setPostLike] = useState({
    like_type_id: "",
    like_type: "post",
    public_id: sessionStorage.getItem("public_id"), // Use sessionStorage to get the public_id value
  });
  const handleReplyTextChange = (commentId, text) => {
    setReplyTexts((prevReplyTexts) => ({
      ...prevReplyTexts,
      [commentId]: text,
    }));
  };

  // Update the reply click to set the active comment and focus the input
  const commentInputRef = useRef(null); // Ref for focusing the input on reply click

  const handleReplyClick = (commentId) => {
    setActiveComment(commentId);
    if (commentInputRef.current) {
      commentInputRef.current.focus();
    }
  };

  const handleCommentChange = (e, postId) => {
    const newComment = e.target.value;
    setCommentInputs((prevInputs) => ({
      ...prevInputs,
      [postId]: newComment,
    }));
  };

  async function handleSeeMoreClick(post_id, commentId) {
    // Toggle visibility off if currently showing and no new reply has been posted
    if (repliesVisibility[commentId] && !newReplyPosted) {
      setRepliesVisibility((prev) => ({ ...prev, [commentId]: false }));
      return;
    }
    // Fetch the latest replies
    try {
      const response = await fetch(
        `${Config.apiBaseUrl}posts/comments/replies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            post_id: post_id,
            commentId: commentId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedReplies = await response.json();
      setReplies((prevReplies) => ({
        ...prevReplies,
        [commentId]: fetchedReplies.data,
      }));
      // Ensure replies are visible
      setRepliesVisibility((prev) => ({ ...prev, [commentId]: true }));
      // Reset new reply flag if it was set
      if (newReplyPosted) setNewReplyPosted(false);
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  }

  // const handleCommentSubmit = async (postId, e) => {
  //   if (e) e.preventDefault(); // This checks if e is defined and calls preventDefault on it
  //   const commentText = commentInputs[postId] || "";
  //   console.log("commentText", commentText);
  //   if (!commentText.trim()) return; // Don't submit empty comments
  //   // Create the comment object to send to the server
  //   const postData = {
  //     post_id: postId,
  //     parent_comment_id: null,
  //     public_id: postLike.public_id,
  //     comment_text: commentText,
  //   };
  //   console.log("postData", postData);
  //   try {
  //     await axios.post(`${Config.apiBaseUrl}posts/comments`, postData);
  //     setCommentInputs((prevComments) => ({ ...prevComments, [postId]: "" }));
  //     fetchCommentsByPostId(postId);
  //   } catch (error) {
  //     console.error("Error submitting comment:", error);
  //   }
  // };

  const handleCommentSubmit = async (postId, e) => {
    if (e) e.preventDefault(); // This checks if e is defined and calls preventDefault on it
    const commentText = commentInputs[postId] || "";
    console.log("commentText", commentText);
    if (!commentText.trim()) return; // Don't submit empty comments

    // Create the comment object to send to the server
    const postData = {
      post_id: postId,
      parent_comment_id: null,
      public_id: postLike.public_id,
      comment_text: commentText,
    };
    console.log("postData", postData);

    try {
      await axios.post(`${Config.apiBaseUrl}posts/comments`, postData);
      setCommentInputs((prevComments) => ({ ...prevComments, [postId]: "" }));
      fetchCommentsByPostId(postId);

      // Fetch new comments to update the comment count
      const { data } = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`
      );
      const newCommentsCount = data.data.length;

      setImageData((prevData) =>
        prevData.map((post) =>
          post.post_id === postId
            ? { ...post, comments_count: post.comments_count + 1 }
            : post
        )
      );

      if (selectedImage && selectedImage.post_id === postId) {
        setSelectedImage((prev) => ({
          ...prev,
          comments_count: newCommentsCount,
        }));
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleKeyPress = (e, postId) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent form submission if enter key is pressed
      handleCommentSubmit(postId, e);
    }
  };
  const handlePopupCommentChange = (e) => {
    setPopupCommentInput(e.target.value);
  };

  async function handleSeeMoreClick(post_id, commentId) {
    // Toggle visibility off if currently showing and no new reply has been posted
    if (repliesVisibility[commentId] && !newReplyPosted) {
      setRepliesVisibility((prev) => ({ ...prev, [commentId]: false }));
      return;
    }
    // Fetch the latest replies
    try {
      const response = await fetch(
        `${Config.apiBaseUrl}posts/replies/${post_id}/${commentId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedReplies = await response.json();
      setReplies((prevReplies) => ({
        ...prevReplies,
        [commentId]: fetchedReplies.data,
      }));
      // Ensure replies are visible
      setRepliesVisibility((prev) => ({ ...prev, [commentId]: true }));
      // Reset new reply flag if it was set
      if (newReplyPosted) setNewReplyPosted(false);
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  }

  const handlePopupCommentSubmit = async (postId, e) => {
    e.preventDefault();
    const commentText = popupCommentInput;
    if (!commentText.trim()) return;
    const postData = {
      post_id: postId,
      parent_comment_id: null,
      public_id: postLike.public_id,
      comment_text: commentText,
    };
    // If replying (activeComment is not null), add parent_comment_id
    if (activeComment) {
      postData.parent_comment_id = activeComment;
    }
    console.log("replay postData", postData);
    try {
      await axios.post(`${Config.apiBaseUrl}posts/comments`, postData);
      setPopupCommentInput("");

      await fetchCommentsByPostId(postId);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };
  const fetchCommentsByPostId = async (postId) => {
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`
      );
      setPostComments((prevComments) => ({
        ...prevComments,
        [postId]: response.data.data,
      }));
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  const handlePostClick = (postId) => {
    fetchCommentsByPostId(postId);
  };

  // ########################################################################
  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsPopupVisible(false);
        setSelectedPostId(null); // Reset the selected post ID
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupVisible]);

  //   ----------------------------------------------------------------------------------------------------------------------------

  //   const [likedPostss, setLikedPosts] = useState({});
  const handleLikeBoard = async (boardId) => {
    console.log("######################3", boardId);
    try {
      const isLiked = !likedPostss[boardId]; // Determine the new liked status

      // Update the liked posts state
      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        [boardId]: isLiked,
      }));

      // Update the boards state
      setBoardsData((currentBoards) =>
        currentBoards.map((board) => {
          if (board.board_id === boardId) {
            return {
              ...board,
              liked_by_current_user: isLiked,
              like_count: isLiked ? board.like_count + 1 : board.like_count - 1,
            };
          }
          return board;
        })
      );

      // Make the API call to update the like status
      const likeData = {
        like_type_id: boardId,
        like_type: "board",
        public_id: sessionStorage.getItem("public_id"),
      };
      await PostLikes(likeData);
    } catch (error) {
      console.error("Error updating like status: ", error);
    }
  };

  const navigatePopularBordPosts = async (board_id, board_name) => {
    console.log(`Navigating to board with ID: ${board_id}`);

    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/board/${board_id}`
      );
      const boardPostsData = response.data.data;
      if (!boardPostsData || boardPostsData.length === 0) {
        setPopupMessage(
          `No board items available for Board Name: ${board_name}`
        );
        setShowPopup(true);
      } else {
        setBoardName(board_name); // Set the local state
        setPopularPostsData(boardPostsData);
        setShowPopularPost(true);
        window.scroll(0, 0);
      }
    } catch (error) {
      console.error("Error fetching board posts:", error);
      setPopupMessage("Error fetching data. Please try again later.");
      setShowPopup(true);
    }
  };
  console.log("Location.state search page end: ", location.state);
  const [currentView, setCurrentView] = useState("boards"); // New state to keep track of the current view

  return (
    <div className="row">
      <div className="sech-main-d-flex">
        {/* boards data strats headers */}
        {isLoading && (
          <div className="loading-spinner">
            <div className="spinner"></div>
          </div>
        )}

        <div className="col-8 body-margin-container search-post-mbl-rep">
          <div className="searchfull-bdy">
            <div className="filtere_serach">
              {filterList.length > 0
                ? filterList.map((item, index) => (
                    <div className="filter_search_diaplay" key={index}>
                      {item}
                      <button
                        onClick={() => handleRemoveItem(item)}
                        className="search-x-buttom"
                      >
                        &times;
                      </button>
                    </div>
                  ))
                : ""}
            </div>

            <div>
              <div className="homebody ">
                {showPopup && (
                  <div className="popup-overlay2">
                    <div className="popup-content2">
                      <button
                        className="close-button"
                        onClick={() => setShowPopup(false)}
                      >
                        &times;
                      </button>

                      {popupMessage}
                    </div>
                  </div>
                )}

                {showPopularPost ? (
                  <div>
                    <div className="d-flex">
                      <span
                        onClick={() => setShowPopularPost(false)}
                        style={{ fontSize: "24px" }}
                      >
                        <img src={Backbutton} alt="backbutton" />
                      </span>
                      <h2 style={{ color: "#FFF" }} className="ms-3">
                        {boardName}
                      </h2>
                    </div>
                    <PopularPost
                      hashtagData={{
                        data: popularPostsData,
                        name: "board",
                      }}
                    />
                  </div>
                ) : (
                  <div className="">
                    <div className="">
                      <div className="">
                        {boardsData.length > 0 && (
                          <h2 className="trip_board_tittle">Boards</h2>
                        )}
                        {boardsData.map((board, index) => (
                          <div className="Popular_boards_box" key={index}>
                            <div className="profile-info">
                              <div className="profile-pic">
                                {board.user.user_profile_logo ? (
                                  <img
                                    src={board.user.user_profile_logo}
                                    alt="Profile"
                                  />
                                ) : (
                                  <div className="default_profileimage"></div>
                                )}
                              </div>
                              <div className="username">
                                {board.user.username || "Username"}
                              </div>
                              {board.location ? (
                                <div className="location">
                                  <img src={Location} alt="Location" />
                                  {board.location}
                                </div>
                              ) : (
                                " "
                              )}
                            </div>
                            <div
                              className="board-image"
                              onClick={() =>
                                navigatePopularBordPosts(
                                  board.board_id,
                                  board.title
                                )
                              }
                            >
                              <img src={board.image_url} alt="Board" />
                            </div>
                            <div className="overlay-board">
                              <div
                                className="icon-container"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleLikeBoard(board.board_id);
                                }}
                              >
                                <img
                                  src={
                                    likedPostss[board.board_id] ? likecon : like
                                  }
                                  alt="Like icon"
                                />
                                <span className="count">
                                  {board.like_count}
                                </span>
                              </div>
                              <div className="icon-container">
                                <img
                                  src={bucketList}
                                  alt="Bucket List"
                                  className="bucket-icon"
                                />
                                <span className="count">
                                  {board.bucket_list_count}
                                </span>
                              </div>
                            </div>
                            {board.title > 0 && (
                              <div className="board-info">
                                <div className="public-board-name">
                                  {board.title}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Bppard ends Hear______________________________________________________________________________________________________________ */}
            <div style={{ color: "#FFF", paddingLeft: "20px" }}>
              <div className=" main">
                {imageDatarecent.length > 0 && (
                  <h2 className="trip_board_tittle">Posts</h2>
                )}
                {imageDatarecent.map((image) => (
                  <div
                    className="cardmain shadow-lg  mb-3 bg-body rounded"
                    key={image.id}
                    onClick={() => handlePostClick(image.post_id)}
                  >
                    <div className="row  mainprofileimg">
                      <div className="col-1 d-flex ">
                        <img
                          className="roundedImg"
                          src={image.user.user_profile_logo}
                          alt="Card image"
                        />

                        <span className=""> {image.user.user_name}</span>
                      </div>
                      <div className="col mt-2 search-pro-name">
                        {image.user.username}
                      </div>
                      <div className="col  mt-3" style={{ textAlign: "right" }}>
                        <label className="">{image.posted_time}</label>
                      </div>
                      <div className="col-1 mt-3">
                        {/* <label>...</label> */}
                      </div>
                    </div>

                    {image.post_type === "post" ? (
                      <div
                        className="card-img-top-container"
                        style={{ position: "relative" }}
                      >
                        {/* <img
                          className="card-img-top"
                          src={image.image_url}
                          alt="Card"
                          onClick={() => postImage(image)}
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop3"
                        /> */}
                        {image.image_url.includes("youtube.com") ? (
                          <ReactPlayer
                            url={image.image_url}
                            className="card-img-top"
                            controls
                            onClick={() => postImage(image)}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          />
                        ) : (
                          <img
                            className="card-img-top"
                            src={image.image_url}
                            alt="Card"
                            onClick={() => postImage(image)}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                          />
                        )}
                        <div
                          className="search-pro-img-tp-landmark"
                          onClick={() => handleLocationClick(image.location)}
                          style={{
                            position: "absolute",
                            bottom: "8px",
                            left: "8px",
                            color: "white",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            padding: "3px 10px",
                            borderRadius: "20px",
                            fontSize: "0.9em",
                            cursor: "pointer",
                          }}
                        >
                          <img src={locationpointer} alt="Location pointer" />
                          {image.location}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="search-card-txt-cmts "
                        style={{
                          padding: "20px",
                          color: "#FFF",
                          background: "#3a3a3a3a",
                          position: "relative",
                          overflowY: "scroll",
                          scrollbarWidth: "thin",
                          scrollbarColor: "transparent transparent",
                          height: "350px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop3"
                        onClick={() => postImage(image)}
                      >
                        <h3>{image.title}</h3>
                        <div>
                          {image.description
                            .split(/(?=\d+\.\s)/)
                            .map((desc, index) => (
                              <p key={index}>{desc}</p>
                            ))}
                        </div>
                      </div>
                    )}

                    <div className="card-body">
                      <div className="row   rowcomments">
                        <div className="col-12 mb-2 mt-3">
                          <div className="row search-row-comecnt-box">
                            <div
                              className="col-2"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={
                                  likedPostss[image.post_id]
                                    ? likecon
                                    : TM_likeIcon
                                }
                                onClick={() => {
                                  handleLikeClick(image.post_id);
                                }}
                                alt="Like"
                              />
                              <span className="ms-2">{image.likes_count}</span>
                            </div>

                            <div className="col-2 d-flex">
                              <img className="TM_Ellipse" src={TM_Ellipse} />{" "}
                              <span className="ms-2">
                                {image.comments_count}
                              </span>
                            </div>
                            <div className="col-2 iconcontainer">
                              <img
                                className="TM_bucketlist"
                                src={TM_bucketlist}
                                onClick={() => togglePopup(image.post_id)}
                                alt="Bucket list icon"
                              />

                              <span className="ms-2">
                                {image.bucket_list_count}
                              </span>
                              {isPopupVisible &&
                                selectedPostId === image.post_id && (
                                  <div ref={popupRef}>
                                    <BucketListPopup
                                      boards={boards}
                                      postId={selectedPostId}
                                      selectedImageData={selectedImage}
                                      fetchPosts={fetchPosts}
                                    />
                                  </div>
                                )}
                            </div>
                            <div className="col-6">
                              {/* <img className="TM_ShareIcon" src={TM_ShareIcon} /> */}
                            </div>
                          </div>
                        </div>
                        <div style={{ color: "white" }}>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "baseline",
                            }}
                          >
                            {/* Conditional rendering based on post type */}
                            {image.post_type !== "note" && (
                              <>
                                <p
                                  className="card-text"
                                  style={{ margin: 0, paddingRight: "10px" }}
                                >
                                  {isExpanded
                                    ? image.description
                                    : `${image.description.slice(0, 100)}...`}
                                </p>

                                {Array.isArray(image.hash_tags) ? (
                                  image.hash_tags.map((tag, index) => (
                                    <span
                                      className="search-card-hashtag"
                                      key={index}
                                      onClick={() => handleHashtagClick(tag)}
                                      style={{
                                        marginLeft: index > 0 ? "10px" : "0",
                                        whiteSpace: "nowrap",
                                        cursor: "pointer",
                                        color: "#D2C2FF",
                                      }}
                                    >
                                      {tag}
                                    </span>
                                  ))
                                ) : (
                                  <span style={{ whiteSpace: "nowrap" }}>
                                    {image.hash_tags}
                                  </span>
                                )}

                                {image.description.length > 100 && (
                                  <p
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      toggleText();
                                    }}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {isExpanded ? "read less" : "read more"}
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="comments">
                          <input
                            type="text"
                            className=""
                            placeholder="Write a comment..."
                            // value={typeof postComments[image.post_id] === 'string' ? postComments[image.post_id] : ''}
                            value={commentInputs[image?.post_id] || ""}
                            onChange={(e) =>
                              handleCommentChange(e, image.post_id)
                            } // Update the specific comment for this postId
                            onKeyPress={(e) => handleKeyPress(e, image.post_id)}
                          />
                          <img
                            src={TM_Vector19}
                            alt="Submit Icon"
                            className="commentpost"
                            onClick={() => handleCommentSubmit(image.post_id)} // Submit the specific comment for this postId
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* PopupRecentPosts  Start */}

              <div
                className="modal fade  model_w1000"
                id="staticBackdrop3"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content  modelContent">
                    <div className="modal-content-1">
                      <div className=" popularContaintpopup ">
                        {/* PopupPost Start */}
                        <button
                          data-bs-dismiss="modal"
                          onClick={postImageCost}
                          className="popular_popup_closebutton"
                        >
                          X
                        </button>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div
                              className="popupcard-img-top-container popupcard-img-top-container-2"
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              {selectedImage?.post_type === "post" ? (
                                <>
                                  {selectedImage?.image_url.includes(
                                    "youtube.com"
                                  ) ? (
                                    <ReactPlayer
                                      url={selectedImage?.image_url}
                                      className="popupcard-img-top ms-2"
                                      controls
                                    />
                                  ) : (
                                    <img
                                      className="popupcard-img-top ms-2"
                                      src={selectedImage?.image_url}
                                      alt="Card"
                                    />
                                  )}
                                  {/* <img
                                    className="popupcard-img-top ms-2"
                                    src={selectedImage?.image_url}
                                    alt="Selected Card"
                                  /> */}
                                  <div
                                    className="ms-2"
                                    onClick={() =>
                                      handleLocationClick(
                                        selectedImage?.location
                                      )
                                    }
                                    style={{
                                      position: "absolute",
                                      bottom: "8px",
                                      left: "8px",
                                      color: "white",
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      padding: "3px 9px",
                                      borderRadius: "15px",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    <img
                                      src={locationpointer}
                                      alt="Location pointer"
                                    />
                                    {selectedImage?.location}
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="description-popup-content"
                                  style={{
                                    color: "white",
                                    padding: "20px",
                                    // textAlign: "center",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    borderRadius: "15px",
                                    margin: "10px",
                                    fontSize: "0.9em",
                                    height: "500px",
                                    width: "100%",
                                    paddingLeft: "15px",
                                    overflowY: "scroll",
                                    scrollbarWidth: "thin",
                                    scrollbarColor: "transparent transparent",
                                  }}
                                >
                                  <h3>{selectedImage?.title}</h3>

                                  {/* <p>{selectedImage?.description}</p> */}
                                  <div>
                                    {selectedImage?.description
                                      .split(/(?=\d+\.\s)/)
                                      .map((desc, index) => (
                                        <p key={index}>{desc}</p>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="card-body">
                              <div className="row   popuprowcomments ">
                                <div className="col-12 mb-3 mt-1">
                                  <div className="row ">
                                    <div
                                      className="col"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <img
                                        src={
                                          likedPostss[selectedImage?.post_id]
                                            ? likecon
                                            : TM_likeIcon
                                        }
                                        onClick={() => {
                                          console.log(
                                            "Liked:",
                                            likedPostss[selectedImage?.post_id]
                                          );
                                          handleLikeClick(
                                            selectedImage?.post_id,
                                            true
                                          );
                                        }}
                                        alt="Like"
                                      />

                                      <span className="ms-2">
                                        {selectedImage?.likes_count}
                                      </span>
                                    </div>

                                    <div className="col">
                                      <img
                                        className="TM_Ellipse"
                                        src={TM_Ellipse}
                                      />{" "}
                                      <span className="ms-2">
                                        {selectedImage?.comments_count}
                                      </span>
                                    </div>
                                    <div className="col iconcontainer">
                                      <img
                                        className="TM_bucketlist"
                                        src={TM_bucketlist}
                                        onClick={() =>
                                          togglePopup(selectedImage?.post_id)
                                        }
                                        // alt="Bucket list icon"
                                      />
                                      {isPopupVisible &&
                                        selectedPostId ===
                                          selectedImage?.post_id && (
                                          <BucketListPopup
                                            boards={boards}
                                            postId={selectedPostId}
                                            selectedImageData={selectedImage}
                                            fetchPosts={fetchPosts}
                                            // onClose={togglePopupVisibility}
                                            // toggleBoardModal={toggleBoardModal} // Pass the function as a prop
                                          />
                                        )}

                                      <span className="ms-2">
                                        {selectedImage?.bucket_list_count}
                                      </span>
                                    </div>
                                    <div className="col-3">
                                      {/* <img
                                    className="TM_ShareIcon"
                                    src={TM_ShareIcon}
                                  /> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="col-lg-6 col-md-6 col-sm-12 mt-2 popupprofile-tp"
                            style={{ color: "white" }}
                          >
                            <div className="popupprofile">
                              <img
                                className="profilelogoimage ms-2"
                                src={selectedImage?.user.user_profile_logo}
                                alt="Card image"
                              />
                              {selectedImage?.user.username}
                            </div>
                            <div className="popupDescription">
                              {selectedImage?.post_type !== "note" &&
                                selectedImage?.description}
                              {/* {selectedImage?.hash_tags} */}
                              {Array.isArray(selectedImage?.hash_tags) ? (
                                selectedImage?.hash_tags.map((tag, index) => (
                                  <span
                                    className="ms-2"
                                    key={index}
                                    onClick={() => handleHashtagClick(tag)}
                                    style={{
                                      marginLeft: index > 0 ? "10px" : "0",
                                      whiteSpace: "nowrap",
                                      cursor: "pointer",
                                      color: "#D2C2FF",
                                    }}
                                  >
                                    {tag}
                                  </span>
                                ))
                              ) : (
                                <span
                                  className="ms-1"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {selectedImage?.hash_tags}
                                </span>
                              )}
                            </div>

                            <div className="popupcommentscontaint">
                              {selectedImage &&
                              Array.isArray(
                                postComments[selectedImage.post_id]
                              ) ? (
                                postComments[selectedImage.post_id]?.map(
                                  (comment) => (
                                    <div
                                      className=" comment-row  "
                                      key={comment.comment_id}
                                    >
                                      <div className="d-flex">
                                        <div className=" ">
                                          {comment.user_profile_logo ? (
                                            <img
                                              style={{
                                                width: "25px",
                                                height: "25px",
                                                borderRadius: "50%",
                                              }}
                                              src={comment.user_profile_logo}
                                              alt="Profile"
                                            />
                                          ) : (
                                            <div className="placeholder"></div>
                                          )}
                                        </div>

                                        <div className="ms-2">
                                          <div className="">
                                            {comment.user_name || "UserName"}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-10">
                                        <div className="usercomment ">
                                          {comment.comment_text}
                                        </div>
                                        <div className="replycomment d-flex align-items-center">
                                          <button
                                            className="btn btn-link p-0 me-3"
                                            style={{
                                              textDecoration: "none",
                                              color: "#fff",
                                              fontFamily: "Inter",
                                              fontSize: "12px",
                                            }}
                                            onClick={() =>
                                              handleReplyClick(
                                                // selectedImage.post_id,
                                                comment.comment_id
                                              )
                                            }
                                          >
                                            Reply
                                          </button>
                                          <button className="btn p-0">
                                            <img
                                              src={TM_likeIcon}
                                              className="comment-like"
                                              style={{
                                                width: "15px",
                                                width: "15px",
                                              }}
                                              alt="Like"
                                            />
                                            <span className="comment_likes">
                                              {comment.likes}
                                            </span>
                                          </button>

                                          <button
                                            className="btn btn-link"
                                            style={{
                                              textDecoration: "none",
                                              color: "#fff",
                                              fontFamily: "Inter",
                                              fontSize: "12px",
                                            }}
                                            onClick={() =>
                                              handleSeeMoreClick(
                                                selectedImage.post_id,
                                                comment.comment_id
                                              )
                                            }
                                          >
                                            See more...
                                          </button>
                                        </div>
                                        {replies[comment.comment_id] &&
                                          repliesVisibility[
                                            comment.comment_id
                                          ] &&
                                          replies[comment.comment_id].map(
                                            (reply) => (
                                              <div
                                                key={reply.id}
                                                className="row reply-row align-items-center mb-2"
                                              >
                                                <div className="col-md-2 reply-user-profile"></div>
                                                <div className="col-md-10">
                                                  <div className="reply-username">
                                                    <img
                                                      className="popuproundedImg ms-2"
                                                      style={{
                                                        width: "25px",
                                                        height: "25px",
                                                        borderRadius: "50%",
                                                      }}
                                                      src={
                                                        selectedImage?.user
                                                          .user_profile_logo
                                                      }
                                                      alt="Card image"
                                                    />
                                                    {reply.user_name}
                                                  </div>
                                                  <div className="reply-text ms-4">
                                                    {reply.comment_text}
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <div style={{ color: "#fff" }}>
                                  No comments to display.
                                </div>
                              )}
                            </div>
                            <div className="popupcomments mb-3">
                              <form
                                onSubmit={(e) =>
                                  handlePopupCommentSubmit(
                                    selectedImage?.post_id,
                                    e
                                  )
                                }
                              >
                                <input
                                  ref={commentInputRef}
                                  type="text"
                                  placeholder="Add a comment"
                                  value={popupCommentInput}
                                  onChange={handlePopupCommentChange}
                                />
                                <button
                                  type="submit"
                                  className="comment-submit"
                                >
                                  <img src={TM_Vector19} alt="Submit" />
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" col-4">
          <div className="rightnav">
            <PopularAndTrending />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPostandBoards;
