import React, { useState, useEffect } from "react";
import "./BoardPublicPopup.css"; // Make sure to create and link this CSS file
import Location from "./../../../../Assets/Images/loaction_pointer.png";
import backButtonImage from "./../../../../Assets/Images/Backbutton.png"; // Update with the correct path to your back icon
import axios from "axios";
import Config from "../../../../Assets/Config";
import LocationSuggestions from "../../../../Location/LocationSuggestions";
import { toast } from "react-toastify";
const BoardPublicPopup = ({ onClose, boardata }) => {
  const [isPublic, setIsPublic] = useState(boardata?.is_public ?? false);
  const [tags, setTags] = useState([]);
  const [hashtags, setHashtags] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [existingBoards, setExistingBoards] = useState([]);
  const [error, setError] = useState(null);

  const [buttonActive, setButtonActive] = useState(false);

  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  // ...

  // const handleLocationChange = (e) => {
  //   setLocationInput(e.target.value);
  //   if (selectedLocation) {
  //     setSelectedLocation('');  // Clear selected location if user edits the input
  //   }
  // };

  const handleLocationChange = (e) => {
    // setEditContent({ ...editContent, location: e.target.value });
    setLocationInput(e.target.value);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    // setEditContent({ ...editContent, location: location });
  };

  useEffect(() => {
    // Function to fetch existing boards
    const fetchExistingBoards = async () => {
      try {
        const publicId = sessionStorage.getItem("public_id"); // Replace with correct method to obtain id
        const response = await axios.get(
          `${Config.apiBaseUrl}bucketlist/boards/${publicId}`
        );
        console.log(
          "&&&&&&&&&&&&&&&&&&&&&&&&&&response.data.data.boards",
          response.data.data.boards
        );
        setExistingBoards(response.data.data.boards);
      } catch (error) {
        console.error("Error fetching existing boards: ", error);
        setError(error); // Assuming you have a state to handle errors
      }
    };

    fetchExistingBoards();
  }, []); // Empty dependency array to ensure this effect runs only on mount

  useEffect(() => {
    const numericBoardId = Number(boardata?.id);
    console.log(
      "##################################### boardata?.id",
      numericBoardId
    );
    const board = existingBoards.find((b) => b.id === numericBoardId);
    if (board) {
      setIsPublic(board.is_public);
    }
  }, [existingBoards]);

  const handleMakePublic = () => {
    console.log(`Board ${isPublic ? "made private" : "made public"}`);
    setIsPublic(!isPublic);
    // ... Make API call to update the board status
  };

  const [getBoards, setGetBoards] = useState([]);

  const toggleBoardVisibility = () => {
    console.log("boardId", boardata?.id, "is_public", boardata?.is_public);
    const updatedIsPublic = !boardata?.is_public;
    console.log(
      "updatedIsPublic",
      updatedIsPublic,
      "is_public",
      boardata?.is_public
    );

    const formData = new FormData();
    formData.append("board_id", boardata?.id);
    formData.append("is_public", updatedIsPublic);
    formData.append("hashtags", hashtags);

    tags.forEach((tag) => {
      formData.append("hash_tags[]", `#${tag}`);
    });

    axios
      .put(`${Config.apiBaseUrl}boards`, formData)
      .then(() => {
        // Update local state to reflect the change
        setGetBoards((currentBoards) =>
          currentBoards.map((board) =>
            board.id === boardata?.id
              ? { ...board, is_public: updatedIsPublic }
              : board
          )
        );

        console.log("updatedIsPublic", updatedIsPublic);
        console.log(
          `Board has been made ${updatedIsPublic ? "private" : "public"}.`
        );
        toast.info(`Board has been made ${updatedIsPublic ? "public" : "private"}.`);

        onClose(); // Call onClose to close the popup
      })
      .catch((error) => {
        console.error("Error updating board visibility:", error);
        toast.error("Failed to update board visibility.");
      });
  };

  const handleHashtagsChange = (e) => {
    setHashtags(e.target.value);
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === "Enter") {
      // Split the input by spaces, remove the '#' character, and filter out empty strings
      const newTags = tagInput
        .split(/\s+/)
        .map((tag) => tag.replace("#", ""))
        .filter((tag) => tag.trim() !== "");
      if (newTags.length > 0) {
        setTags([...tags, ...newTags]);
        setTagInput("");
      }
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const handleHashtagsKeyPress = (e) => {
    if (e.key === "Enter" && hashtags.trim() !== "") {
      console.log(`Hashtags to add: ${hashtags}`);
      setHashtags(""); // Reset input field
      setButtonActive(true); // Activate the button
    }
  };

  useEffect(() => {
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@Board data:", boardata);
  }, [boardata]);

  const renderTags = () => {
    return tags.map((tag, index) => (
      <span key={index} className="tag">
        {tag}
      </span>
    ));
  };

  return (
    <div className="board-public-popup-overlay d-flex">
      <div className="board-public-popup" onClick={(e) => e.stopPropagation()}>
        <span className="close-popup" onClick={onClose}>
          X
        </span>
        <div className="bpp-tp-hd" style={{ color: "white", padding: "5px" }}>
          {/* <img
            src={boardata?.image_url}
            alt="alt"
            style={{ width: "35px", height: "35px", borderRadius: "100%" }}
          />
          <span className="ms-1"> {boardata?.name}</span> */}
        </div>
        <div className="">
          <img
            src={boardata?.image_url}
            alt="Board"
            className="landscape-image"
          />
        </div>
        {/* <div className="board-details">
          <div className="trip-info">
            <span className="trip-title">{boardata?.name || "Board Name"}</span>
            <span className="location">
              {boardata?.location || "No Location"}
            </span>
          </div>
        </div> */}
      </div>
      <div className="vertical-line"></div>

      <div style={{ padding: "25px" }}>
        <div className="bktlst-tag-board-main ">
          <div className="board-popup-name">{boardata?.name}</div>
          {/*<div classname="board-popup-loaction" class="board-popup-loaction">
            <img src={loactionpointer} alt="img" />
            {boardata?.location || "Location"}
          </div> */}
          <div
            className="current_Location1"
            style={{ backgroundColor: "#272727", border: "1px solid #fff" }}
          >
            <input
              type="text"
              value={locationInput}
              onChange={handleLocationChange}
              placeholder="Location"
              className="location_input2"
            />
            <span className="location_icon">
              {/* <img src={Location} alt="Location Icon" /> */}
            </span>
            <div className="location_suggestions">
              <LocationSuggestions
                location={locationInput}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                onSuggestionClick={handleLocationSelect}
              />
            </div>
          </div>
          <div className="tag-board-input-container ">
            <input
              type="text"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyDown={handleTagInputKeyDown}
              placeholder="# Add tags"
              className="hash_tag_input"
            />
            <div className="tag-container">
              {tags.map((tag, index) => (
                <span key={index} className="tag">
                  #{tag}
                  <button
                    className="remove-tag-button"
                    onClick={() => removeTag(index)}
                  >
                    x
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>
        <div className="make-public-button-tp" style={{ marginTop: "300px" }}>
          <button
            onClick={toggleBoardVisibility}
            className={`make-public-button ${buttonActive ? "active" : ""}`}
          >
            {isPublic ? "Make Board Private" : "Make Board Public"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoardPublicPopup;
