import React, { useState } from "react";
import arrowButton from "./../../../../Assets/Images/arrowButton.png";
import locationPointer from "./../../../../Assets/Images/loaction_pointer.png";
import Delete from "./../../../../Assets/Images/Delete Symbol.png";
import PlusIcon from "./../../../../Assets/Images/Add to board symbol .png";
import roundlogo from "./../../../../Assets/Images/roundlogo.png";
import Config from "../../../../Assets/Config";
import axios from "axios";
import "./LinkPopup.css";
import { toast } from "react-toastify";

const LinkPopup = ({
  show,
  onClose,
  onRefresh,
  userProfile,
  username,
  userLocation,
  flag,
  selectedBoardId,
}) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageDetails, setImageDetails] = useState([]);
  const [currentHashtags, setCurrentHashtags] = useState({});
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showSaveFailurePopup, setShowSaveFailurePopup] = useState(false);
  const [savedImageThumbnails, setSavedImageThumbnails] = useState([]);

  const isAllFieldsFilled = (index) => {
    const { description, hashtags, location } = imageDetails[index];
    return (
      description.trim() !== "" && hashtags.length > 0 && location.trim() !== ""
    );
  };
  const fetchImages = async (url) => {
    setIsLoading(true);
    setFeedbackMsg("");
    try {
      const response = await fetch(`${Config.apiBaseUrl}scrape-media`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ url: url }),
      });
      console.log(url);

      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
      console.log("############################### data", response);

      const data = await response.json();
      console.log("############################### data", data);
      if (data.url && data.url.length > 0) {
        setImageDetails(
          data.url.map((mediaUrl) => ({
            url: mediaUrl,
            type: data.type, // since type is not inside the array
            selected: false,
            description: "",
            hashtags: [],
            location: "",
          }))
        );
        console.log("############################### data", imageDetails);
      } else {
        setFeedbackMsg("No images available for the entered URL.");
        setImageDetails([]);
      }
    } catch (error) {
      console.log(error);
      console.error("Error fetching images:", error);
      setFeedbackMsg("Error fetching images.");
      setTimeout(() => {
        setFeedbackMsg("");
      }, 4000); // Clears the feedback message after 4 seconds
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    fetchImages(url);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  const toggleSelection = (index) => {
    setImageDetails((details) =>
      details.map((detail, i) => {
        if (i === index) {
          return { ...detail, selected: true };
        } else {
          return { ...detail, selected: false };
        }
      })
    );
  };
  const updateField = (index, field, value) => {
    setImageDetails((details) =>
      details.map((detail, i) =>
        i === index ? { ...detail, [field]: value } : detail
      )
    );
  };

  const handleHashtagChange = (index, value) => {
    setCurrentHashtags((prev) => ({ ...prev, [index]: value }));
  };

  const addHashtag = (index) => {
    let hashtagInput = currentHashtags[index].trim();
    if (hashtagInput) {
      const hashtags = hashtagInput.split(/\s+/);

      setImageDetails((details) =>
        details.map((detail, i) =>
          i === index
            ? {
                ...detail,
                hashtags: [...new Set([...detail.hashtags, ...hashtags])], // use Set to avoid duplicates
              }
            : detail
        )
      );
      setCurrentHashtags((prev) => ({ ...prev, [index]: "" })); // clear input after adding
    }
  };

  const handleSaveToBucketlist = async (index) => {
    const { url, description, hashtags, location } = imageDetails[index];
    if (!imageDetails[index].selected) {
      setFeedbackMsg("Please select an image or video before saving.");
      setTimeout(() => setFeedbackMsg(""), 4000);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("user_id", sessionStorage.getItem("user_id"));
      formData.append("title", "");
      formData.append("image_url", url);
      formData.append("description", description);
      formData.append("location", location);
      formData.append("link", "");
      formData.append("post_type", "post");
      formData.append("is_public", "false");

      hashtags.forEach((tag) => {
        formData.append("hash_tags[]", `#${tag}`);
      });

      const response = await axios.post(`${Config.apiBaseUrl}posts`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(
        "@@@@@@@@@@@@@@@@@@@@@@@@@@@ selectedBoardId, flag",
        selectedBoardId,
        flag
      );
      if (response.status === 200) {
        console.log(
          "Post saved successfully",
          response,
          response.data.data.post_id
        );
        setShowSaveSuccessPopup(true);
        setTimeout(() => setShowSaveSuccessPopup(false), 4000);

        const savedMedia = imageDetails[index];
        setSavedImageThumbnails([
          ...savedImageThumbnails,
          { url: savedMedia.url, type: savedMedia.type },
        ]);

        // Remove the image from imageDetails after saving
        setImageDetails((prevDetails) =>
          prevDetails.filter((_, i) => i !== index)
        );
        // Check if the post needs to be added to a board

        if (flag === "board") {
          const postId = response.data.data.post_id;
          const boardId = selectedBoardId;
          const userId = sessionStorage.getItem("user_id");

          const boardItemData = {
            board_id: boardId,
            post_id: postId,
            user_id: userId,
          };

          const boardItemResponse = await axios.post(
            `${Config.apiBaseUrl}boarditems`,
            boardItemData
          );
          if (boardItemResponse.status === 201) {
            console.log("Post added to board successfully");
            toast.success("Post added to board successfully");
            onRefresh();
          } else {
            console.error("Failed to add post to board");
          }
        }
      } else {
        setShowSaveFailurePopup(true);
        setTimeout(() => setShowSaveFailurePopup(false), 4000);
        onRefresh();
      }
    } catch (error) {
      console.error("Error saving to bucketlist:", error);
      setShowSaveFailurePopup(true);
      setTimeout(() => setShowSaveFailurePopup(false), 4000);
    }
  };

  const handleClose = () => {
    setUrl("");
    setImageDetails([]);
    setCurrentHashtags({});
    onClose();
    window.location.reload();
  };

  const removeHashtag = (imageIndex, hashtagIndex) => {
    setImageDetails((details) =>
      details.map((detail, index) => {
        if (index === imageIndex) {
          return {
            ...detail,
            hashtags: detail.hashtags.filter((_, i) => i !== hashtagIndex),
          };
        }
        return detail;
      })
    );
  };

  if (!show) return null;

  return (
    <div className="link-popup">
      <div className="close-popup" onClick={handleClose}>
        X
      </div>
      <div className="d-flex">
        <input
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter URL here..."
          className="url-input"
        />
        {/* <span onClick={handleSubmit} style={{ cursor: "pointer" }}>
          <img src={arrowButton} alt="Arrow button" />
        </span> */}
      </div>
      {/* <div className="saved-thumbnails">
        {savedImageThumbnails.map((thumbnail, index) => (
          <img
            key={index}
            src={thumbnail}
            alt={`Saved Thumbnail ${index}`}
            className="saved-thumbnail" 
            q
          />
        ))}
      </div> */}
      <div className="saved-thumbnails">
        {savedImageThumbnails.map((media, index) => {
          if (media.type === "video") {
            return (
              <video
                key={index}
                src={media.url}
                alt={`Saved Content ${index}`}
                className="saved-thumbnail"
                controls
              />
            );
          } else {
            return (
              <img
                key={index}
                src={media.url}
                alt={`Saved Thumbnail ${index}`}
                className="saved-thumbnail"
              />
            );
          }
        })}
      </div>

      <div className="popup-content-wrapper">
        {imageDetails.map((detail, index) => (
          <div key={index} className="image-card">
            <div className="image-item">
              {/* <img
                src={detail.url}
                alt={`Content ${index}`}
                className="thumbnail"
              /> */}
              {detail.type === "video" ? (
                <video controls src={detail.url} className="thumbnail"></video>
              ) : (
                <img
                  src={detail.url}
                  alt={`Content ${index}`}
                  className="thumbnail"
                />
              )}
              <input
                type="checkbox"
                checked={detail.selected}
                onChange={() => toggleSelection(index)}
                className="image-checkbox"
              />
            </div>
            <div className="image-details">
              <textarea
                placeholder="Description"
                value={detail.description}
                onChange={(e) =>
                  updateField(index, "description", e.target.value)
                }
                disabled={!detail.selected}
                className="description-input-link"
              />
              <div className="hashtag-location-container">
                <input
                  type="text"
                  placeholder="Enter Hashtags"
                  value={currentHashtags[index] || ""}
                  onChange={(e) => handleHashtagChange(index, e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      addHashtag(index);
                    }
                  }}
                  enterKeyHint="next"
                  disabled={!detail.selected}
                  className="hashtag-input"
                />
                <input
                  type="text"
                  placeholder="Location"
                  value={detail.location}
                  onChange={(e) =>
                    updateField(index, "location", e.target.value)
                  }
                  disabled={!detail.selected}
                  className="location-input"
                />
              </div>
              {/* <div className="hashtag-list">
                {detail.hashtags.map((hashtag, i) => (
                  <span key={i} className="hashtag-item">
                    {hashtag}
                  </span>
                ))}
              </div> */}
              {/* <div className="hashtag-list">
                {detail.hashtags.map((hashtag, i) => (
                  <span key={i} className="hashtag-item">
                    #{hashtag}
                    <span
                      style={{
                        padding: "2px",
                        margin: "3px",
                        // backgroundColor: "#272727",
                        color: "#FFF",
                        borderRadius: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => removeHashtag(index, i)}
                    >
                      x
                    </span>
                   
                  </span>
                ))}
              </div> */}

              <div className="hashtag-list">
                {detail.hashtags.map((hashtag, i) => (
                  <span key={i} className="hashtag-item">
                    #{hashtag.replace(/^#+/, "")}
                    <span
                      className="delete-icon"
                      style={{
                        padding: "2px",
                        margin: "3px",
                        // backgroundColor: "#272727",
                        color: "#FFF",
                        borderRadius: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => removeHashtag(index, i)}
                    >
                      x
                    </span>
                  </span>
                ))}
              </div>

              {/* <button
                onClick={() => handleSaveToBucketlist(index)}
                disabled={!detail.selected}
                className={`save-button ${
                  detail.selected && isAllFieldsFilled(index) ? "filled" : ""
                } ${detail.selected ? "enabled" : ""}`}
              >
                Save
              </button> */}

              <button
                onClick={() => handleSaveToBucketlist(index)}
                disabled={!detail.selected || !isAllFieldsFilled(index)}
                className={`save-button ${
                  detail.selected && isAllFieldsFilled(index) ? "filled" : ""
                } ${detail.selected ? "enabled" : ""}`}
                style={{
                  backgroundColor: detail.selected ? "#714BDC" : "#272727",
                }}
              >
                Save
              </button>
            </div>
          </div>
        ))}
      </div>
      {feedbackMsg && <div className="feedback-message">{feedbackMsg}</div>}
      {showSaveSuccessPopup && (
        <div className="save-success-popup">
          Successfully saved the image.
          <button onClick={() => setShowSaveSuccessPopup(false)}>
            Saved Successfully
          </button>
        </div>
      )}
      {showSaveFailurePopup && (
        <div className="save-failure-popup">
          Failed to save the post.
          <button onClick={() => setShowSaveFailurePopup(false)}>Failed</button>
        </div>
      )}
    </div>
  );
};

export default LinkPopup;
