import React, { useState } from "react";
import "./Home.css";
import CreatePost from "../CreatePost/CreatePost";
import PopularTrips from "../PopularTrip/PopularTrips";
import PopularPost from "../PopularPosts/PopularPost";
import TopDiscoveries from "../TopDiscoveries/TopDiscoveries";
import PopularAndTrending from "../Popular_Trending/PopularAndTrending";
import RecentPosts from "../RecentPosts/RecentPosts";
import Backbutton from "./../../../Assets/Images/Backbutton.png";
import { useSearch } from "../../../Assets/queryprovider";
import { toast } from "react-toastify";

const Home = () => {
  const { query } = useSearch();
  const [isHashtagSelected, setIsHashtagSelected] = useState(false);
  const [popularPostsData, setPopularPostsData] = useState([]);
  const [boardName, setBoardName] = useState("");
  const [showPopularPost, setShowPopularPost] = useState(false);
  console.log("Before removal:", sessionStorage.getItem("filterList"));
  sessionStorage.removeItem("filterList");
  console.log("After removal:", sessionStorage.getItem("filterList"));

  const handlePopularPostsData = ({ name, data }) => {
    console.log("#$@%^&*()*&%^&*( data #$%^&*()*&^*(", name, data);
    setBoardName(name);
    setPopularPostsData(data);
    setShowPopularPost(data.length > 0);
    if (data.length === 0) {
      toast.info(`No board items available for "${name}"`); // Consider replacing with a modal or custom notification for better user experience.
    }
  };

  const shouldHideMainContent = query.length > 0 || isHashtagSelected;

  return (
    <div className="home-body ">
      <div className="row">
        <div className="col-12 col-md-8 body-margin-container">
          {showPopularPost ? (
            <div class="hm-back-tab-kd">
              <div className="d-flex align-items-center">
                <span
                  onClick={() => setShowPopularPost(false)}
                  style={{ fontSize: "24px", cursor: "pointer" }}
                >
                  <img src={Backbutton} alt="Back button" />
                </span>
                <h2 style={{ color: "#FFF" }} className="ms-3">
                  {boardName}
                </h2>
              </div>
              <PopularPost
                hashtagData={{ data: popularPostsData, name: "board" }}
              />
            </div>
          ) : !shouldHideMainContent ? (
            <>
              <CreatePost />
              <div className="mainboard">Popular Trip Boards</div>
              <PopularTrips onPopularPostsData={handlePopularPostsData} />
              <div className="mainboard">Popular Posts</div>
              <PopularPost />
              <div className="mainboard">Top Discoveries</div>
              <TopDiscoveries />
              <div className="mainboard">Recent Posts</div>
              <RecentPosts />
            </>
          ) : (
            <>
              {isHashtagSelected ? (
                <PopularPost hashtagData={popularPostsData} />
              ) : (
                <>
                  {query.length <= 0 && (
                    <>
                      <div className="mainboard">Popular Posts</div>
                      <PopularPost />

                      <div className="mainboard">Recent Posts</div>
                      <RecentPosts />
                    </>
                  )}
                  {query.length > 0 && (
                    <>
                      <PopularPost />
                      {/* <RecentPosts /> */}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
        <div className="col-12 col-md-4 popular-and-trending">
          <PopularAndTrending
            onHashtagSelect={setIsHashtagSelected}
            setHashtagData={setPopularPostsData}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
