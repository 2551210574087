import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./ImageGrid.css";
import TM_bucketlist from "./../../../Assets/Images/bucketlist.png";
import TM_likeIcon from "./../../../Assets/Images/Like Icon.png";
import loaction_pointer from "./../../../Assets/Images/loaction_pointer.png";
import Config from "../../../Assets/Config";
import Message from "./../../../Assets/Images/Ellipse 25.png";
import licon from "./../../../Assets/Images/LIcon.png";
import { useNavigate } from "react-router-dom";

const ImageGrid = (props) => {
  const [images, setImages] = useState([]);
  const [selectedImg, setSelectedImg] = useState(null);
  const [error, setError] = useState(null);

  const [comments, setComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const dynamicImageUrl = `${Config.apiBaseUrl}posts/recent`;

  const [clickCounts, setClickCounts] = useState({}); // Tracks click counts for each image
  const history = useNavigate();

  const popupRef = useRef();

  const handleClosePopup = () => {
    setSelectedImg(null);
  };

  useEffect(() => {
    // Fetch comments for the selected image using post_id
    if (selectedImg) {
      fetchComments(selectedImg.post_id);
    }
  }, [selectedImg]);

  const fetchComments = (post_id) => {
    setLoadingComments(true);
    axios
      .get(`${Config.apiBaseUrl}posts/${post_id}`)
      .then((response) => {
        setComments(response.data.data.comments);
        setLoadingComments(false);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
        setLoadingComments(false);
      });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = () => {
    console.log("dynamicImageUrl:", dynamicImageUrl);
    axios
      .get(dynamicImageUrl)
      .then((response) => {
        console.log("Response:", response);
        const imageLoadPromises = response.data.data.map((img) => {
          return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = img.image_url;

            image.onload = () => {
              resolve({
                post_id: img.post_id,
                src: img.image_url,
                description: img.description,
                username: img.user.username,
                bucketListCount: img.bucket_list_count,
                likeCount: img.likes_count,
                user_profile_logo: img.user.user_profile_logo,
                location: img.location,
                width: image.naturalWidth,
                height: image.naturalHeight,
              });
            };

            console.log(
              " img.user.user_profile_logo",
              img.user.user_profile_logo
            );
            image.onerror = () => {
              reject(new Error("Failed to load image at " + img.image_url));
            };
          });
        });

        Promise.all(imageLoadPromises)
          .then((imageData) => {
            const shuffleImages = shuffleArray(imageData);
            setImages(shuffleImages);
          })
          .catch((error) => {
            console.error("Error loading one or more images:", error);
            setError("An error occurred while loading images.");
          });
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
        setError("An error occurred while fetching images.");
      });
  };

  const patterns = [
    [
      [1, 1],
      [1, 1],
      [1, 1],
      [1, 1],
      [1, 1],
    ],
    [
      [1, 1],
      [1, 1],
      [1, 1],
      [1, 2],
      [1, 1],
    ],
    [
      [1, 1],
      [2, 1],
      [1, 1],
    ],
    [
      [1, 1],
      [2, 1],
      [1, 1],
      [1, 1],
    ],
    [
      [1, 1],
      [1, 2],
      [2, 1],
    ],
    [
      [1, 1],
      [1, 1],
      [1, 1],
      [1, 1],
    ],
    [
      [1, 1],
      [1, 1],
      [1, 1],
    ],
    [[1, 1]],
    [
      [1, 1],
      [1, 1],
    ],
    [
      [1, 1],
      [1, 1],
      [1, 2],
      [1, 1],
    ],
    [
      [2, 1],
      [1, 1],
      [1, 2],
      [1, 1],
    ],
    [
      [1, 1],
      [1, 2],
      [1, 1],
      [1, 1],
    ],
    [
      [1, 1],
      [2, 1],
    ],
  ];

  const loadImages = () => {
    const gridElements = [];
    let imageIndex = 0;

    // Place the first two images in the first row
    if (images.length > 0) {
      gridElements.push(
        renderImageInfo(images[0], 0, "1 / span 1", "1 / span 1", true)
      );
      imageIndex++;
    }

    // New content for empty grids in the first row
    gridElements.push(
      <div key="empty-grids" style={{ gridColumn: "2 / span 3", gridRow: "1" }}>
        <div className="empty-grid-content">
          <h1>Plan your next trip with the world.</h1>
          <p>
            Get inspiration. Explore pre-traveled itineraries. And save
            everything you need in one neat place.
          </p>
          <button onClick={props.handleSignupClick}>Start Exploring</button>
        </div>
      </div>
    );

    if (images.length > 1) {
      gridElements.push(
        renderImageInfo(images[1], 1, "5 / span 1", "1 / span 1", true)
      );
      imageIndex++;
    }

    // Use the 2nd and 3rd patterns for the next 8 images
    const selectedPatterns = [patterns[1], patterns[2]]; // Use the 2nd and 3rd patterns
    let patternRowIndex = 2; // Start from the second row for these patterns

    for (let i = 0; i < selectedPatterns.length; i++) {
      const pattern = selectedPatterns[i];
      for (let j = 0; j < pattern.length; j++) {
        if (imageIndex < images.length) {
          const [colSpan, rowSpan] = pattern[j];
          gridElements.push(
            renderImageInfo(
              images[imageIndex],
              imageIndex,
              `auto / span ${colSpan}`,
              `${patternRowIndex} / span ${rowSpan}`
            )
          );
          imageIndex++;
        }
      }
      patternRowIndex++; // Move to the next row for the next pattern
    }

    return gridElements;
  };
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  const renderImageInfo = (
    img,
    key,
    gridColumn,
    gridRow,
    isIncreasedHeight = false
  ) => {
    const customHeight = isIncreasedHeight ? "412px" : "auto"; // Increase height for first two images
    return (
      <div
        key={key}
        className="grid-item grid-background"
        style={{ gridColumn, gridRow, height: customHeight }}
        onClick={() => setSelectedImg(img)}
      >
        <div className="image-info-container">
          <img src={img.src} alt={`Image ${key}`} />
          <div className="image-overlay">
            <div className="top-right">
              <div className="icon-container">
                <img src={TM_likeIcon} alt="Like" />
                <span>{img.likeCount}</span>
              </div>

              <div className="icon-container">
                <img src={TM_bucketlist} alt="Bucket List" />

                <span>{img.bucketListCount}</span>
              </div>
            </div>

            <div className="top-left">
              <div className="profile-and-name">
                <div className="profile-logo-container">
                  <img src={img.user_profile_logo} alt="Profile" />
                </div>
                <span>{img.username}</span>
              </div>
              {img.location ? (
                <div className="location-container">
                  <img src={loaction_pointer} alt="Location" />
                  <span>{img.location}</span>
                </div>
              ) : (
                ""
              )}
              {/* <div className="location-container">
                <img src={loaction_pointer} alt="Location" />
                <span>{img.location}</span>
              </div> */}
            </div>
            <div className="bottom-left">
              {/* <span>{img.description}</span> */}
              <span>
                {img.description && img.description.length > 107
                  ? img.description.substring(0, 104) + "..."
                  : img.description}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="grid-wrapper">
      <div className="grid-container">
        {error ? <p className="error-message">{error}</p> : loadImages()}

        {
          <ImagePopup
            img={selectedImg}
            onClose={handleClosePopup}
            comments={comments} // Pass comments to ImagePopup
            loadingComments={loadingComments} // Pass loading state to ImagePopup
            ref={popupRef}
          />
        }
      </div>
    </div>
  );
};

const ImagePopup = React.forwardRef(
  ({ img, comments, loadingComments }, ref) => {
    const navigate = useNavigate(); // Hook to navigate programmatically
    const [clickCount, setClickCount] = useState(0);
    const [closepopup, setClosepopup] = useState(false);

    useEffect(() => {
      if (img) {
        setClosepopup(false);
      }
    }, [img]);

    const handlePopupClick = () => {
      const newCount = clickCount + 1;
      setClickCount(newCount);

      // If the popup has been clicked 3 times, redirect to the login page
      if (newCount === 2) {
        navigate("/signupprocess");
      }
    };

    if (!img || closepopup) return null;

    const popupClose = () => {
      setClosepopup(true);
    };
    return (
      <div
        className="popup-overlay"
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="popup-content">
          <button onClick={popupClose} className="close_image_popup">
            X
          </button>
          {/* <div className="">
            <div className="popup-text">
              <div className="profilename">
                <img
                  src={img.user_profile_logo}
                  alt="Profile"
                  className="profile-logo"
                />
                <span>{img.username}</span>
              </div>
            </div>
          </div> */}
          {/* <div className="horizonta-line"></div> */}
          <img src={img.src} alt={img.description} className="popup-image" />
          <div className="horizonta-line"></div>

          {img.location ? (
            <div className="image-overlay-location">
              <img
                src={loaction_pointer}
                alt="Location"
                className="location-icon"
              />
              <span className="location-popup-text">{img.location}</span>
            </div>
          ) : (
            ""
          )}

          <div className="popupcount">
            <img src={TM_likeIcon} alt="Like" />
            <span>{img.likeCount}</span>
            <img src={TM_bucketlist} alt="Bucket List" />
            <span>{img.bucketListCount}</span>
            <img src={Message} alt="Share" />
          </div>
        </div>
        <div class="vertical-line"></div>
        <div className="popup-text-overlay">
          <div className="top-section">
            <div className="profilename">
              <img
                src={img.user_profile_logo}
                alt="Profile"
                className="profile-logo"
              />
              <span>{img.username}</span>
            </div>
            <div className="disc">
              <span>
                {img.description}
                {img.hash_tags}
              </span>
            </div>
            <div className="horizonta-line"></div>

            <div className=" comment_box">
              {loadingComments ? (
                <p>Loading comments...</p>
              ) : comments && comments.length > 0 ? (
                comments.map((comment, index) => (
                  <div key={index} className=" ">
                    <div className="profilename">
                      {comment.user_profile_logo ? (
                        <img
                          src={comment.user_profile_logo}
                          alt="Profile"
                          className="profilelogo"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = ""; // Remove the src or set it to a default image if preferred
                            e.target.classList.add("whiteCircle");
                          }}
                        />
                      ) : (
                        <div className="whiteCircle"></div> // Render this div when there's no profile logo
                      )}
                      <span>{comment.user_name || "Anonymous"}</span>
                      <div className="time">{comment.posted_time}</div>
                    </div>
                    <p>{comment.comment_text}</p>
                    <div className="comments_like">
                      <h6>Reply</h6>
                      <img src={licon} alt="Like" />
                    </div>
                  </div>
                ))
              ) : (
                <p>No comments yet.</p>
              )}
            </div>
          </div>

          <div className="input-section">
            <div className="horizonta-line"></div>
            <input
              type="text"
              disabled // Adding the disabled attribute here
              placeholder=" Signup to add a comment..."
              className="comment-input"
            />
          </div>
        </div>
      </div>
    );
  }
);

export default ImageGrid;
