import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import image_gallary from "./../../../Assets/Images/image_gallary.png";
import dessabled from "./../../../Assets/Images/dessabled.png";
import Message from "./../../../Assets/Images/Message.png";
import location from "./../../../Assets/Images/loaction_pointer.png";
import Config from "./../../../Assets/Config";
import "./CreatePost.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Modal } from "bootstrap";

import LocationSuggestions from "../../../Location/LocationSuggestions";
import { toast } from "react-toastify";

const CreatePost = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFilepost, setselectedFilepost] = useState([]);
  const [createPosts, setcreatePosts] = useState({
    user_id: Number(sessionStorage.getItem("user_id")),
    title: "",
    description: "",
    post_type: "",
    is_public: true,
    tags: "",
    location: "",
    link: "",
  });

  const [tempTags, setTempTags] = useState([]);
  const [submitForm, setSubmitForm] = useState(false);
  const username = sessionStorage.getItem("username");
  const profile_logo = sessionStorage.getItem("user_profile_logo");
  const [selectedLocation, setSelectedLocation] = useState("");
  const changehandler = (e) => {
    const value = e.target.value;
    setcreatePosts((prevPost) => ({
      ...prevPost,
      location: value,
    }));

    if (!value) {
      setSelectedLocation("");
    }
  };

  const handleLocationSelect = (address) => {
    setcreatePosts((prevPost) => ({
      ...prevPost,
      location: address,
    }));
    setSelectedLocation(address);

    setTempTags((prevTags) => [
      ...prevTags,
      `<img src="${location}" /> ${address}`,
    ]);
  };

  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 0) {
      const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (!allowedImageTypes.includes(file.type)) {
        toast.error("Only JPEG, JPG, and PNG files are accepted.");
        return;
      }

      setselectedFilepost(file);
      const fileType = file.type.startsWith("image/")
        ? "post"
        : file.type.startsWith("video/")
        ? "video"
        : null;

      if (fileType === "post") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
          setSelectedVideo(null);
          setcreatePosts((prevPost) => ({
            ...prevPost,
            link: file.name,
            image: file,
            post_type: "post",
          }));
        };
        reader.readAsDataURL(file);
      } else if (fileType === "video") {
        setSelectedImage(null);
        setSelectedVideo(URL.createObjectURL(file));
        setcreatePosts((prevPost) => ({
          ...prevPost,
          link: file.name,
          video: file,
          post_type: "video",
        }));
      } else {
        setSelectedImage(null);
        setSelectedVideo(null);
        setselectedFilepost(null);
        console.log("No valid image or video file selected");
      }
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const handleTagInputChange = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const value = e.target.value.trim();
      if (value) {
        const newTags = value
          .replace(/#+/g, "")
          .split(/\s+/)
          .map((tag) => `#${tag}`);
        setTempTags([...tempTags, ...newTags]);
        e.target.value = "";
      }
    }
  };

  const handleTaglocationInputChange = (e) => {
    const value = e.target.value.trim();
    if (e.key === "Enter" && value !== "") {
      setTempTags([...tempTags, `<img src="${location}" /> ${value}`]);
      e.target.value = "";
      e.preventDefault();
    }
  };

  const handleRemoveTag = (indexToRemove, e) => {
    e.preventDefault();
    e.stopPropagation();
    setTempTags((prevTags) =>
      prevTags.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSendButtonClick = (e) => {
    e.preventDefault();
    if (
      createPosts.description.trim() === "" ||
      createPosts.location.trim() === "" ||
      !selectedFilepost
    ) {
      toast.info("Please fill out all required fields.");
      return;
    }
    handelpost(e);
  };

  const handelpost = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", Number(createPosts.user_id));
    formData.append("title", createPosts.title);
    formData.append("description", createPosts.description);
    formData.append("post_type", createPosts.post_type);
    formData.append("is_public", createPosts.is_public.toString());
    formData.append("location", createPosts.location);
    formData.append("link", createPosts.link);

    console.log("createPosts", createPosts);
    console.log("selectedFilepost", selectedFilepost);

    if (selectedFilepost) {
      formData.append("image", selectedFilepost);
    }
    tempTags
      .filter((tag) => /^#[\w]+$/.test(tag))
      .forEach((tag) => {
        formData.append("hash_tags[]", tag);
      });

    try {
      const response = await axios.post(`${Config.apiBaseUrl}posts`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200 || response.status === 201) {
        toast.success("Posted Successfully");
        window.location.reload();
      } else {
        throw new Error("Failed to post");
      }
    } catch (error) {
      toast.error("Post failed: " + error.message);
    }
  };

  const clearCreateButtonClick = () => {
    setcreatePosts({
      user_id: Number(sessionStorage.getItem("user_id")),
      title: "",
      description: "",
      post_type: "post",
      is_public: true,
      location: "",
      tags: "",
      link: "",
    });

    setTempTags([]);
  };

  const modalRef = useRef(null);
  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setcreatePosts((prevPost) => ({
      ...prevPost,
      description: value,
    }));
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setcreatePosts((prevPost) => ({
      ...prevPost,
      location: value,
    }));
    if (!value) {
      setSelectedLocation("");
    }
  };

  return (
    <div>
      <h2 style={{ color: "#FFF" }} className="mb-4 adventure">
        Adventure starts here...
      </h2>
      <div>
        <button
          className="createbtn mb-2"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop1"
          onClick={clearCreateButtonClick}
        >
          + Create Post
        </button>
      </div>
      <form onSubmit={handelpost} className="createpostPopup">
        <div
          className="modal fade "
          id="staticBackdrop1"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
          ref={modalRef}
        >
          <div className="dashbd-modal-dialog-tp">
            <div className="modal-dialog model-dialog-creat-post ">
              <div className="modal-content containtpopup">
                <div className="modal-header ">
                  <div className="row ">
                    <div className="col-5 proft-logo-text-center">
                      <img
                        src={profile_logo}
                        className="rounded-circle postprofileimg"
                        alt="Profile image"
                      />
                    </div>
                    <div className="col-7 mt-2 postUserName">{username}</div>
                    <button
                      type="button"
                      className="btn-close btn_cls_create"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        borderRadius: "50px",
                        backgroundColor: "#FFF",
                        color: "#fff",
                      }}
                    ></button>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="p-2 postcontaint">
                    <div className="postcomment">
                      <textarea
                        name="description"
                        value={createPosts.description}
                        className="textarea_comments"
                        placeholder="What do you want to share?"
                        onChange={handleDescriptionChange}
                      ></textarea>
                      <div className="tag-container">
                        {tempTags.map((tag, index) => (
                          <span key={index} className="tag">
                            <span
                              dangerouslySetInnerHTML={{ __html: tag }}
                            ></span>
                            <button
                              className="remove-tag-button"
                              onClick={(e) => handleRemoveTag(index, e)}
                            >
                              <i className="bi bi-x">x</i>
                            </button>
                          </span>
                        ))}
                      </div>
                    </div>
                    {createPosts.image && (
                      <img src={selectedImage} className="selectedImage" />
                    )}
                    <div className="row mt-3" id="rowcontaint">
                      <div
                        className="col-2 image_container"
                        style={{ position: "relative" }}
                      >
                        <img
                          className="background_image"
                          src={image_gallary}
                          alt="Background Image"
                          onClick={triggerFileInput}
                        />
                        <input
                          id="fileInput"
                          type="file"
                          className="hidden"
                          accept="image/*,video/*"
                          onChange={handleFileChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <div className="location_container">
                          <input
                            type="text"
                            value={createPosts.location}
                            name="location"
                            className="location_input Location_placeholder"
                            placeholder="Location"
                            onChange={handleLocationChange}
                            onKeyDown={handleTaglocationInputChange}
                          />
                          <span className="location_icon">
                            {/* <img src={location} alt="Location Icon" /> */}
                          </span>
                          <div className="location_suggestions">
                            <LocationSuggestions
                              location={createPosts.location}
                              selectedLocation={selectedLocation}
                              setSelectedLocation={setSelectedLocation}
                              onSuggestionClick={handleLocationSelect}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3 hash_tag">
                        <input
                          type="text"
                          name="hash_tag"
                          className="hash_tag_input"
                          placeholder="# &ensp; Add tags"
                          onKeyDown={handleTagInputChange}
                        />
                      </div>
                      <div className="col-4 sending_btn">
                        <span
                          type="submit"
                          className="submitbutton  submitbutton2"
                          onClick={handleSendButtonClick}
                        >
                          <img
                            src={selectedImage ? Message : dessabled}
                            alt="Send"
                            className="sendimg"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePost;
