import React, { useEffect, useState } from "react";
import axios from "axios";
import Config from "../../../Assets/Config";
import "./SeeMorePublicBoards.css";
import like from "../../../Assets/Images/Like.png";
import likecon from "../../../Assets/Images/likecon.png";
import PopularAndTrending from "./../../UserHomepage/Popular_Trending/PopularAndTrending";
import { useNavigate } from "react-router-dom";
import { useSearch } from "./../../../Assets/queryprovider";
import { PostLikes } from "../../../Assets/PostLikes";
import bucketlist from "../../../Assets/Images/bucketlist.png";
import PopularPost from "../../UserHomepage/PopularPosts/PopularPost";
import Backbutton from "./../../../Assets/Images/Backbutton.png";

import locationpointer from "./../../../Assets/Images/loaction_pointer.png";
// import PopularAndTrending from "../../UserHomepage/Popular_Trending/PopularAndTrending";

const SeeMorePublicBoards = ({ hashtagData, selectedImageData }) => {
  const [boards, setBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [boardsData, setBoardsData] = useState([]);
  const [showPopularPost, setShowPopularPost] = useState(false);
  const [popularPostsData, setPopularPostsData] = useState(null);
  const [boardName, setBoardName] = useState("");
  console.log("popularPostsData ________", popularPostsData);

  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const navigate = useNavigate();
  const { setBoardPostsName, setboardname } = useSearch(); // If setBoardName should come from useSearch

  const navigateToBoardDetail = async (board_id, board_name) => {
    console.log(`Navigating to board with ID: ${board_id}`);
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/board/${board_id}`
      );
      const boardPostsData = response.data.data;
      console.log("Board posts data:", boardPostsData);
      if (!boardPostsData || boardPostsData.length === 0) {
        setPopupMessage(`No board items available for "${board_name}"`);
        setShowPopup(true);
      } else {
        setboardname(board_name);
        setPopularPostsData(boardPostsData);
        setShowPopularPost(true);
        // setShowPopup(false);
      }
    } catch (error) {
      console.error("Error fetching board posts:", error);
      setPopupMessage("Error fetching data. Please try again later.");
      setShowPopup(true);
    }
  };

  const handleImageError = (e) => {
    e.target.onError = null; // prevents looping
    e.target.style.display = "none"; // hides the broken image icon
  };

  const [likedPostss, setLikedPosts] = useState({});

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    const userId = sessionStorage.getItem("user_id");
    const url = `${Config.apiBaseUrl}boards`;

    axios
      .get(url, {
        params: {
          user_id: userId,
        },
      })
      .then((response) => {
        console.log("boards data", response.data.data);
        setBoards(response.data.data);
        // Create an object mapping post IDs to their liked status
        const likedPostsObject = response.data.data.reduce((acc, board) => {
          acc[board.board_id] = board.liked_by_current_user;
          return acc;
        }, {});
        setLikedPosts(likedPostsObject);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleLikeBoard = async (event, boardId) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      const isLiked = !likedPostss[boardId]; // Determine the new liked status

      // Update the liked posts state
      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        [boardId]: isLiked,
      }));

      // Update the boards state
      setBoards((currentBoards) =>
        currentBoards.map((board) => {
          if (board.board_id === boardId) {
            return {
              ...board,
              liked_by_current_user: isLiked,
              like_count: isLiked ? board.like_count + 1 : board.like_count - 1,
            };
          }
          return board;
        })
      );

      // Make the API call to update the like status
      const likeData = {
        like_type_id: boardId,
        like_type: "board",
        public_id: sessionStorage.getItem("public_id"),
      };
      await PostLikes(likeData);
    } catch (error) {
      console.error("Error updating like status: ", error);
    }
  };

  if (isLoading) {
    return <div style={{ color: "#FFF" }}>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: "#FFF" }}>Error: {error.message}</div>;
  }

  // const [showPublicpostPopup, setShowPublicpostPopup] = useState(false);

  const navigatePopularBordPosts = async (board_id, board_name) => {
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/board/${board_id}`
      );

      const boardPostsData = response.data.data;
      if (!boardPostsData || boardPostsData.length === 0) {
        setPopupMessage(`No board items available for ${board_name}`);
        setShowPopup(true);
      } else {
        setBoardName(board_name);
        setPopularPostsData(boardPostsData);
        setShowPopularPost(true);
        window.scroll(0, 0);
        console.log(
          "boardPostsData_______+___+++++++++++++++++++++++++",
          boardPostsData
        );
      }
    } catch (error) {
      console.error("Error fetching board posts:", error);
      setPopupMessage("Error fetching data. Please try again later.");
      setShowPopup(true);
    }
  };

  return (
    <div className="see-more-wrapper ">
      {showPopup && (
        <div className="popup-overlay2">
          <div className="popup-content2">
            {popupMessage}
            <button className="close-btn2" onClick={() => setShowPopup(false)}>
              &times;
            </button>
          </div>
        </div>
      )}
      {showPopularPost ? (
        <div className="post-margin-container">
          <div className="d-flex ">
            <span
              // onClick={() => navigate(-1)}
              style={{ cursor: "pointer", color: "#FFF", marginTop: "8px" }}
              onClick={() => setShowPopularPost(false)}
              // style={{ fontSize: "24px" }}
            >
              <img
                src={Backbutton}
                alt="backbutton"
                className="seeMore-back-btn-KD"
              />
            </span>
            <h2 style={{ color: "#FFF" }} className="ms-3">
              {boardName}
            </h2>
          </div>
          <PopularPost
            hashtagData={{
              data: popularPostsData,
              name: "board",
            }}
          />
        </div>
      ) : (
        <div className="boards-wrapper bodymarginPubliccontainer">
          <h2 style={{ color: "#FFF" }}>Public Boards</h2>
          {boards.map((board, index) => (
            <div key={index} className="public-board-container">
              <div
                className="public-board"
                onClick={() =>
                  navigatePopularBordPosts(board.board_id, board.title)
                }
              >
                <div className="public-board-image-container">
                  <img
                    src={board.image_url}
                    alt="Board"
                    className="public_board_image"
                  />
                  <div className="public-board-overlay">
                    {/* User Info and Location */}
                    <div className="public-board-top-left">
                      {board.user.user_profile_logo ? (
                        <img
                          src={board.user.user_profile_logo}
                          alt="User"
                          className="public-board-profile-logo"
                          onError={handleImageError}
                        />
                      ) : (
                        <div className="public-board-profile-placeholder" />
                      )}
                      <span className="public-board-profile-name">
                        {board.user.username || "UserName"}
                      </span>
                    </div>
                    {/* Like and Bucket List Buttons */}
                    <div className="public-board-bottom-right">
                      <span
                        className="public-board-like-btn"
                        onClick={(e) => handleLikeBoard(e, board.board_id)}
                      >
                        <img
                          src={likedPostss[board.board_id] ? likecon : like}
                          alt="Like Icon"
                        />

                        <span>{board.like_count}</span>
                      </span>
                      <span className="public-board-bucket-list-btn">
                        <img src={bucketlist} alt="Bucket" />
                        <span>{board.bucket_list_count}</span>
                      </span>
                    </div>
                    <div className="public-board-bottom-left">
                      <div className="public-board-name">{board.title}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="popular-and-trending-wrapper tab-disp-none ms-3">
        <PopularAndTrending />
      </div>
    </div>
  );
};

export default SeeMorePublicBoards;
