import React, { useRef, useState, useEffect } from "react";
import "./NewNotePopup.css";
import TextEditorToolbar from "./TextEditorToolbar";
// import Location from "./../../../../Assets/Images/locationpointer.png";
import Location from "./../../../../Assets/Images/loaction_pointer.png";
import Delete from "./../../../../Assets/Images/Delete Symbol.png";
import PlusIcon from "./../../../../Assets/Images/Add to board symbol .png";
import roundlogo from "./../../../../Assets/Images/roundlogo.png";
import Config from "../../../../Assets/Config";
import axios from "axios";
import addButton from "./../../../../Assets/Images/addButton.png";
import { X } from "@mui/icons-material";
import LocationSuggestions from "../../../../Location/LocationSuggestions";
import { toast } from "react-toastify";
const NewNotePopup = ({
  show,
  onClose,
  userProfile,
  username,
  userLocation,
  setShowNewNotePopup,
  onSaveToBucketlist,
  selectedPostId,
  flag,
  onRefresh,
  selectedBoardId,
}) => {
  const textAreaRef = useRef(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isHamburgerPopupVisible, setIsHamburgerPopupVisible] = useState(false);
  const [isNewBoardPopupVisible, setIsNewBoardPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [boardselectedimage, setBoardSelectedImage] = useState(null);
  const publicId = sessionStorage.getItem("public_id");
  const [boardNotePosts, setBoardNotePosts] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  console.log("selectedPostId", selectedPostId);
  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  // ...

  const handleLocationChange = (e) => {
    setLocationInput(e.target.value);
    if (selectedLocation) {
      setSelectedLocation(""); // Clear selected location if user edits the input
    }
  };

  const handleLocationSelect = (address) => {
    setLocationInput(address);
    setSelectedLocation(address);
  };
  const resetForm = () => {
    // Reset simple states
    setTitle("");
    setDescription("");
    setSelectedFile(null);
    setBoardSelectedImage(null);
    setIsNewBoardPopupVisible(false);
    setIsCreateNewBoardPopupVisible(false);
    setShowNewNotePopup(false);
    // setIsCreateNewBoardPopupVisible(false);
    setError(null); // If you manage errors as state
    setIsLoading(false); // If you manage loading state

    // Reset complex object states
    setCreateBoard({
      board_name: "",
      is_public: false,
      location: "",
      post_type: "note",
      user_id: Number(sessionStorage.getItem("user_id")),
    });

    // Reset arrays or other structured data
    setBoardNotePosts([]);

    // Any other state that needs resetting
  };

  const [createboard, setCreateBoard] = useState({
    board_name: "",
    is_public: false,
    location: "",
    post_type: "note",
    user_id: Number(sessionStorage.getItem("user_id")),
  });
  const [isCreateNewBoardPopupVisible, setIsCreateNewBoardPopupVisible] =
    useState(false);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
    setShowNewNotePopup(false);
  };

  useEffect(() => {
    axios
      .get(`${Config.apiBaseUrl}bucketlist/boards/${publicId}`)
      .then((res) => {
        if (res.data.data.boards) {
          console.log("setGetBoards", res.data.data.boards);
          setBoardNotePosts(res.data.data.boards);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  if (!show) return null;

  const toggleNewBoardPopup = () => {
    setIsNewBoardPopupVisible((prev) => !prev);
    if (isHamburgerPopupVisible) {
      setIsHamburgerPopupVisible(false);
    }
  };
  const toggleHamburgerPopup = () => {
    setIsHamburgerPopupVisible((prevState) => !prevState);
    if (isNewBoardPopupVisible) {
      setIsNewBoardPopupVisible(false);
    }
  };

  const toggleCreateNewBoardPopup = () => {
    setIsCreateNewBoardPopupVisible((prev) => !prev);
    // When opening the create new board popup, make sure to close the new board list popup if it's open
    if (isNewBoardPopupVisible) {
      setIsNewBoardPopupVisible(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBoardSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("No image file selected");
    }
  };
  const triggerFileInput = () => {
    document.getElementById("editPopup_fileInput").click();
  };

  const postImageCancel = (e) => {
    e.preventDefault();
    setBoardSelectedImage(null);
    setIsCreateNewBoardPopupVisible(false);
    // onClose();
    // setShowNewNotePopup(false);
  };

  const handleTitleChange = (event) => {
    const value = event.target.value;
    validateTitle(value);
    setTitle(value);
  };

  const handleDescriptionInput = (event) => {
    const value = event.currentTarget.textContent;
    validateDescription(value);
    setDescription(value);
  };

  // Validate title
  const validateTitle = (value) => {
    // if (!value.trim()) {
    //   setTitleError("Note name is required.");
    //   alert("Note name is required.");
    // } else {
    //   setTitleError("");
    // }
    setTitle(value);
  };

  // Validate description
  const validateDescription = (value) => {
    console.log("validateDescription", value.trim().length);
    // if (value.trim().length < 50) {
    //   console.log("Description must be at least 50 characters long.")
    //   // alert("Description must be at least 50 characters long.")
    //   // setDescriptionError("Description must be at least 50 characters long.");

    // } else {
    //   setDescriptionError("");
    // }
    setDescription(value);
  };
  const changeHandler = (e) => {
    setCreateBoard({ ...createboard, [e.target.name]: e.target.value });
  };
  // Submit the note and optionally create a board and link the note
  const submitHandler = async (e) => {
    e.preventDefault();

    // Step 1: Submit the note
    const noteFormData = new FormData();
    noteFormData.append("title", title);
    noteFormData.append("description", description);
    noteFormData.append("location", userLocation);
    noteFormData.append("post_type", createboard.post_type);
    noteFormData.append("user_id", createboard.user_id);
    // if (selectedFile) noteFormData.append("media", selectedFile);

    try {
      const noteResponse = await axios.post(
        `${Config.apiBaseUrl}posts`,
        noteFormData
      );
      console.log("noteResponse", noteResponse);
      if (noteResponse.status === 200) {
        const postId = noteResponse.data.data.post_id;
        // alert("Note saved successfully.");

        // Proceed with board creation if necessary
        // if (isNewBoardPopupVisible) {
        console.log("boardselectedimage", selectedFile);
        const boardFormData = new FormData();
        boardFormData.append("board_name", createboard.board_name);
        boardFormData.append("user_id", createboard.user_id);
        if (selectedFile) boardFormData.append("image", selectedFile); // Assuming you have the image as File object
        boardFormData.append("is_public", createboard.is_public);
        boardFormData.append("location", createboard.location);

        const boardResponse = await axios.post(
          `${Config.apiBaseUrl}boards`,
          boardFormData
        );
        if (boardResponse.status === 200) {
          const boardId = boardResponse.data.data.board_id;
          const postBody = {
            board_id: boardId,
            post_id: postId,
            user_id: sessionStorage.getItem("user_id"),
          };
          console.log("###################### postBody", postBody);
          try {
            const response = await axios.post(
              `${Config.apiBaseUrl}boarditems`,
              postBody
            );
            console.log("Success:", response.data);
            toast.success("Post added to board successfully!");
            // setShowNewNotePopup(false);
            resetForm();
            onRefresh();
          } catch (error) {
            console.error("Error:", error);
            toast.error("Failed to add post to board");
          }
        }
      } else {
        toast.error("Failed to save note.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.info("An error occurred while saving the note.");
    }
  };

  if (!show) return null;

  const handleAddToBucketList = async () => {
    // Check if the title is empty
    if (!title.trim()) {
      toast.error("Note name is required.");
      return;
    }

    // Check if the description is less than 50 characters
    if (description.trim().length < 50) {
      console.log("description.trim().length", description.trim().length);
      toast.info("Description must be at least 50 characters long.");
      return;
    }
    const noteFormData = new FormData();
    noteFormData.append("title", title);
    noteFormData.append("description", description);
    noteFormData.append("location", userLocation);
    noteFormData.append("post_type", createboard.post_type);
    noteFormData.append("user_id", createboard.user_id);
    // If you have a file to upload
    // if (selectedFile) noteFormData.append('media', selectedFile);

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}posts`,
        noteFormData
      );
      console.log("Response:", response);

      if (response.status === 200) {
        // Post ID can be used for further actions if needed
        toast.success("Note added to bucket list successfully.");

        if (flag === "board") {
          console.log("selectedBoardId", selectedBoardId);
          // Save the post to a board
          const postId = response.data.data.post_id;
          const boardId = selectedBoardId;
          const userId = sessionStorage.getItem("user_id");

          const boardItemData = {
            board_id: boardId,
            post_id: postId,
            user_id: userId,
          };

          const boardItemResponse = await axios.post(
            `${Config.apiBaseUrl}boarditems`,
            boardItemData
          );
          if (boardItemResponse.status === 200) {
            console.log("Post added to board successfully");
            toast.success("Post added to board successfully");
            // Handle success case
          } else {
            console.error("Failed to add post to board");
            // Handle error case
          }
        }
        // setShowFilePostPopup(false);
        // Close popup or perform other actions after success
        resetForm();
        onRefresh(); // Call the onRefresh function to trigger refresh
        // onClose();
      } else {
        toast.error("Failed to add note to bucket list.");
      }
    } catch (error) {
      console.error(
        "An error occurred while adding note to bucket list:",
        error
      );
      toast.error("An error occurred while adding note to bucket list.");
    }
  };

  const handleBoardClick = async (id) => {
    // Step 1: Submit the note
    const noteFormData = new FormData();
    noteFormData.append("title", title);
    noteFormData.append("description", description);
    noteFormData.append("location", userLocation);
    noteFormData.append("post_type", createboard.post_type);
    noteFormData.append("user_id", createboard.user_id);
    if (selectedFile) noteFormData.append("media", selectedFile);

    try {
      const noteResponse = await axios.post(
        `${Config.apiBaseUrl}posts`,
        noteFormData
      );
      console.log("noteResponse", noteResponse);
      if (noteResponse.status === 200) {
        const postId = noteResponse.data.data.post_id;

        console.log("Note saved successfully.", noteResponse);
        console.log("postId", postId);
        const postBody = {
          board_id: id,
          post_id: postId,
          user_id: sessionStorage.getItem("user_id"),
        };

        try {
          const response = await axios.post(
            `${Config.apiBaseUrl}boarditems`,
            postBody
          );
          console.log("Success:", response.data);
          toast.success("Note added to board successfully!");
          resetForm();
          onRefresh(); // Call the onRefresh function to trigger refresh
          // setBoardPostStatus((prev) => ({ ...prev, [id]: true }));
          // fetchPosts();
        } catch (error) {
          console.error("Error:", error);
          toast.error("Failed to add post to board");
        }
      }
    } catch (error) {
      console.error("An error occurred while saving the note:", error);
      toast.error("An error occurred while saving the note.");
    }
  };

  return (
    <div className="newNotePopupOverlay" onClick={handleClose}>
      <div className="newNotePopup" onClick={(e) => e.stopPropagation()}>
        <button className="close-popup" onClick={handleClose}>
          X
        </button>
        <div className="newNotePopupHeader">
          <div className="leftGroup">
            <div className="userProfile">
              <img
                src={userProfile}
                alt="User"
                className="userProfileImage p-2"
              />
              <span className="username">{username}</span>
            </div>
          </div>
          <span>
            <input
              type="text"
              placeholder="Enter Title Name"
              className={`noteTitleInput ${titleError ? "inputError" : ""}`}
              value={title}
              onChange={handleTitleChange}
            />
          </span>
          <div className="current_Location">
            <input
              type="text"
              value={locationInput}
              onChange={handleLocationChange}
              placeholder="Location"
              className="location_input"
            />
            {/* <span className="location_icon">
              <img src={Location} alt="Location Icon" />
            </span> */}
            <div className="location_suggestions1">
              <LocationSuggestions
                location={locationInput}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                onSuggestionClick={handleLocationSelect}
              />
            </div>
          </div>
        </div>

        <TextEditorToolbar textAreaRef={textAreaRef} />

        <div
          ref={textAreaRef}
          contentEditable
          className={`noteInput ${descriptionError ? "inputError" : ""}`}
          onInput={handleDescriptionInput}
        ></div>

        <div className="newNotePopupActions">
          <div className="d-flex">
            <button className="action-button delete-button">
              <img src={Delete} alt="Delete" />
              <span>Delete</span>
            </button>
            {/* <button className="action-button add-button ms-5">
              <img
                src={PlusIcon}
                alt="Add to Board"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNewBoardPopup();
                }}
              />
              <span>Add to Board</span> */}
            {flag !== "board" && (
              // <button className="action-button add-button ms-5">
              //   <img
              //     src={PlusIcon}
              //     alt="Add to Board"
              //     disabled={!title.trim() || !description.trim()}
              //     onClick={(e) => {
              //       e.stopPropagation();
              //       toggleNewBoardPopup();
              //     }}
              //   />
              //   <span>Add to Board</span>
              <button
                className="action-button add-button ms-5"
                disabled={!title.trim() || !description.trim()} // Disable button if title or description is empty
                onClick={(e) => {
                  e.stopPropagation();
                  toggleNewBoardPopup();
                }}
              >
                <img src={PlusIcon} alt="Add to Board" />
                <span>Add to Board</span>

                {isNewBoardPopupVisible && (
                  <div className="addboardoverlay">
                    <div className="add_Note_Toboard_popup">
                      <span
                        className="close-popup close-popup-1"
                        onClick={toggleNewBoardPopup}
                      >
                        X
                      </span>
                      <div
                        className="add-board-action"
                        onClick={() => toggleCreateNewBoardPopup()}
                      >
                        <img src={addButton} alt="Add" />
                        <span>Create New board</span>
                      </div>
                      <div className="board-list">
                        <div className="board-list">
                          {isLoading ? (
                            <div>Loading...</div>
                          ) : error ? (
                            <div>Error: {error.message}</div>
                          ) : boardNotePosts.length > 0 ? (
                            boardNotePosts.map((board) => (
                              <div
                                className="board-item"
                                key={board.id}
                                onClick={() => handleBoardClick(board.id)}
                              >
                                {board.title}
                              </div>
                            ))
                          ) : (
                            <div>No existing boards found.</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </button>
            )}
            {isCreateNewBoardPopupVisible && (
              <div className="editPopup_overlay" onClick={onClose}>
                <div
                  className="editPopup_content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <form onSubmit={submitHandler}>
                    <div className="editPopup_modalHeader">
                      <input
                        type="text"
                        className="editPopup_input"
                        placeholder="New board name"
                        value={createboard.board_name}
                        name="board_name"
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="editPopup_modalBody">
                      <div
                        className="editPopup_imageContainer"
                        onClick={triggerFileInput}
                      >
                        {boardselectedimage ? (
                          <>
                            <img
                              src={boardselectedimage}
                              alt="Selected cover"
                              className="editPopup_selectedImage"
                            />
                            <div
                              className="editPopup_overlayText"
                              style={{ color: "#FFF" }}
                            >
                              Click to change
                            </div>
                          </>
                        ) : (
                          <div
                            className="editPopup_uploadText"
                            style={{ color: "#FFF" }}
                          >
                            Upload cover image
                          </div>
                        )}
                        <input
                          id="editPopup_fileInput"
                          type="file"
                          className="editPopup_hidden"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </div>

                      <div className="editPopup_buttons">
                        <button
                          className="editPopup_cancelButton"
                          onClick={postImageCancel}
                        >
                          Cancel
                        </button>
                        <button
                          className="editPopup_createButton"
                          type="submit"
                          disabled={
                            !createboard.board_name.trim() ||
                            !boardselectedimage
                          } // Disable the create button if board name is empty or no image selected
                        >
                          Create
                        </button>

                        {/* <button
                          className="editPopup_createButton"
                          type="submit"
                        >
                          Create
                        </button> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
            <button className="action-button share-button ms-5">
              <img src={roundlogo} alt="Share to Main Feed" />
              <span>Share to main feed</span>
            </button>
          </div>
          <div>
            <button
              style={{ padding: "15px 20px" }}
              className={`action-button ${
                description.trim().length > 0 && title.trim().length > 0
                  ? "highlighted"
                  : ""
              }`}
              onClick={handleAddToBucketList}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   onClose();

              //   console.log("Save to Bucketlist button clicked");

              //   // setShowFilePostPopup(false);
              //   onSaveToBucketlist({
              //     description,
              //     selectedPostFile: "",
              //     title,
              //     tags: [],
              //     userLocation,
              //     postType: "note",
              //   }).catch((error) => {
              //     console.error("Error saving to bucketlist:", error);
              //   });
              // }}
              // disabled={!description.trim() || !title.trim()} // The button is disabled unless both fields are filled in
            >
              {flag === "board" ? "Save to Board" : "Save to Bucketlist"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewNotePopup;
