import React, { useState, useEffect } from "react";
import "./BucketListPopup.css";
import TM_bucketlist from "./../../../../Assets/Images/bucketlist.png";
import axios from "axios";
import Config from "../../../../Assets/Config";
import { toast } from "react-toastify";

const BucketListPopup = ({
  selectedImageData,
  postId,
  onRefresh,
  onClose,
  flag,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [createBoard, setCreateBoard] = useState({
    board_name: "",
    is_public: false,
    location: "",
    user_id: Number(sessionStorage.getItem("user_id")),
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [boardSelectedImage, setBoardSelectedImage] = useState(null);
  const [boardPostStatus, setBoardPostStatus] = useState({});
  const [boards, setBoards] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddingToBoard, setIsAddingToBoard] = useState(false);
  const publicId = sessionStorage.getItem("public_id");

  const fetchBoards = async () => {
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/user/${publicId}`
      );
      setBoards(response.data.data);

      const initialStatus = {};
      response.data.data.forEach((board) => {
        if (board.post_ids.includes(selectedImageData.post_id)) {
          initialStatus[board.board_id] = true;
        }
      });
      setBoardPostStatus(initialStatus);
    } catch (error) {
      console.error("Error fetching boards data:", error);
    }
  };

  useEffect(() => {
    fetchBoards();
  }, [publicId, selectedImageData.post_id]);

  const changeHandler = (e) => {
    setCreateBoard({ ...createBoard, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png")
    ) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBoardSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setBoardSelectedImage(null);
      toast.error("Only JPEG, JPG, and PNG files are accepted.");
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions

    if (!createBoard.board_name.trim() || !selectedFile) {
      toast.error("Board name and image are required.");
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("user_id", createBoard.user_id);
    formData.append("board_name", createBoard.board_name);
    formData.append("image", selectedFile);
    formData.append("location", createBoard.location);
    formData.append("is_public", createBoard.is_public.toString());

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}boards`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        const newBoardId = response.data.data.board_id;
        if (selectedImageData) {
          const postBody = {
            board_id: newBoardId,
            post_id: selectedImageData.post_id,
            user_id: createBoard.user_id,
          };
          await axios.post(`${Config.apiBaseUrl}boarditems`, postBody, {
            headers: {
              "Content-Type": "application/json",
            },
          });
          toast.success("Board created and post added successfully!");
          await fetchBoards();
          onRefresh();
          onClose();
        }
        setShowPopup(false);
      }
    } catch (error) {
      console.error("Error creating board or adding post:", error);
      toast.error("Failed to create board or add post");
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleBoardSelection = async (id) => {
    if (boardPostStatus[id]) {
      toast.info("This post is already added to this board.");
      return;
    }
    if (isAddingToBoard) {
      return; // Prevent multiple submissions
    }

    setIsAddingToBoard(true);
    const postBody = {
      board_id: id,
      post_id: selectedImageData.post_id,
      user_id: sessionStorage.getItem("user_id"),
    };
    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}boarditems`,
        postBody
      );
      toast.success("Post added to board successfully!");
      setBoardPostStatus((prevStatus) => {
        const newStatus = { ...prevStatus };
        newStatus[id] = true;
        return newStatus;
      });
      onRefresh();
      onClose();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to add post to board");
    } finally {
      setIsAddingToBoard(false);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    onClose && onClose();
  };

  const savePostToBucketList = async () => {
    if (!selectedImageData) {
      console.error("selectedImageData is null or undefined");
      return;
    }
    const postBody = {
      bucket_id: Number(sessionStorage.getItem("user_id")),
      bucket_type: selectedImageData.post_type,
      bucket_type_id: selectedImageData.post_id,
    };
    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}bucketlist`,
        postBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Bucket entry added successfully");
      await fetchBoards();
      onClose();
    } catch (error) {
      toast.info("Post already exists in your bucket list");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const initialStatus = {};
    boards.forEach((board) => {
      if (board.post_ids.includes(selectedImageData.post_id)) {
        initialStatus[board.board_id] = true;
      }
    });
    setBoardPostStatus(initialStatus);
  }, [boards, selectedImageData]);

  return (
    <>
      <div className="popup-backdrop" onClick={closePopup}>
        <div
          className="popup-content-image"
          onClick={(e) => e.stopPropagation()}
        >
          {flag !== "bucket" && (
            <div onClick={savePostToBucketList} className="popup-item">
              <img src={TM_bucketlist} alt="Bucket" className="popup-icon" />
              <span>Save to Bucket List</span>
            </div>
          )}
          <div
            className="popup-item add-to-new-board"
            onClick={() => setShowPopup(!showPopup)}
          >
            <button className="add-to-new-board-btn">+</button>
            <span>Add to new board</span>
          </div>
          {boards.map((board) => (
            <div
              key={board.board_id}
              className="popup-item"
              onClick={() => toggleBoardSelection(board.board_id)}
            >
              <div
                className={
                  boardPostStatus[board.board_id]
                    ? "popup-board-circle selected"
                    : "popup-board-circle"
                }
              >
                {boardPostStatus[board.board_id] ? "✓" : "+"}
              </div>
              <span>{board.title}</span>
            </div>
          ))}
        </div>
      </div>

      {showPopup && (
        <div className="editPopup_overlay" onClick={closePopup}>
          <div
            className="editPopup_content"
            onClick={(e) => e.stopPropagation()}
          >
            <form onSubmit={submitHandler}>
              <div className="editPopup_modalHeader">
                <input
                  type="text"
                  className="editPopup_input"
                  placeholder="New board name"
                  name="board_name"
                  onChange={changeHandler}
                />
                <div
                  className="editPopup_imageContainer"
                  onClick={() =>
                    document.getElementById("editPopup_fileInput").click()
                  }
                >
                  {boardSelectedImage ? (
                    <img
                      src={boardSelectedImage}
                      alt="Selected cover"
                      className="editPopup_selectedImage"
                    />
                  ) : (
                    <div
                      className="editPopup_uploadText"
                      style={{ color: "#FFF" }}
                    >
                      Upload cover image
                    </div>
                  )}
                  <input
                    id="editPopup_fileInput"
                    type="file"
                    className="editPopup_hidden"
                    accept="image/jpeg, image/jpg, image/png"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div className="editPopup_modalBody">
                <button
                  type="button"
                  className="editPopup_cancelButton"
                  onClick={() => setShowPopup(false)}
                >
                  Cancel
                </button>
                {/* <button type="submit" className="editPopup_saveButton">
                  {isSubmitting ? "Creating..." : "Create Board"}
                </button> */}
                <button
                  type="submit"
                  className="editPopup_createButton"
                  disabled={isSubmitting}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default BucketListPopup;
