import React, { createContext, useContext, useState } from "react";

const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

export const SearchProvider = ({ children }) => {
  const [posts, setPosts] = useState([]);
  const [query, setQuery] = useState([]);
  const [boardPostsName, setBoardPostsName] = useState("");
  const [selectedpost, setselectedpost] = useState("");

  const addQuery = (newQuery) => {
    setQuery((prevQueries) => [...prevQueries, newQuery]);
  };

  const removeQuery = (index) => {
    setQuery((prevQueries) => prevQueries.filter((_, i) => i !== index));
  };

  const clearQuery = () => {
    setQuery([]);
  };
  return (
    <SearchContext.Provider
      value={{
        posts,
        setPosts,
        query,
        addQuery,
        removeQuery,
        clearQuery,
        boardPostsName,
        setBoardPostsName,
        selectedpost,
        setselectedpost,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
