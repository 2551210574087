import React, { useState, useRef, useEffect } from "react";
import "./EditPostPopup.css";
import message from "./../../../../Assets/Images/Ellipse 25.png";
import like from "./../../../../Assets/Images/Like Icon.png";
import Location from "./../../../../Assets/Images/locationpointer .png";
import bucketlist from "./../../../../Assets/Images/bucketlist.png";
import TM_Vector19 from "./../../../../Assets/Images/Vector 19.png";
import Config from "./../../../../Assets/Config"; // Assuming this holds your API base URL
import axios from "axios";
import LocationSuggestions from "../../../../Location/LocationSuggestions";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";

function EditPostPopup({ show, post, onClose, onSave, onRefreshCallback }) {
  // Expanded editContent state to include possible description editing
  const [editContent, setEditContent] = useState({
    type: "",
    value: "",
    description: "",
    location: post.location || "", // Assuming location is a property of your post
    title: post.title || "",
  });
  const fileInputRef = useRef(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [popupCommentInput, setPopupCommentInput] = useState("");
  const [activeComment, setActiveComment] = useState(null);
  const [hashtags, setHashtags] = useState(editContent.hash_tags || []);
  const [postType, setPostType] = useState("note");
  const commentInputRef = useRef(null); // Ref for focusing the input on reply click
  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (e) => {
    setEditContent({ ...editContent, location: e.target.value });
    setLocationInput(e.target.value);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setEditContent({ ...editContent, location: location });
  };

  const handleReplyClick = (commentId) => {
    setActiveComment(commentId);
    if (commentInputRef.current) {
      commentInputRef.current.focus();
    }
  };

  useEffect(() => {
    console.log("############################## Post data:", post);
  }, [post]); // The post data will be logged every time the 'post' object changes

  const fetchCommentsByPostId = async (postId) => {
    setLoadingComments(true);
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`
      );
      console.log("setComments", response.data.data);
      setComments(response.data.data);
      setLoadingComments(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setLoadingComments(false);
    }
  };

  const handlePopupCommentSubmit = async (e) => {
    e.preventDefault();
    const commentText = popupCommentInput;
    if (!commentText.trim()) return;

    const postData = {
      post_id: editContent.id,
      parent_comment_id: activeComment || null,
      public_id: sessionStorage.getItem("public_id"),
      comment_text: commentText,
    };

    try {
      await axios.post(`${Config.apiBaseUrl}posts/comments`, postData);
      setPopupCommentInput("");
      await fetchCommentsByPostId(editContent.id);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  useEffect(() => {
    if (show) {
      const contentType = post.post_type === "note" ? "note" : "post";
      setHashtags(post.hash_tags || []);
      const contentValue =
        post.post_type === "note" ? post.description : post.image_url;

      const initialEditContent = {
        type: post.post_type,
        value: contentValue,
        description: post.description || "",
        location: post.location || "",
        id: post.id,
        title: post.title || "",
        user_logo: post.user.user_profile_logo,
        username: post.user.username,
      };
      setEditContent(initialEditContent);
      fetchCommentsByPostId(post.id);
    } else {
      setEditContent({
        type: "",
        value: "",
        description: "",
        location: "",
        id: null,
        title: "",
      });
    }
  }, [show, post]);

  const handleSave = async () => {
    const url = `${Config.apiBaseUrl}/posts`;
    const formData = new FormData();
    formData.append("post_id", editContent.id);
    formData.append("title", editContent.title || "");
    formData.append("description", editContent.description);
    formData.append("link", editContent.link || "");
    formData.append("post_type", editContent.type);
    formData.append("is_public", String(editContent.isPublic));
    formData.append("location", editContent.location);

    if (editContent.hash_tags) {
      editContent.hash_tags.forEach((tag) => {
        formData.append("hash_tags[]", tag);
      });
    }

    if (editContent.file && editContent.type === "post") {
      formData.append("image", editContent.file);
    }

    try {
      const response = await axios({
        method: "put",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success("Post updated successfully");
      onSave(response.data);
      onRefreshCallback();
      onClose();
    } catch (error) {
      toast.error("Error updating post:", error);
      console.error("Error updating post:", error);
    }
  };

  const handleDescriptionChange = (e) => {
    setEditContent((prevState) => ({
      ...prevState,
      description: e.target.value,
      post_type: prevState.post_type === "post" ? "post" : "note",
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (file && !allowedImageTypes.includes(file.type)) {
      toast.error("Only JPEG, JPG, and PNG files are accepted.");
      return;
    }
    if (file && file.type.startsWith("image/")) {
      const imageUrl = URL.createObjectURL(file);
      setEditContent((prevState) => ({
        ...prevState,
        type: "post",
        value: imageUrl,
        file,
        post_type: "post",
      }));
    } else {
      toast.error("Please select a valid image file.");
    }
  };

  const handleNoteChange = (e) => {
    setEditContent({ ...editContent, value: e.target.value });
    setPostType("note");
  };

  const handleClose = () => {
    setEditContent({ type: "", value: "", description: "" });
    onClose();
  };

  const handleHashtagChange = (e) => {
    if (e.key === "Enter") {
      let input = e.target.value.trim().replace(/\s+/g, " ");
      let tagList = input.split(" ");
      tagList = tagList.map((tag) => {
        if (!tag.startsWith("#")) {
          return `#${tag}`;
        }
        return `#${tag.slice(tag.lastIndexOf("#") + 1)}`;
      });
      tagList = tagList.filter((tag) => !hashtags.includes(tag));
      setHashtags([...hashtags, ...tagList]);
      setEditContent((prevState) => ({
        ...prevState,
        hash_tags: [...(prevState.hash_tags || []), ...tagList],
        post_type: "post",
      }));
      e.target.value = "";
    }
  };

  const removeHashtag = (tag) => {
    setHashtags(hashtags.filter((t) => t !== tag));
    setEditContent((prevState) => ({
      ...prevState,
      hash_tags: prevState.hash_tags.filter((t) => t !== tag),
      post_type: "post",
    }));
  };

  if (!show) return null;

  return (
    <div className="edit_popupoverlay d-flex">
      <div className="edit-popup-container">
        <button className="close-popup" onClick={handleClose}>
          X
        </button>

        {editContent.type === "post" ? (
          <div className="edit-post-popup-image-container">
            {editContent.value.includes("youtube.com") ? (
              <ReactPlayer
                url={editContent.value}
                controls
                className="edit-post-popup-image youtube_popup"
              />
            ) : (
              <>
                {editContent.value.endsWith(".mp4") ? (
                  <video
                    src={editContent.value}
                    alt="Edit"
                    className="edit-post-popup-image youtube_popup"
                    controls
                  />
                ) : (
                  <img
                    src={editContent.value}
                    alt="Edit"
                    className="edit-post-popup-image youtube_popup"
                  />
                )}
              </>
            )}
            <span
              className="edit-post-popup-select-image"
              onClick={() => fileInputRef.current.click()}
            >
              Change Post
            </span>
            <input
              type="file"
              onChange={handleFileChange}
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div className="location-text">
              <input
                type="text"
                value={editContent.location}
                onChange={handleLocationChange}
                placeholder="Edit location"
                className="edit-location-input"
              />
              <span className="pointer-image"></span>
              <div className="location_suggestions">
                <LocationSuggestions
                  location={locationInput}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  onSuggestionClick={handleLocationSelect}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="edit-note-container">
            <textarea
              className="edit-note-description-input"
              value={editContent.description}
              onChange={handleDescriptionChange}
              placeholder="Edit description"
            />
          </div>
        )}
      </div>
      <div className="vertical-line"></div>
      <div style={{ width: "90%" }}>
        <div className="top-section">
          <div className="profilename">
            <img
              src={editContent.user_logo}
              alt="Profile"
              className="profile-logo"
            />
            <span>{editContent.username || "Username"}</span>
          </div>
          {editContent.type === "post" && (
            <div className="disc my-file-disc">
              <textarea
                type="text"
                value={editContent.description}
                onChange={handleDescriptionChange}
                placeholder="Edit description"
                className="edit-description-input"
              />
              <input
                type="text"
                onKeyDown={handleHashtagChange}
                placeholder="Add #tag"
                className="ep-hashtag-input"
              />
              <div className="edit-post-hashtag">
                <div className="ep-hashtags-container">
                  {hashtags.map((tag, index) => (
                    <div key={index} className="ep-hashtag">
                      {tag}
                      <button
                        onClick={() => removeHashtag(tag)}
                        className="ep-hashtag-remove-btn"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="horizonta-line "></div>
        <div className="input-section">
          <button
            onClick={handleSave}
            className="edit-post-popup-save-button-2 "
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditPostPopup;
