import React, { useState, useEffect, useRef } from "react";
import "./Landingpage.css";
import { useNavigate, Outlet } from "react-router-dom";
import TM_Logo from "./../../Assets/Images/TM_Logo.png";
import TM_profile from "./../../Assets/Images/userProfile.png";
import TM_searchIcon from "./../../Assets/Images/images.png";
import TM_Homeicon from "./../../Assets/Images/Home.png";
import TM_bucketlisticon from "./../../Assets/Images/bucketlist.png";
import TM_Personicon from "./../../Assets/Images/Person.png";
import TM_Likeicon from "./../../Assets/Images/Like.png";
import TM_Settingsicon from "./../../Assets/Images/Settings.png";
import FooterLoginTMLogo from "./../../Assets/Images/FooterLoginTMLogo.png";
import Footer from "../Footer/Footer";
import { useSearch } from "./../../Assets/queryprovider";
import useSessionTimeout from "../../SessionTimeout/useSessionTimeout";

const Landingpage = () => {
  useSessionTimeout(300000);
  const navigate = useNavigate();
  const { addQuery, clearQuery } = useSearch();
  const [searchInput, setSearchInput] = useState("");
  const [showLogout, setShowLogout] = useState(false);
  const [activeIcon, setActiveIcon] = useState("home"); // Default active icon
  const logoutRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleFilterSearchClick = (e) => {
    e.preventDefault();
    const trimmedInput = searchInput.trim();
    if (trimmedInput) {
      navigate("/filtersearch", {
        state: { filterSearch: trimmedInput, filterFlag: "g" },
      });
    }
  };

  const handleIconClick = (iconName, path) => {
    setActiveIcon(iconName);
    navigateAndClearSearch(path);
  };

  const navigateAndClearSearch = (path) => {
    setSearchInput("");
    clearQuery();
    navigate(path);
  };

  const toggleLogoutOption = () => {
    setShowLogout(!showLogout);
  };

  const LogoutHandler = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="landing-pg-bgs" style={{ backgroundColor: "#131313" }}>
      <div className="fixedNav">
        <nav className="d-flex topnav_color">
          <img src={TM_Logo} alt="TM Logo" className="tm-logo" />
          <img
            src={FooterLoginTMLogo}
            alt="TM Logo Mobile"
            className="tm-logo-mobile"
          />
          <form className="searchbtn" onSubmit={handleFilterSearchClick}>
            <img
              src={TM_searchIcon}
              alt="Search Icon"
              className="search-icon"
            />
            <input
              type="text"
              value={searchInput}
              onChange={handleInputChange}
              placeholder="Start exploring..."
            />
          </form>
          <div className="nav-search-link d-flex">
            <div className="profile-container" onClick={toggleLogoutOption}>
              <div className="user-image-or-letter">
                {sessionStorage.getItem("user_profile_logo") ? (
                  <img
                    src={sessionStorage.getItem("user_profile_logo")}
                    alt="User"
                    className="profile_image"
                  />
                ) : (
                  sessionStorage.getItem("username").charAt(0).toUpperCase()
                )}
              </div>
              {showLogout && (
                <div
                  ref={logoutRef}
                  className="logout-option"
                  onClick={LogoutHandler}
                >
                  Logout
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>
      <div className="row landing-d-gride">
        <div className="col-md-12 outlet">
          <div className="sidenavFixed sidenav-icons sidenav">
            <div
              className={`sidenav-icon ${
                activeIcon === "home" ? "active" : ""
              }`}
              onClick={() => {
                handleIconClick("home");
                navigateAndClearSearch("home");
              }}
            >
              <img
                src={TM_Homeicon}
                alt="Home Icon"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              className={`sidenav-icon ${
                activeIcon === "bucketlist" ? "active" : ""
              }`}
              onClick={() => {
                handleIconClick("bucketlist");
                navigateAndClearSearch("bucketlist");
              }}
            >
              <img
                src={TM_bucketlisticon}
                alt="Bucketlist Icon"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              // className={`sidenav-icon ${
              //   activeIcon === "profile" ? "active" : ""
              // }`}
              // onClick={() => handleIconClick("profile", "profile")}

              className={`sidenav-icon ${
                activeIcon === "profile" ? "active" : ""
              }`}
              onClick={() => {
                handleIconClick("profile");
                navigateAndClearSearch("profile");
              }}
            >
              <img
                src={TM_Personicon}
                alt="Profile Icon"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              // className={`sidenav-icon ${
              //   activeIcon === "likes" ? "active" : ""
              // }`}
              // onClick={() => handleIconClick("likes", "likes")}

              className={`sidenav-icon ${
                activeIcon === "likes" ? "active" : ""
              }`}
              onClick={() => {
                handleIconClick("likes");
                navigateAndClearSearch("likes");
              }}
            >
              <img
                src={TM_Likeicon}
                alt="Likes Icon"
                style={{ cursor: "pointer" }}
              />
            </div>
            <div
              // className={`sidenav-icon ${
              //   activeIcon === "settings" ? "active" : ""
              // }`}
              // onClick={() => handleIconClick("settings", "settings")}

              className={`sidenav-icon ${
                activeIcon === "settings" ? "active" : ""
              }`}
              onClick={() => {
                handleIconClick("settings");
                navigateAndClearSearch("settings");
              }}
            >
              <img
                src={TM_Settingsicon}
                alt="Settings Icon"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="outlet_body">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="site-container">
        <div className="content-wrap"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Landingpage;
