import React, { useEffect, useRef, useState } from "react";
import "./Signup.css";
import Footer from "../Footer/Footer";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ImageGrid from "./ImageGrid/ImageGrid";
import Config from "../../Assets/Config";
import DashboardImages from "./DashboardScaticImages/DashboardImages";
import TMlogo from "./../../Assets/Images/TMlogo.png";
import FooterLoginTMLogo from "./../../Assets/Images/FooterLoginTMLogo.png";
import NavHamberger from "./../../Assets/Images/NavHamberger.png";
import { toast } from "react-toastify";

import iconlike from "./../../Assets/Images/icon-like.svg";
import iconcomment from "./../../Assets/Images/icon-comment.svg";
import iconcheckmark from "./../../Assets/Images/icon-checkmark.svg";
import MobileImageGrid from "./ImageGrid/MobileImageGrid";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Signup = (lo) => {
  const [imageClickCount, setImageClickCount] = useState(0);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const signupRef = useRef(null);

  const [condition, setcondition] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);
  const [logincondition, setlogincondition] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [rigvalidationerrors, setvalidationErrors] = useState({});
  const [showSignupPassword, setShowSignupPassword] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);

  const handleImageClick = () => {
    setImageClickCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (imageClickCount > 1) {
      navigate("/");
      window.scrollTo(0, 0);
    }
  }, [imageClickCount]);

  useEffect(() => {
    const isAuthenticated = sessionStorage.getItem("userToken");
    if (!isAuthenticated) {
      navigate("/");
      window.scrollTo(0, 0);
    }
  }, [navigate]);

  const location = useLocation();

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const loginurl = `${Config.apiBaseUrl}auth/login`;
  const signupurl = `${Config.apiBaseUrl}auth/register`;

  const [data, setData] = useState({
    emailid: "",
    password: "",
    dob: "",
  });

  const [login, setLogin] = useState({
    identifier: "",
    password: "",
  });

  const loginchange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });

    setErrors({ ...errors, [e.target.name]: "" });
  };

  const emailRegex =
    /^[a-zA-Z0-9]{3,}[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isValidDate = (dateString) => {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

    if (dateString.match(regex) === null) {
      return false;
    }

    const [month, day, year] = dateString.split("/");
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === parseInt(year, 10) &&
      date.getMonth() === parseInt(month, 10) - 1 &&
      date.getDate() === parseInt(day, 10)
    );
  };

  const submit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!data.emailid.trim()) {
      validationErrors.emailid = "Email is required";
    } else if (!emailRegex.test(data.emailid)) {
      validationErrors.emailid = "Email must be at least 3 characters long";
    }
    if (!data.password.trim()) {
      validationErrors.password = "Password is required";
    } else if (data.password.trim().length < 8) {
      validationErrors.password = "Password must be at least 8 characters long";
    } else if (!/[A-Z]/.test(data.password) || !/[a-z]/.test(data.password)) {
      validationErrors.password =
        "Password must contain at least one uppercase and one lowercase letter";
    } else if (/\s/.test(data.password)) {
      validationErrors.password = "Password cannot contain spaces";
    }
    if (!data.dob.trim()) {
      validationErrors.dob = "Birthdate is required";
    } else if (!isValidDate(data.dob)) {
      validationErrors.dob = "Invalid date format (expected mm/dd/yyyy)";
    }

    setvalidationErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const Sinupdetails = {
          emailid: data.emailid,
          password: data.password,
          dob: data.dob,
        };
        const response = await axios.post(signupurl, Sinupdetails);
        if (response.status === 200) {
          toast.success("Successfully Signed Up");
          const user_id = response.data.data.user_id; // Capture user_id from response
          console.log("userId-----------------", user_id);

          setData({ ...data, emailid: "", password: "", dob: "" });
          navigate("/signupprocess", { state: { user_id } }); // Pass userId to the route component
        } else {
          console.error("Sign up failed", response);
          toast.error("Sign up failed: " + response.statusText);
        }
      } catch {
        console.error("Sign up failed");
        toast.error("Please enter details");
      }
    }
  };

  const LoginDetails = async (e) => {
    e.preventDefault();

    try {
      const logindetails = {
        identifier: login.identifier,
        password: login.password,
      };

      const response = await axios.post(loginurl, logindetails);
      if (response.status === 200) {
        console.log("response======================", response);

        const public_id = response.data.public_id;
        const token = response.data.token;
        const username = response.data.username;
        const user_id = response.data.user_id;
        const user_profile_logo = response.data.user_profile_logo;
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("username", username);
        sessionStorage.setItem("public_id", public_id);
        sessionStorage.setItem("user_id", user_id);
        sessionStorage.setItem("user_profile_logo", user_profile_logo);

        window.scrollTo(1, 0);
        navigate("/home");
      }
    } catch (error) {
      console.error("Login failed", error);
      // Check if the error response has a data property containing the error message
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Set the error message in the state or handle it in the desired way
        setErrors({ general: error.response.data.message });
      } else {
        // If the error response doesn't have a specific message, handle it differently
        setErrors({
          general: "An error occurred while logging in. Please try again.",
        });
      }
    }
  };

  const handleSignupClick = () => {
    if (signupRef && signupRef.current) {
      signupRef.current.scrollIntoView({ behavior: "smooth" });
    }
    setcondition(true);
    setlogincondition(false);
  };

  const handleClick = () => {
    setcondition(false);
    setlogincondition(true);
    setTimeout(() => {
      if (signupRef.current) {
        signupRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  useEffect(() => {
    axios
      .get(`${Config.apiBaseUrl}media/static`)
      .then((res) => {
        setImages(res.data.image_urls);
        console.log("Response API images :", res.data.image_urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);

  return (
    <div className=" dashboard-width">
      <nav className="nav_color">
        <div className="containedWrapper  d-flex">
          <img
            className="imagesize d-none d-lg-block"
            src={images.TMlogo || TMlogo} // If images.TMlogo is falsy, TMlogo will be used as the src
            alt="TMlogo"
            onError={(e) => {
              e.target.onerror = null; // prevents looping
              e.target.src = TMlogo; // the static fallback image
            }}
          />

          <img
            src={images.logo || FooterLoginTMLogo}
            alt="FooterLoginTMLogo"
            className="d-block d-lg-none  imagesize"
          />
          <div className="">
            <div className="d-lg-block d-none">
              <button className="login" onClick={handleClick}>
                Log In
              </button>
              <button className="signup ms-3" onClick={handleSignupClick}>
                Sign Up
              </button>
            </div>

            <div className="d-lg-none navhamberger">
              <span onClick={handleMobileMenuToggle}>
                <span>
                  <img
                    className="hamburger"
                    src={NavHamberger}
                    alt="NavHamberger.png"
                  />
                </span>
              </span>

              {isMobileMenuOpen && (
                <div className="mobile-menu ">
                  <div onClick={handleClick}>
                    <button className="login">Log In</button>
                  </div>
                  <div onClick={handleSignupClick}>
                    <button className="signup ">Sign Up</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      <>
        <div className="desktop-image-grid">
          <ImageGrid
            onImageClick={handleImageClick}
            handleSignupClick={handleSignupClick}
          />
        </div>
        <div className="mobile-image-grid">
          <MobileImageGrid
            onImageClick={handleImageClick}
            handleSignupClick={handleSignupClick}
          />
        </div>
      </>

      <DashboardImages />

      <div className=" ">
        <div className="background-login  ">
          <div>
            <div className="">
              {condition && (
                <div className="startPlanningWrapper">
                  <div className="iconWrapper">
                    <h4>Start planning your next adventure today.</h4>
                    <div className="icons">
                      <img
                        src={iconlike}
                        alt="like icon"
                        onError={(e) => {
                          e.target.onerror = null;
                        }}
                      />
                      <img
                        src={iconcomment}
                        alt="comment icon"
                        onError={(e) => {
                          e.target.onerror = null;
                        }}
                      />
                      <img
                        src={iconcheckmark}
                        alt="checkmark icon"
                        onError={(e) => {
                          e.target.onerror = null;
                        }}
                      />
                    </div>
                  </div>

                  <div ref={signupRef}>
                    <div className="logincard">
                      <div className="p-3 ">
                        <div>
                          <form onSubmit={submit}>
                            <center>
                              <img
                                src={images.logo || FooterLoginTMLogo}
                                alt="Waterfall"
                                className="Logo"
                                onError={(e) => {
                                  e.target.onerror = null; // prevents looping
                                  e.target.src = FooterLoginTMLogo; // the static fallback image
                                }}
                              />
                              <p className="welcometext d-none d-lg-block">
                                Welcome to <br />
                                Travel Magazine
                              </p>
                              <p className="welcometext d-block d-lg-none">
                                Start planning your <br />
                                adventure today.
                              </p>
                              <p className="createcontact d-block d-lg-none">
                                Create your account.
                              </p>

                              <div className="form-outline  mb-4 input-container">
                                <input
                                  type="email"
                                  onChange={handleInputChange}
                                  name="emailid"
                                  value={data.emailid}
                                  className={`form-control form-control-lg input-width ${
                                    rigvalidationerrors.emailid
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Email"
                                />
                                {rigvalidationerrors.emailid && (
                                  <div className="error_message">
                                    {rigvalidationerrors.emailid}
                                  </div>
                                )}
                              </div>

                              <div className="form-outline form-white mb-4 input-container">
                                <input
                                  type={
                                    showSignupPassword ? "text" : "password"
                                  }
                                  onChange={handleInputChange}
                                  name="password"
                                  value={data.password}
                                  className={`form-control form-control-lg input-width ${
                                    rigvalidationerrors.password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Password"
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    setShowSignupPassword(!showSignupPassword)
                                  }
                                  className="toggle-button"
                                >
                                  <i
                                    className={
                                      showSignupPassword
                                        ? "fas fa-eye-slash"
                                        : "fas fa-eye"
                                    }
                                  ></i>
                                </button>
                                {rigvalidationerrors.password && (
                                  <div className="error_message">
                                    {rigvalidationerrors.password}
                                  </div>
                                )}
                              </div>

                              <div className="form-outline form-white mb-4 input-container">
                                <input
                                  type="text"
                                  onChange={handleInputChange}
                                  name="dob"
                                  value={data.dob}
                                  className={`form-control form-control-lg input-width ${
                                    rigvalidationerrors.dob ? "is-invalid" : ""
                                  }`}
                                  placeholder="Birthdate mm/dd/yyyy"
                                />
                                {rigvalidationerrors.dob && (
                                  <div className="error_message">
                                    {rigvalidationerrors.dob}
                                  </div>
                                )}
                              </div>

                              <div>
                                <button className="continue_button">
                                  Continue
                                </button>
                              </div>
                            </center>
                            <center className="acknowledge  ">
                              By Continuing, you agree to Travel Magazine’s
                              <br />{" "}
                              <b className=" create_font">
                                Terms of Service
                              </b>{" "}
                              and acknowledge you’ve read
                              <br /> our{" "}
                              <b className=" create_font">Privacy Policy.</b>
                            </center>

                            <div>
                              <center className="acknowledge  ">
                                Already a member?&nbsp;
                                <a
                                  // href="#!"
                                  className="create_font fw-bold"
                                  onClick={handleClick}
                                >
                                  Log in.
                                </a>
                              </center>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div>
                {logincondition && (
                  <div className="startPlanningWrapper">
                    <div className="iconWrapper">
                      <h4>Start planning your next adventure today.</h4>
                      <div className="icons">
                        <img
                          src={iconlike}
                          alt="like icon"
                          onError={(e) => {
                            e.target.onerror = null;
                          }}
                        />
                        <img
                          src={iconcomment}
                          alt="comment icon"
                          onError={(e) => {
                            e.target.onerror = null;
                          }}
                        />
                        <img
                          src={iconcheckmark}
                          alt="checkmark icon"
                          onError={(e) => {
                            e.target.onerror = null;
                          }}
                        />
                      </div>
                    </div>
                    <div className="logincard p-3 " ref={signupRef}>
                      <form onSubmit={LoginDetails}>
                        <center>
                          <img
                            className="Logo"
                            src={images.logo || FooterLoginTMLogo}
                            alt="logo"
                            onError={(e) => {
                              e.target.onerror = null; // prevents looping
                              e.target.src = FooterLoginTMLogo; // the static fallback image
                            }}
                          />
                          <p className="welcometext">
                            Welcome to <br />
                            Travel Magazine
                          </p>
                          <div className="form-outline  mb-4 input-container">
                            <input
                              type="identifier"
                              onChange={loginchange}
                              name="identifier"
                              className="form-control form-control-lg input-width "
                              placeholder="Email/Username"
                            />
                            {errors.identifier && (
                              <div className="error_message">
                                {errors.identifier}
                              </div>
                            )}
                          </div>
                          <div className="form-outline form-white mb-4 input-container">
                            <input
                              type={showLoginPassword ? "text" : "password"}
                              onChange={loginchange}
                              name="password"
                              className="form-control form-control-lg input-width"
                              placeholder="Password"
                            />
                            <button
                              type="button"
                              onClick={() =>
                                setShowLoginPassword(!showLoginPassword)
                              }
                              className="toggle-button"
                            >
                              <i
                                className={
                                  showLoginPassword
                                    ? "fas fa-eye-slash"
                                    : "fas fa-eye"
                                }
                              ></i>
                            </button>
                            {errors.general && (
                              <div className="error_message">
                                {errors.general}
                              </div>
                            )}
                          </div>
                          <div>
                            <button className="continue_button">
                              Continue
                            </button>
                          </div>
                        </center>

                        <div>
                          <center className="acknowledge mt-5 mb-5 ">
                            Not on Travel Magazine? &nbsp;
                            <a
                              className="fw-bold create_font"
                              style={{ cursor: "pointer" }}
                              onClick={handleSignupClick}
                            >
                              Create an account
                            </a>
                          </center>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signup;
