import React, { useState } from "react";
import "./Settings.css";
import ProfileInformation from "./ProfileInformation/ProfileInformation";
import Emailandpassword from "./EmailAndPassword/Emailandpassword";
import Notifications from "./Notifications/Notifications";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const [selectedButton, setSelectedButton] = useState("ProfileInformation");
  const navigate = useNavigate();
  window.scroll(0, 0);

  const LogoutHandler = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="row stng-mble-d-block" style={{ marginLeft: "50px" }}>
      <div className="settingcol" style={{ backgroundColor: "#131313" }}>
        <div className="setting">Settings</div>
        <div className="stng-mble-d-flex">
          <div
            className={`profileinformation1 ${selectedButton === "ProfileInformation" ? "active" : ""
              }`}
            onClick={() => setSelectedButton("ProfileInformation")}
          >
            Profile Information
          </div>
          <div
            className={`profileinformation1 ${selectedButton === "EmailAndPassword" ? "active" : ""
              }`}
            onClick={() => setSelectedButton("EmailAndPassword")}
          >
            Email & Password
          </div>
          <div
            className={`profileinformation1 ${selectedButton === "Notifications" ? "active" : ""
              }`}
            onClick={() => setSelectedButton("Notifications")}
          >
            Notifications
          </div>
          <div className="profileinformation1" onClick={LogoutHandler}>
            Logout
          </div>
        </div>

      </div>
      <div className="col-8 right_nav right-setting-block">
        {selectedButton === "ProfileInformation" && <ProfileInformation />}
        {selectedButton === "EmailAndPassword" && <Emailandpassword />}
        {selectedButton === "Notifications" && <Notifications />}
      </div>
    </div>
  );
};

export default Settings;
