import React, { useState } from "react";

import Config from "../../../Assets/Config";

import "./Emailandpassword.css";

import axios from "axios";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { toast } from "react-toastify";

const Emailandpassword = () => {
  const [currentEmail, setCurrentEmail] = useState("");

  const [newEmail, setNewEmail] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const [repeatPassword, setRepeatPassword] = useState("");

  const [showNewPassword, setShowNewPassword] = useState(false);

  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const [errors, setErrors] = useState({});

  const validateField = (field, value) => {
    const newErrors = { ...errors };

    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/; // Minimum 8 characters, at least one letter, one number, and one special character

    const emailRegex = /\S+@\S+\.\S+/;

    switch (field) {
      case "currentEmail":
        if (!value) {
          newErrors.currentEmail = "Current email is required";
        } else if (!emailRegex.test(value)) {
          newErrors.currentEmail = "Current email is invalid";
        } else {
          delete newErrors.currentEmail;
        }

        break;

      case "newEmail":
        if (!value) {
          newErrors.newEmail = "New email is required";
        } else if (!emailRegex.test(value)) {
          newErrors.newEmail = "New email is invalid";
        } else {
          delete newErrors.newEmail;
        }

        break;

      case "newPassword":
        if (!value) {
          newErrors.newPassword = "New password is required";
        } else if (!passwordRegex.test(value)) {
          newErrors.newPassword =
            "Password must be at least 8 characters long and include at least one letter, one number, and one special character";
        } else {
          delete newErrors.newPassword;
        }

        break;

      case "repeatPassword":
        if (value !== newPassword) {
          newErrors.repeatPassword = "Passwords do not match";
        } else {
          delete newErrors.repeatPassword;
        }

        break;

      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;

    switch (field) {
      case "currentEmail":
        setCurrentEmail(value);

        break;

      case "newEmail":
        setNewEmail(value);

        break;

      case "newPassword":
        setNewPassword(value);

        break;

      case "repeatPassword":
        setRepeatPassword(value);

        break;

      default:
        break;
    }

    validateField(field, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    // Run validation on all fields
    validateField("currentEmail", currentEmail);
    validateField("newEmail", newEmail);
    validateField("newPassword", newPassword);
    validateField("repeatPassword", repeatPassword);
 
    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      return;
    }
 
    try {
      const userId = sessionStorage.getItem("user_id"); // Get the user_id from session or any other source
      const baseUrl = `${Config.apiBaseUrl}user`;
      // Create a FormData object
      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('current_email', currentEmail);
      formData.append('new_email', newEmail);
      formData.append('new_password', newPassword);
      formData.append('repeat_password', repeatPassword);
      formData.append('flag', 'change'); // Adding the flag
      const response = await axios.put(baseUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
 
      if (response.status === 200) {
        toast.success("Email and password updated successfully");
        console.log("Email and password updated successfully");
 
        // Optionally, you can reset the form fields here
        setCurrentEmail("");
        setNewEmail("");
        setNewPassword("");
        setRepeatPassword("");
        setErrors({});
      } else {
        toast.error("Failed to update email and password");
        console.error("Failed to update email and password");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="row">
      <div className="email-heading">Email & Password</div>

      <form onSubmit={handleSubmit}>
        <div>
          <label className="lablenames">Email</label>

          <div>
            <input
              type="text"
              placeholder="Current Email"
              className="form-control nameinput"
              value={currentEmail}
              onChange={handleChange("currentEmail")}
            />

            {errors.currentEmail && (
              <div className="error-message">{errors.currentEmail}</div>
            )}
          </div>

          <div>
            <input
              type="text"
              placeholder="New Email"
              className="form-control nameinput"
              value={newEmail}
              onChange={handleChange("newEmail")}
            />

            {errors.newEmail && (
              <div className="error-message">{errors.newEmail}</div>
            )}
          </div>
        </div>

        <div>
          <label className="lablenames">Password</label>

          <div className="input-container">
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="New Password"
              className="form-control nameinput"
              value={newPassword}
              onChange={handleChange("newPassword")}
            />

            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="toggle-button"
            >
              <i
                className={showNewPassword ? "fas fa-eye-slash" : "fas fa-eye"}
              ></i>
            </button>

            {errors.newPassword && (
              <div className="error-message">{errors.newPassword}</div>
            )}
          </div>

          <div className="input-container">
            <input
              type={showRepeatPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className="form-control nameinput"
              value={repeatPassword}
              onChange={handleChange("repeatPassword")}
            />

            <button
              type="button"
              onClick={() => setShowRepeatPassword(!showRepeatPassword)}
              className="toggle-button"
            >
              <i
                className={
                  showRepeatPassword ? "fas fa-eye-slash" : "fas fa-eye"
                }
              ></i>
            </button>

            {errors.repeatPassword && (
              <div className="error-message">{errors.repeatPassword}</div>
            )}
          </div>
        </div>

        <div>
          <button type="submit" className="form-control savebutton">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default Emailandpassword;
