import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../../Assets/Config";
import "./DashboardImages.css";
import InspirationTextGrouping from "./../../../Assets/Images/InspirationTextGrouping.png";
import SocialPostsGroup from "./../../../Assets/Images/SocialPostsGroup.png";
import layOut3a from "./../../../Assets/Images/layOut3a.png";
import layOut3b from "./../../../Assets/Images/layOut3b.png";
import layOut4 from "./../../../Assets/Images/layOut4.png";
import layOut4a from "./../../../Assets/Images/layOut4a.png";
import AIItineraries from "./../../../Assets/Images/AIItineraries.png";
import Ai2 from "./../../../Assets/Images/Ai2.png";
import Ai from "./../../../Assets/Images/Ai.png";
import Videoshow from "./../../../Assets/Images/Videoshow.png";
import Trip from "./../../../Assets/Images/Trip.png";
import Tripbaors from "./../../../Assets/Images/Tripbaors.png";
import PopularTrips from "../../UserHomepage/PopularTrip/PopularTrips";
// import ExploreGrid from "../ExploreGrid/ExploreGrid";
const DashboardImages = () => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    axios
      .get(`${Config.apiBaseUrl}media/static`)
      .then((res) => {
        setImages(res.data.image_urls);
        console.log("Response API images :", res.data.image_urls);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);
  const [imageSrc, setImageSrc] = useState(
    window.innerWidth >= 768 ? "layOut4" : "YellowBoards"
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setImageSrc("layOut4"); // Assuming 'layOut4' is either a path or a variable holding the image source
      } else {
        setImageSrc("YellowBoards"); // Similarly, for 'YellowBoards'
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div className="">
        <div className="box">
          <div className="rectangle">
            <div>
              <img
                className="layout1a"
                src={images.layOut_1a || InspirationTextGrouping}
                alt="Layout 1a"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = InspirationTextGrouping;
                }}
              />
              {/* <div className="text-overlay">
                Get inspiration from real travelers from all over the world.
                <button>Start Exploring</button>
              </div> */}
            </div>
            <div>
              <img
                className="layout1b"
                src={images.layOut_1b || SocialPostsGroup}
                alt="Layout 1b"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = SocialPostsGroup;
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 ">
        <div className="explore ">
          <div className="explorecard">
            <div>Explore itineraries</div>
            <p>
              See tested travel plans created by people who have gone to the
              same places you’re interested in.
            </p>

            <img
              src={Trip}
              alt="Layout 3a"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = Trip;
              }}
            />
          </div>
          <div className="createcard">
            <div>Create your own trip boards</div>
            <p>
              Save trip ideas, itineraries, articles, links, images, and videos,
              all in one neat and organized place.
            </p>
            <img
              src={Tripbaors}
              alt="Layout 3b"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = layOut3b;
              }}
            />
          </div>
        </div>
        {/* <div className="explore mt-5">
          <div className="Videoshow">
            <a
              href="http://voyagetvdemo.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <div>Watch your favorite shows</div>
              <p>
                Thousands of hours of custom travel content including familiar
                classics and soon-to-be favorites.
              </p>
              <img
                src={Ai2}
                alt="Video Show"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = Ai2;
                }}
              />
            </a>
          </div>{" "}
          
          <div className="AIItineraries">
            <a
              href="https://www.travelmagazine.com/travel-buddy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div>Get some AI-ssistance</div>
              <p>
                Need a little extra help putting together your itinerary? Our
                Travel A.I. will help you put together the perfect trip.
              </p>
              <img
                src={Ai}
                alt="AI Itineraries"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = Ai;
                }}
              />
            </a>
          </div>
        </div> */}
        {/* <center style={{ backgroundColor: "#FFB932", height: "450px" }}>
          <div className="mt-5 mb-5">
            <div>
              <div className="helpcard">
                Help other travelers with your expertise
              </div>

             
            </div>
            <div className="helpcard1">
              Share your experiences from start to finish and help others make
              good choices while avoiding pitfalls on their next vacation.
            </div>
            
          </div>
        </center> */}
        <>
          <div className="help-container mt-3 mb-3">
            <div className="help-card">
              <h2>Help other travelers with your expertise</h2>
              <p>
                Share your experiences from start to finish and help others make
                good choices while avoiding pitfalls on their next vacation.
              </p>
            </div>
            <img
              src={layOut4a}
              alt="Traveler Sharing Experience"
              className="help-image"
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default DashboardImages;
