import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import "./UserPost.css";
import NewNotePopup from "./NewNotePopup";
import Config from "../../../../Assets/Config";
import addButton from "./../../../../Assets/Images/addButton.png";
import { useSearch } from "../../../../Assets/queryprovider";
import PopupComponent from "./PopupComponent";
import FileUploadPopup from "./FileUploadPopup";
import PostFilePopup from "./PostFilePopup";
import arrowButton from "./../../../../Assets/Images/arrowButton.png";
import LinkPopup from "./LinkPopup";
import { X } from "@mui/icons-material";
import Morepostoption from "./Morepostoption";
import EditPostPopup from "./EditPostPopup";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Menu as MenuIcon } from "@mui/icons-material";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";

const UserPost = ({ onClose }) => {
  const [contentItems, setContentItems] = useState([]);
  const publicId = sessionStorage.getItem("public_id");
  const [loading, setLoading] = useState(true);
  const [loadingComments, setLoadingComments] = useState(false);
  const { posts, query } = useSearch();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupItem, setPopupItem] = useState(null);
  const [welcomeMessage, setWelcomeMessage] = useState({});
  const [isHamburgerPopupVisible, setIsHamburgerPopupVisible] = useState(false);
  const [comments, setComments] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // state for showing the popup
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [showNewNotePopup, setShowNewNotePopup] = useState(false); // For new note popup
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [showFilePostpopup, setShowFilePostpopup] = useState(false);
  const [userLocation, setUserLocation] = useState("");
  const [selectedsendPostFile, setSelectedsendPostFile] = useState(null);
  const [postNotesData, setPostNotesData] = useState("");
  const [selectedPostFile, setSelectedPostFile] = useState(null);
  const [finalPostTags, setFinalPostTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [showLinkPopup, setShowLinkPopup] = useState(false); // state for showing the link popup
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const navigate = useNavigate();
  // Handlers for clicking on location and hashtag
  const handleLocationClick = (location) => {
    navigate("/filtersearch", {
      state: { filterSearch: location, filterFlag: "l" },
    });
  };
  const handleRefresh = () => {
    setRefresh(true); // Toggling the refresh state to ensure update
  };
  const handleHashtagClick = (hashtag) => {
    navigate("/filtersearch", {
      state: { filterSearch: hashtag, filterFlag: "h" },
    });
  };

  // ________________________________________________________________________________________________________

  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false); // State to control edit popup visibility

  //************************************************************************************************* */
  const [editPostData, setEditPostData] = useState(null);

  //************************************************************************************************* */

  // useEffect(() => {
  //   if (refresh) {
  //     setRefresh(false);
  //   }
  // }, [refresh]);

  const handleSaveToBucketlist = async ({
    description,
    selectedPostFile = null,
    tags,
    userLocation,
    title = null,
    postType,
  }) => {
    const descriptionText = description[0].text; // Get the text field from the first description object

    console.log("handleSaveToBucketlist called with xxxxx", {
      descriptionText,
      selectedPostFile,
      tags,
      userLocation,
      postType,
      title,
    });
    const formData = new FormData();
    formData.append("user_id", sessionStorage.getItem("user_id"));
    formData.append("title", title);
    formData.append("description", descriptionText);
    formData.append("link", "");
    formData.append("post_type", postType);
    // formData.append("is_public", sessionStorage.getItem("public_id"));
    formData.append("is_public", false);
    formData.append("location", userLocation);
    // Convert tags array to a comma-separated string of hashtags

    tags?.forEach((tag) => {
      formData.append("hash_tags[]", `#${tag}`);
    });

    if (selectedsendPostFile) {
      formData.append("image", selectedsendPostFile);
    }

    console.log("formData", formData);
    try {
      const response = await fetch(`${Config.apiBaseUrl}posts`, {
        method: "POST",
        body: formData,
      });
      // if(postType === "note"){
      //   alert("Note added Successfully");
      // }else{
      {
        toast.success("Post added Successfully");
      }
      // alert("Post added Successfully");
      setRefresh(true);
      // window.location.reload();

      if (response.ok) {
        console.log("Bucketlist item saved successfully");
        setRefresh(true);
      } else {
        console.error("Failed to save Bucketlist item");
        setRefresh(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //To add the current location of the userq

  const reverseGeocodeLocation = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

    try {
      const response = await fetch(url, {
        headers: { "User-Agent": "your-app-name" },
      });
      if (!response.ok) throw new Error("Failed to fetch location name");

      const data = await response.json();
      if (data.address) {
        const locationName = `${data.address.country}`;
        setUserLocation(locationName);
        document.getElementById("location-input-top").value = locationName; // Automatically fill the location input
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          reverseGeocodeLocation(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        () => {
          console.log("Location access denied by the user.");
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const handleFileSelected = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowFileUploadPopup(false); // Close the upload popup after selection
  };

  const userProfile = sessionStorage.getItem("user_profile_logo");
  const username = sessionStorage.getItem("username");
  const location = userLocation || "Location";

  // const toggleHamburgerPopup = () => {
  //   setIsHamburgerPopupVisible((prevState) => !prevState);
  // };

  const linkify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return part;
      }
    });
  };

  const formatDescription = (description) => {
    return description.split("\n").map((item, index) => {
      if (!item) return null;

      return (
        <div key={index} style={{ marginBottom: "10px" }}>
          {linkify(item)}
        </div>
      );
    });
  };

  const fetchPosts = useCallback(async () => {
    try {
      let url = `${Config.apiBaseUrl}bucketlist/posts/${publicId}`;

      const queryString = query
        .map((querys) => encodeURIComponent(querys))
        .join(",");

      if (queryString.trim()) {
        url = `${Config.apiBaseUrl}search/${encodeURIComponent(query)}`;
      }
      console.log(url);
      const { data } = await axios.get(url);
      console.log(
        "fetchPosts###############################################################",
        data
      );

      if (data.data && data.data.length > 0) {
        console.log("setContentItems", data.data);
        setContentItems(data.data);
        setShowWelcomeMessage(false);
      } else {
        console.log("setWelcomeMessage", data.data);
        // If no data returned, set a welcome message
        setWelcomeMessage(data.data);
        setShowWelcomeMessage(true);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setLoading(false);
    }
  }, [publicId, query]);
  // Debounced fetch posts
  const debouncedFetchPosts = useCallback(debounce(fetchPosts, 500), [
    fetchPosts,
  ]);

  useEffect(() => {
    debouncedFetchPosts();
  }, [debouncedFetchPosts]);

  let contentData = [];

  console.log("posts['data'].posts", posts["data"]?.posts);
  console.log("contentItems", contentItems);
  console.log("query", query);

  if (posts?.data && Array.isArray(posts.data)) {
    if (posts.data.length > 0) {
      console.log("Using posts from the 'data.posts' property:", posts.data);
      contentData = posts.data;
    } else {
      console.log("No posts available in 'posts.data.posts', showing empty");
      contentData = [];
    }
  } else if (Array.isArray(contentItems)) {
    console.log("Fallback to 'contentItems':", contentItems);
    contentData = contentItems;
  } else {
    console.error("contentData is not an array");
    contentData = [];
  }

  console.log("Final contentData:", contentData);
  useEffect(() => {
    if (refresh) {
      debouncedFetchPosts();
      setRefresh(false);
    }
  }, [refresh, debouncedFetchPosts]);
  const renderCardContent = (item) => {
    const handleClick = async () => {
      setLoadingComments(true); // Assume you have a setLoadingComments to handle loading states
      console.log("item##################", item);
      try {
        // Fetch comments for the clicked item
        const commentsResponse = await axios.get(
          `${Config.apiBaseUrl}posts/comments/${item.id}`
        );
        const countsResponse = await axios.get(
          `${Config.apiBaseUrl}posts/${item.id}`
        );

        console.log(
          "commentsResponse, countsResponse##############################",
          commentsResponse.data.data,
          countsResponse.data.data
        );
        // Set the popup item with the fetched data
        setPopupItem({
          ...item,
          hash_tags: countsResponse.data.data.hash_tags,
          comments: commentsResponse.data.comments, // Assuming this is the structure of the response
          likes_count: countsResponse.data.data.likes_count,
          comments_count: countsResponse.data.data.comments_count,
          bucket_list_count: countsResponse.data.data.bucket_list_count,
        });

        setIsPopupVisible(true); // Show the popup
      } catch (error) {
        console.error("Failed to fetch post details:", error);
      } finally {
        setLoadingComments(false);
      }
    };
    // Check if the URL is a video based on inclusion of a known video domain
    const isYoutubeVideo =
      item.image_url && item.image_url.includes("youtube.com");
    const isVideo = item.image_url && item.post_type === "video";
    console.log(
      "@@@@@@@@@@@@@@@@@@@@@@@@@ isVideo @@@@@@@@@@@@@@@@@@@@@@@@",
      isVideo,
      "item.post_type",
      item.post_type
    );

    if (isYoutubeVideo) {
      return (
        <ReactPlayer
          url={item.image_url}
          controls
          onClick={handleClick}
          className="card-image"
        />
      );
    } else if (isVideo) {
      return (
        <video
          controls
          src={item.image_url}
          alt="Video content"
          onClick={handleClick}
          className="card-image"
        />
      );
    } else if (item.image_url) {
      return (
        <img
          src={item.image_url}
          alt="Content"
          onClick={handleClick}
          className="card-image"
        />
      );
    } else {
      const textClass = item.post_type === "note" ? "scrollable-text" : "";
      console.log(
        "22222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222222",
        textClass,
        item
      );

      return (
        <div
          onClick={handleClick}
          className="bucklst-text-card {`${textClass}`}"
          style={{
            padding: "10px",
            background: "#272727",
            borderRadius: "15px",

            color: "#FFF",
            cursor: "pointer",
          }}
        >
          <p>{item.title}</p>
          <span className="note_description">
            <span>{item.description}</span>
          </span>
        </div>
      );
    }
  };

  const handleResetSelectedPostFile = () => {
    setSelectedPostFile(null); // Resetting the selected file
  };

  const handleFilePostChange = (event) => {
    const filePost = event.target.files[0];
    setSelectedsendPostFile(filePost);
    setSelectedPostFile(URL.createObjectURL(filePost));
  };

  const handleClickOnNewLink = () => {
    setShowLinkPopup(true); // Set showLinkPopup to true when the new link is clicked
  };

  const handleRemoveTags = (indexToRemove) => {
    setFinalPostTags((prevTags) =>
      prevTags.filter((_, index) => index !== indexToRemove)
    );
  };

  const tagInputChangeHandler = (e) => {
    const value = e.target.value.trim();
    if (e.key === "Enter" && value !== "") {
      setTags([...tags, "#" + value]);
      // setTags([...tags, value]);
      e.target.value = "";
      e.preventDefault();
    }
  };

  const [currentBoardId, setCurrentBoardId] = useState("");
  const MoreOptionRefs = useRef([]);

  // const handleMoreOptionsClick = (event, post_id, ind) => {
  //   event.preventDefault();

  //   // Access the ref of the specific item
  //   const element = MoreOptionRefs.current[ind];

  //   if (element) {
  //     // Manipulate classes or other properties directly through the ref
  //     element.classList.toggle("active");

  //     console.log("handleMoreOptionsClick", post_id);
  //     setCurrentBoardId(currentBoardId === post_id ? null : post_id);
  //   } else {
  //     console.error("Element not found at index: ", ind);
  //   }
  // };

  const [popupPosition, setPopupPosition] = useState("below");
  const handleMoreOptionsClick = (event, post_id, ind) => {
    setIsNewBoardPopupVisible(false);
    event.preventDefault();
    const element = MoreOptionRefs.current[ind];
    const rect = element.getBoundingClientRect();
    const isNearBottom = window.innerHeight - rect.bottom < 300; // Adjust 300px to match the popup height

    if (element) {
      element.classList.toggle("active");
      // Adjust position class based on space availability
      setCurrentBoardId(currentBoardId === post_id ? null : post_id);
      setPopupPosition(isNearBottom ? "above" : "below"); // You might need to manage this state or directly manipulate the class
    } else {
      console.error("Element not found at index: ", ind);
    }
  };

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPopupBoardId, setEditPopupBoardId] = useState(null);
  const [getBoards, setGetBoards] = useState([]);
  const [isNewBoardPopupVisible, setIsNewBoardPopupVisible] = useState(false);
  const [isCreateNewBoardPopupVisible, setIsCreateNewBoardPopupVisible] =
    useState(false);

  // State to control the visibility of EditPopup

  const handleClosePopup = () => {
    setShowPopup(false);
    setShowEditPopup(false);
  };
  const deleteBoard = (boardId) => {
    if (window.confirm("Are you sure you want to delete this board?")) {
      axios
        .delete(`${Config.apiBaseUrl}boards/${boardId}`)
        .then(() => {
          const updatedBoards = getBoards.filter(
            (board) => board.id !== boardId
          );
          setGetBoards(updatedBoards);
          handleClosePopup();
          toast.success("Board deleted successfully");
        })
        .catch((err) => {
          console.error("Error deleting the board:", err);
        });
    }
  };

  const [currentImage, setCurrentImage] = useState(""); // State to hold the current image URL

  // const handleEdit = (boardId) => {
  //   console.log("existingBoards", existingBoards);
  //   const currentBoard = existingBoards.find((board) => board.id === boardId);
  //   if (currentBoard) {
  //     console.log("handleEdit", boardId);
  //     setShowEditPopup(true);
  //     setCurrentBoardId(boardId);
  //     setEditPopupBoardId(boardId);
  //     setCurrentImage(currentBoard.image_url);  // Ensure currentBoard has 'image_url' property
  //   } else {
  //     console.error("No board found with ID:", boardId);
  //     // Handle the scenario where no board is found
  //   }
  // };
  const handleEdit = (postId) => {
    const postToEdit = contentData.find((post) => post.id === postId);
    console.log(
      "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@postToEdit",
      postToEdit
    );
    if (postToEdit) {
      setEditPostData(postToEdit); // Set the data for the post being edited
      setShowEditPopup(true); // Make the edit popup visible
    } else {
      console.error("Post not found!");
    }
  };

  // ££££££££££££££££££££££££££££££££££££££££££££££££££££££££
  const [existingBoards, setExistingBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const toggleCreateNewBoardPopup = () => {
    setIsCreateNewBoardPopupVisible((prev) => !prev);
    // When opening the create new board popup, make sure to close the new board list popup if it's open
    if (isNewBoardPopupVisible) {
      setIsNewBoardPopupVisible(false);
    }
  };

  useEffect(() => {
    if (isNewBoardPopupVisible) {
      const publicId = sessionStorage.getItem("public_id");
      setIsLoading(true);
      axios
        .get(`${Config.apiBaseUrl}bucketlist/boards/${publicId}`)
        .then((response) => {
          console.log("bucketlist/boards", response.data.data.boards);
          setExistingBoards(response.data.data.boards);

          setRefresh(true);
        })
        .catch((error) => {
          console.error("Error fetching existing boards: ", error);
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isNewBoardPopupVisible]);
  const [boardid, setboardid] = useState(null);

  const handleBoard = (postId) => {
    // Set the post ID to the state to be used when the user actually selects or creates a board
    setSelectedPostId(postId);

    // Check if there are existing boards or not
    if (existingBoards.length === 0) {
      // Directly show the popup to create a new board if no boards exist
      setIsNewBoardPopupVisible(true);
    } else {
      // Show the popup to choose an existing board or create a new one
      setIsNewBoardPopupVisible(true);
    }
  };

  const handleBoardClick = (boardId, postId) => {
    setboardid(boardId);
    // Define the postBody for the API call
    const postBody = {
      board_id: boardId,
      post_id: postId,
      user_id: sessionStorage.getItem("user_id"),
    };

    // Make the API call
    axios
      .post(`${Config.apiBaseUrl}boarditems`, postBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Success:", response.data);
        toast.success("Post added to board successfully!");
        // window.location.reload();
        setIsNewBoardPopupVisible(false);
        setCurrentBoardId(false);

        setRefresh(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to add post to board");
      });
  };

  const [createboard, setCreateBoard] = useState({
    board_name: "",
    is_public: false,
    location: "",
    user_id: Number(sessionStorage.getItem("user_id")),
  });

  const changeHandler = (e) => {
    setCreateBoard({ ...createboard, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    debugger;
    console.log("createboard", createboard);
    const formData = new FormData();
    formData.append("user_id", createboard.user_id);
    formData.append("board_name", createboard.board_name);
    formData.append("image", selectedFile);
    formData.append("location", createboard.location);
    formData.append("is_public", false);
    console.log("formData", formData);
    try {
      // debugger;
      const boardResponse = await axios.post(
        `${Config.apiBaseUrl}boards`,
        formData
      );
      const boardId = boardResponse.data.data.board_id;
      // alert("Board created successfully.");

      const postBody = {
        board_id: boardId,
        post_id: selectedPostId,
        user_id: sessionStorage.getItem("user_id"),
      };
      console.log("###################### postBody", postBody);
      try {
        const response = await axios.post(
          `${Config.apiBaseUrl}boarditems`,
          postBody
        );
        console.log("Success:", response.data);
        toast.success("Post added to board successfully!");
        setIsCreateNewBoardPopupVisible(false);
        // window.location.reload();
        setRefresh(true);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to add post to board");
      }
    } catch (error) {
      console.error("Post failed", error);
      toast.error("Post failed");
      setRefresh(true);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("editPopup_fileInput").click();
  };

  const postImageCancel = (e) => {
    e.preventDefault();
    setBoardSelectedImage(null);
    setIsCreateNewBoardPopupVisible(false);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [boardselectedimage, setBoardSelectedImage] = useState(null);
  const [selectedPostId, setSelectedPostId] = useState("");

  // const toggleHamburgerPopup = () => {
  //   setIsHamburgerPopupVisible((prevState) => !prevState);
  //   if (isNewBoardPopupVisible) {
  //     setIsNewBoardPopupVisible(false);
  //   }
  // };

  // Change from a boolean to an object mapping post IDs to visibility booleans
  // const [isHamburgerPopupVisible, setIsHamburgerPopupVisible] = useState({});

  // const toggleHamburgerPopup = (postId) => {
  //   setIsHamburgerPopupVisible(prevState => ({
  //     ...prevState,
  //     [postId]: !prevState[postId]  // Toggle the visibility
  //   }));
  // };

  const closeAllPopups = () => {
    setIsPopupVisible(false);
    // setShowFileUploadPopup(false);
    setShowNewNotePopup(false);
    // setShowFilePostpopup(false);
    setShowLinkPopup(false);
    setShowWelcomeMessage(false);
    setIsHamburgerPopupVisible(false);
    setIsNewBoardPopupVisible(false);
    setIsCreateNewBoardPopupVisible(false);
    setShowEditPopup(false);
    setCurrentBoardId(null); // Close the MoreOptions popup
  };

  const togglePopup = () => {
    closeAllPopups();
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };

  const toggleNotePopup = () => {
    closeAllPopups();
    setShowNewNotePopup(true);
  };

  const toggleNewFile = () => {
    closeAllPopups();
    setShowFilePostpopup(true);
  };

  const handleDeletePost = async (post_id) => {
    // const userId = sessionStorage.getItem('user_id'); // Get the user ID from sessionStorage
    console.log("post_id", post_id);

    try {
      const response = await fetch(`${Config.apiBaseUrl}posts/${post_id}`, {
        method: "DELETE",
        // body: formData, // Set the form data as the request body
      });

      if (!response.ok) throw new Error("Failed to delete the post.");

      setIsHamburgerPopupVisible(false);
      // Handle the response from the server (e.g., refresh the posts list, show a success message)
      toast.success("Post deleted successfully");
      closeAllPopups();
      const updatedContentItems = contentItems.filter(
        (item) => item.id !== post_id
      );
      setContentItems(updatedContentItems);

      // If there are no posts left, show the welcome message
      setShowWelcomeMessage(updatedContentItems.length === 0);
      console.log("Post deleted successfully");

      // window.location.reload();
      setRefresh(true);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const toggleNewBoardPopup = () => {
    setIsNewBoardPopupVisible(false);
  };

  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBoardSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("No image file selected");
    }
  };

  //*************************************************************************************** */

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setIsPopupVisible(false);
        setShowFileUploadPopup(false);
        setShowNewNotePopup(false);
        setShowFilePostpopup(false);
        setShowLinkPopup(false);
        setShowWelcomeMessage(false);
        setIsHamburgerPopupVisible(false);
        setIsNewBoardPopupVisible(false);
        setIsCreateNewBoardPopupVisible(false);
      }
    },
    [
      setIsPopupVisible,
      setShowFileUploadPopup,
      setShowNewNotePopup,
      setShowFilePostpopup,
      setShowLinkPopup,
      setShowWelcomeMessage,
      setIsHamburgerPopupVisible,
      setIsNewBoardPopupVisible,
      setIsCreateNewBoardPopupVisible,
    ]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsHamburgerPopupVisible(false);
      }
    };

    if (isHamburgerPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isHamburgerPopupVisible]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!event.target.closest(".postscontainer")) {
  //       closeAllPopups();
  //       setIsPopupVisible(false);
  //       setShowFileUploadPopup(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  // const [isHamburgerPopupVisible, setIsHamburgerPopupVisible] = useState(false);

  const toggleHamburgerPopup = () => {
    // Optionally handle logic specific to a post ID if necessary
    setShowFileUploadPopup(true);
    closeAllPopups();
  };

  return (
    <div>
      <div className=" addnewpostcard mble-dsp-block">
        <button
          className="
                addpostopen_popup"
          onClick={() => toggleHamburgerPopup()}
        >
          + Add New File
        </button>

        {showEditPopup && (
          <EditPostPopup
            show={showEditPopup}
            post={editPostData}
            onClose={() => closeAllPopups()}
            onSave={(newImage) => {
              // Handle the API call to save the new image
              console.log("New Image:", newImage);
              // Add logic to update the post in your state and possibly re-fetch from the server
              setShowEditPopup(false);
            }}
            onRefreshCallback={handleRefresh}
          />
        )}

        {showFileUploadPopup && (
          <FileUploadPopup
            setShowFileUploadPopup={setShowFileUploadPopup}
            toggleNewFile={toggleNewFile}
            toggleNotePopup={toggleNotePopup}
            togglePopup={togglePopup}
            showLinkPopup={showLinkPopup}
            onClickNewLink={handleClickOnNewLink} // Pass onClickNewLink function
            onFileSelected={handleFileSelected}
          />
        )}

        <LinkPopup
          show={showLinkPopup}
          onClose={() => closeAllPopups()}
          userProfile={userProfile}
          username={username}
          userLocation={location}
        />

        {selectedImageUrl && (
          <div className="image-preview-popup">
            <img
              src={selectedImageUrl}
              alt="Selected"
              style={{ width: "300px", height: "300px" }}
            />
            <button onClick={() => setSelectedImageUrl(null)}>Close</button>
          </div>
        )}
      </div>
      <div className="postscontainer ">
        {(!posts || posts.length === 0) &&
          (!query.length || !contentData.length === 0) && (
            <div className=" addnewpostcard mble-dsp-none">
              <button
                className="
                addpostopen_popup"
                onClick={() => toggleHamburgerPopup()}
              >
                + Add New File
              </button>

              {showEditPopup && (
                <EditPostPopup
                  show={showEditPopup}
                  post={editPostData}
                  onClose={() => closeAllPopups()}
                  onSave={(newImage) => {
                    // Handle the API call to save the new image
                    console.log("New Image:", newImage);
                    // Add logic to update the post in your state and possibly re-fetch from the server
                    setShowEditPopup(false);
                  }}
                  onRefreshCallback={handleRefresh}
                />
              )}

              {showFileUploadPopup && (
                <FileUploadPopup
                  setShowFileUploadPopup={setShowFileUploadPopup}
                  toggleNewFile={toggleNewFile}
                  toggleNotePopup={toggleNotePopup}
                  togglePopup={togglePopup}
                  showLinkPopup={showLinkPopup}
                  onClickNewLink={handleClickOnNewLink} // Pass onClickNewLink function
                  onFileSelected={handleFileSelected}
                />
              )}

              <LinkPopup
                show={showLinkPopup}
                onClose={() => closeAllPopups()}
                userProfile={userProfile}
                username={username}
                userLocation={location}
              />

              {selectedImageUrl && (
                <div className="image-preview-popup">
                  <img
                    src={selectedImageUrl}
                    alt="Selected"
                    style={{ width: "300px", height: "300px" }}
                  />
                  <button onClick={() => setSelectedImageUrl(null)}>
                    Close
                  </button>
                </div>
              )}
            </div>
          )}
        {loading ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
          </div>
        ) : contentItems.length === 0 ? (
          <div className="PostMessahe_card">
            <div className="welcome-postmessage">
              <div>{welcomeMessage.title || "Save your travel plans"}</div>
              <p>
                {" "}
                {welcomeMessage.description ||
                  `This space is where your unorganized files will be stored privately. Once you’ve added files, you can share them with friends with one-to-one file sharing.`}
              </p>
            </div>
          </div>
        ) : (
          contentData.map((item, index) => (
            <div
              key={item.id}
              ref={(el) => (MoreOptionRefs.current[index] = el)}
              className={`postcard   ${popupPosition}`}
            >
              <button
                className="hamburger-btn"
                onClick={(event) =>
                  handleMoreOptionsClick(event, item.id, index)
                }
              >
                {/* ☰ */}
                {currentBoardId === item.id ? <CloseIcon /> : <MenuIcon />}
              </button>
              <div className="card-content ">
                {renderCardContent(item)}
                {currentBoardId === item.id && (
                  <Morepostoption
                    onClose={() => setCurrentBoardId(false)}
                    onEdit={() => handleEdit(item.id)}
                    onAddToBoard={() => handleBoard(item.id)}
                    onDelete={() => handleDeletePost(item.id)}
                    postId={item.id}
                    className=""
                  />
                )}
              </div>
            </div>
          ))
        )}
        {showEditPopup && editPostData && (
          <EditPostPopup
            show={showEditPopup}
            post={editPostData}
            onClose={() => closeAllPopups()}
            onSave={(updatedPost) => {
              console.log("Updated Post:", updatedPost);
              setShowEditPopup(false);
            }}
            onRefreshCallback={handleRefresh}
          />
        )}

        {isNewBoardPopupVisible && (
          <div className="add-board-overlay">
            <div className="add-board-popup">
              <span className="close-popup" onClick={toggleNewBoardPopup}>
                X
              </span>
              <div
                className="add-board-action"
                onClick={() => toggleCreateNewBoardPopup()}
              >
                <img src={addButton} alt="Add" />
                <span>Create New board</span>
              </div>
              <div className="board-list">
                <div className="board-list">
                  {isLoading ? (
                    <div>Loading...</div>
                  ) : error ? (
                    <div>Error: {error.message}</div>
                  ) : existingBoards.length > 0 ? (
                    existingBoards.map((board) => (
                      <div
                        className="board-item"
                        key={board.id}
                        onClick={() =>
                          handleBoardClick(board.id, selectedPostId)
                        }
                      >
                        {board.title}
                      </div>
                    ))
                  ) : (
                    <div>No existing boards found.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {isCreateNewBoardPopupVisible && (
          <div className="editPopup_overlay" onClick={onClose}>
            <div
              className="editPopup_content"
              onClick={(e) => e.stopPropagation()}
            >
              <form onSubmit={submitHandler}>
                <div className="editPopup_modalHeader">
                  <input
                    type="text"
                    className="editPopup_input"
                    placeholder="New board name"
                    value={createboard.board_name}
                    name="board_name"
                    onChange={changeHandler}
                  />
                </div>
                <div className="editPopup_modalBody">
                  <div
                    className="editPopup_imageContainer"
                    onClick={triggerFileInput}
                  >
                    {boardselectedimage ? (
                      <>
                        <img
                          src={boardselectedimage}
                          alt="Selected cover"
                          className="editPopup_selectedImage"
                        />
                        <div
                          className="editPopup_overlayText"
                          style={{ color: "#FFF" }}
                        >
                          Click to change
                        </div>
                      </>
                    ) : (
                      <div
                        className="editPopup_uploadText"
                        style={{ color: "#FFF" }}
                      >
                        Upload cover image
                      </div>
                    )}
                    <input
                      id="editPopup_fileInput"
                      type="file"
                      className="editPopup_hidden"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>

                  <div className="editPopup_buttons">
                    <button
                      className="editPopup_cancelButton"
                      onClick={postImageCancel}
                    >
                      Cancel
                    </button>
                    <button className="editPopup_createButton" type="submit">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}

        <PostFilePopup
          showFilePostPopup={showFilePostpopup}
          setShowFilePostPopup={setShowFilePostpopup}
          userProfile={userProfile}
          username={username}
          selectedPostFile={selectedPostFile}
          userLocation={location}
          handleResetSelectedPostFile={handleResetSelectedPostFile}
          toggleNewFile={toggleNewFile}
          handleFilePostChange={handleFilePostChange}
          finalPostTags={finalPostTags}
          onSaveToBucketlist={handleSaveToBucketlist}
          onRefresh={handleRefresh}
        />

        <NewNotePopup
          show={showNewNotePopup}
          onClose={() => closeAllPopups()}
          setShowFilePostPopup={setShowFilePostpopup}
          userProfile={userProfile}
          setShowNewNotePopup={setShowNewNotePopup}
          username={username}
          userLocation={location}
          onSaveToBucketlist={handleSaveToBucketlist}
          onRefresh={handleRefresh}
        />

        <PopupComponent
          isPopupVisible={isPopupVisible}
          popupItem={popupItem}
          setIsPopupVisible={setIsPopupVisible}
          formatDescription={formatDescription}
          onRefresh={handleRefresh}
        />
      </div>
    </div>
  );
};

export default UserPost;
