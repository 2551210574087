import React, { useState, useEffect, useRef, useCallback } from "react";
import message from "./../../../../Assets/Images/Ellipse 25.png";
import like from "./../../../../Assets/Images/Like Icon.png";
import Location from "./../../../../Assets/Images/locationpointer .png";
import bucketlist from "./../../../../Assets/Images/bucketlist.png";
import TM_Vector19 from "./../../../../Assets/Images/Vector 19.png";
import Config from "./../../../../Assets/Config"; // Assuming this holds your API base URL
import axios from "axios";
import ReactPlayer from "react-player";
import BucketListPopup from "./BucketListPopup";
import { useSearch } from "../../../../Assets/queryprovider";
import { PostLikes } from "../../../../Assets/PostLikes";
import "./PopupComponent.css"; // Assuming you have styles defined in UserPopup.css
import { useNavigate } from "react-router-dom";
// import TM_likeIcon from "./../../../Assets/Images/Like Icon.png";
// import likecon from "./../../../Assets/Images/likecon.png";
import likecon from "./../../../../Assets/Images/likecon.png";
import TM_likeIcon from "./../../../../Assets/Images/Like Icon.png";
import { toast } from "react-toastify";
const PopupComponent = ({
  isPopupVisible,
  popupItem,
  setIsPopupVisible,
  formatDescription,
  onRefresh,
}) => {
  const [loadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [popupCommentInput, setPopupCommentInput] = useState("");
  const [activeComment, setActiveComment] = useState(null); // Track the active comment being replied to
  const commentInputRef = useRef(null); // Ref for focusing the input on reply click
  const [showBucketListPopup, setShowBucketListPopup] = useState(false);
  const [Boards, setExistingBoards] = useState([]);
  const [repliesVisibility, setRepliesVisibility] = useState({});
  const [replyText, setReplyText] = useState({});
  const [activeReply, setActiveReply] = useState(null);

  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState([]);
  // const { query, setQuery } = useSearch(""); // Accessing the query value
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const [postLike, setPostLike] = useState({
    like_type_id: "",
    like_type: "post",
    public_id: sessionStorage.getItem("public_id"), // Use sessionStorage to get the public_id value
  });
  const [likedPostss, setLikedPosts] = useState({});
  const { query, setQuery, removeQuery } = useSearch();
  const username = sessionStorage.getItem("username");
  const fetchPosts = useCallback(async () => {
    try {
      let url = `${Config.apiBaseUrl}posts/popular`;
      const queryString = query
        .map((querys) => encodeURIComponent(querys))
        .join(",");

      if (queryString.trim()) {
        url = `${Config.apiBaseUrl}hashtags/${queryString}`;
      }

      console.log("Fetching posts from:", url);

      const userId = sessionStorage.getItem("user_id");
      const config = {
        method: "get",
        url: url,
      };

      if (url === `${Config.apiBaseUrl}posts/popular`) {
        config.params = { user_id: userId };
      }

      const { data } = await axios(config);
      console.log(
        "##################Fetched data:###########################",
        data
      );

      // Update imageData with the fetched data
      setImageData(data.data || []);

      // Create an object mapping post IDs to their liked status
      const likedPostsObject = data.data.reduce((acc, post) => {
        acc[post.post_id] = post.liked_by_current_user;
        return acc;
      }, {});

      setLikedPosts(likedPostsObject);

      const firstPost = data.data[0];
      const likeTypeId = firstPost ? firstPost.post_id : null;
      console.log("likeTypeId", likeTypeId);
      setPostLike((prevState) => ({ ...prevState, like_type_id: likeTypeId }));
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }, [query]);

  const debouncedFetchPosts = useCallback(debounce(fetchPosts, 500), [
    fetchPosts,
  ]);
  useEffect(() => {
    debouncedFetchPosts();
  }, [debouncedFetchPosts]);

  useEffect(() => {
    if (showBucketListPopup) {
      const publicId = sessionStorage.getItem("public_id");
      axios
        .get(`${Config.apiBaseUrl}boards/user/${publicId}`)
        .then((response) => {
          console.log("bucketlist/boards", response.data.data);
          setExistingBoards(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching existing boards: ", error);
        });
    }
  }, [showBucketListPopup]);

  const handleLocationClick = (location) => {
    navigate("/filtersearch", {
      state: { filterSearch: location, filterFlag: "l" },
    });
  };

  const handleHashtagClick = (hashtag) => {
    navigate("/filtersearch", {
      state: { filterSearch: hashtag, filterFlag: "h" },
    });
  };

  const handleReplyClick = (commentId) => {
    console.log("handleReplyClick commentId", commentId);
    setActiveComment(commentId);
    setActiveReply(commentId);
  };

  const handleCancelReply = () => {
    setActiveReply(null);
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [activeReply]: "",
    }));
    setRepliesVisibility({});
  };
  const closePopup = () => {
    setIsPopupVisible(false);
    // setSelectedPostId(null);
  };
  const updateCommentCount = async (postId, newCommentCount) => {
    try {
      const updatedPosts = imageData.map((post) => {
        if (post.post_id === postId) {
          return { ...post, comments_count: newCommentCount };
        }
        return post;
      });
      setImageData(updatedPosts);
      if (selectedImage && selectedImage.post_id === postId) {
        setSelectedImage({ ...selectedImage, comments_count: newCommentCount });
      }
    } catch (error) {
      console.error("Error updating comment count:", error);
    }
  };
  const handlePopupCommentSubmit = async (event) => {
    if (event && event.preventDefault) event.preventDefault();

    const commentText = activeComment
      ? replyText[activeComment]
      : popupCommentInput;

    if (!commentText.trim()) return;

    const postData = {
      post_id: popupItem.post_id,
      parent_comment_id: activeComment || null,
      public_id: sessionStorage.getItem("public_id"),
      comment_text: commentText,
    };

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}posts/comments`,
        postData
      );
      if (activeComment) {
        setReplyText((prevTexts) => ({
          ...prevTexts,
          [activeComment]: "",
        }));
      } else {
        setPopupCommentInput("");
      }
      setActiveComment(null);
      await fetchCommentsByPostId(popupItem.post_id);
      if (!activeComment) {
        const newCommentsCount =
          response.data.comments_count ||
          (selectedImage?.comments_count || 0) + 1;
        updateCommentCount(popupItem.post_id, newCommentsCount);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const fetchCommentsByPostId = async (postId) => {
    setLoadingComments(true);
    const userId = sessionStorage.getItem("user_id"); // Retrieve the user ID from session storage
    console.log("User ID being sent:", userId);

    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`,
        {
          params: {
            user_id: userId,
          },
        }
      );
      console.log("responseresponseresponseresponseresponse", response);
      setComments(response.data.data);
      setLoadingComments(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setLoadingComments(false);
    }
  };

  const [activePostId, setActivePostId] = useState(null);
  useEffect(() => {
    if (
      isPopupVisible &&
      popupItem &&
      popupItem.post_id !== (activePostId || null)
    ) {
      console.log(
        "popupItempopupItempopupItempopupItempopupItempopupItem",
        popupItem
      );
      fetchCommentsByPostId(popupItem.id);
      setActivePostId(popupItem.id);
      // console.log("useEffectuseEffectuseEffectuseEffectuseEffect",popupItem.id)
    }
  }, [isPopupVisible, popupItem]);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setPopupCommentInput("");
    setActiveComment(null);
    setActivePostId(null);
  };

  useEffect(() => {
    return () => {
      setComments([]);
      setPopupCommentInput("");
      setActiveComment(null);
      setActivePostId(null);
    };
  }, []);

  const handleCommentLike = async (commentId, postId, isLiked) => {
    const updatedComments = comments.map((comment) => {
      if (comment.comment_id === commentId) {
        return {
          ...comment,
          liked_by_current_user: !isLiked,
          likes: isLiked ? comment.likes - 1 : comment.likes + 1,
        };
      }
      return comment;
    });

    setComments(updatedComments);

    const likeData = {
      public_id: sessionStorage.getItem("public_id"),
      like_type_id: commentId,
      like_type: "comment",
    };
    console.log("######################", likeData);
    try {
      await PostLikes(likeData);
      console.log("Like status updated successfully.");
    } catch (error) {
      console.error("Error updating comment like status:", error);
    }
  };

  const handleSendReply = (postId, commentId, e) => {
    console.log("handleSendReply", postId, commentId, e);
    e.preventDefault();
    const replyTextContent = replyText[commentId];

    if (replyTextContent.trim()) {
      setActiveComment(commentId);
      handlePopupCommentSubmit({
        preventDefault: () => {},
      });
    }
  };

  const toggleRepliesVisibility = (commentId) => {
    setRepliesVisibility((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
    setActiveReply(activeReply === null);
  };

  const handleReplyTextChange = (commentId, text) => {
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [commentId]: text,
    }));
  };

  const [isPublic, setIsPublic] = useState(true);

  useEffect(() => {
    setIsPublic(popupItem?.is_public);
  }, [popupItem]);

  const toggleBoardVisibility = () => {
    console.log("called the toggleBoardVisibility");
    const updatedIsPublic = !isPublic;

    setIsPublic(updatedIsPublic);

    const formData = new FormData();
    formData.append("post_id", popupItem.id);
    formData.append("title", popupItem.title);
    formData.append("description", popupItem.description);
    formData.append("link", popupItem.link);
    formData.append("post_type", popupItem.post_type);
    formData.append("location", popupItem.location);
    formData.append("is_public", updatedIsPublic);

    axios
      .put(`${Config.apiBaseUrl}posts`, formData)
      .then(() => {
        // Update local state immediately
        setIsPublic(updatedIsPublic);
        toast.info(
          `This post has been marked as ${
            updatedIsPublic ? "public" : "private"
          }.`
        );
      })
      .catch((error) => {
        // Revert to the original state if there's an error
        setIsPublic(!updatedIsPublic);
        console.error("Error updating board visibility:", error);
        toast.error("Failed to update board visibility.");
      });
  };

  return (
    isPopupVisible && (
      <div className="popupoverlay">
        <div className="popupcontainer" onClick={(e) => e.stopPropagation()}>
          <button
            className="close-popup"
            onClick={() => setIsPopupVisible(false)}
          >
            X
          </button>
          {/* <div className="popupuserinfo">
            <img
              src={popupItem.user.user_profile_logo}
              alt="User"
              className="popup-user-logo"
            />
            <span className="popup-username">{popupItem.user.username}</span>
          </div> */}
          {/* {popupItem.image_url && (
            <div className="content-container">
              <img
                src={popupItem.image_url}
                alt="Content"
                className="popup-image"
                style={{}}
              /> */}
          {popupItem.image_url && (
            <div className="content-container">
              {popupItem.image_url.includes("youtube.com") ? (
                <ReactPlayer
                  url={popupItem.image_url}
                  controls
                  className="popup-image"
                />
              ) : (
                <>
                  {popupItem.post_type === "video" ? (
                    <video
                      src={popupItem.image_url}
                      alt="Content"
                      className="popup-image"
                      controls
                      style={{}}
                    />
                  ) : (
                    <img
                      src={popupItem.image_url}
                      alt="Content"
                      className="popup-image-2"
                      style={{}}
                    />
                  )}
                </>
              )}
              {popupItem.location ? (
                <div
                  className="locationtext"
                  onClick={() => handleLocationClick(popupItem.location)}
                >
                  <img src={Location} alt="Location" />
                  {popupItem.location}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          {popupItem.video_url && (
            <iframe
              src={popupItem.video_url}
              title="Video Content"
              className="popup-video"
              style={{ width: "600px", height: "500px" }}
              allowFullScreen
            ></iframe>
          )}
          {!popupItem.image_url && !popupItem.video_url && (
            <div
              className="popuptext popuptext-2"
              style={{ backgroundColor: "#272727" }}
            >
              <div className="popuptitle">{popupItem.title}</div>
              {formatDescription(popupItem.description)}
            </div>
          )}
          <div className="action-icons iconcontainer">
            <img src={like} alt="Like" className="action-icon" />
            <span style={{ color: "#FFF" }}> {popupItem.likes_count}</span>
            <img src={message} alt="Message" className="action-icon" />
            <span style={{ color: "#FFF" }}> {comments.length}</span>

            {showBucketListPopup && (
              <BucketListPopup
                boards={Boards}
                postId={popupItem.id}
                flag="bucket"
                onRefresh={onRefresh}
                onClose={closePopup}
                // onSuccess={handleBucketListSuccess}
              />
            )}
            <img
              src={bucketlist}
              alt="Bucketlist"
              className="action-icon"
              onClick={() => setShowBucketListPopup(!showBucketListPopup)}
            />
            <span style={{ color: "#FFF" }}>{popupItem.bucket_list_count}</span>
          </div>
        </div>
        <div className="vertical-line"></div>
        <div>
          <div className="popup-text-overlay">
            <div className="top-section">
              <div className="profilename">
                <img
                  src={popupItem.user.user_profile_logo}
                  alt="Profile"
                  className="profile-logo"
                />
                <span>{popupItem.user.username}</span>
              </div>
              <div className="disc popup_disc">
                {/* <span>
                  {popupItem.post_type === "post" && (
                    <span>{popupItem.description}</span>
                  )}
                </span> */}
                <span>
                  {popupItem.post_type === "post" && (
                    <span>
                      {/(www\.|https?:\/\/)/.test(popupItem.description) ? (
                        <a href={popupItem.description}>
                          {popupItem.description}
                        </a>
                      ) : (
                        <span>{popupItem.description}</span>
                      )}
                    </span>
                  )}
                </span>
                <span
                  className="popup_hash_tags"
                  onClick={() =>
                    handleHashtagClick(popupItem.hash_tags.join(" "))
                  }
                >
                  {popupItem.hash_tags.join(" ")}
                </span>
              </div>
              <div className="horizonta-line"></div>
              {/* <div className="commentbox">
                {loadingComments ? (
                  <p>Loading comments...</p>
                ) : comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <div key={index} className="comment">
                      <div className="profilename">
                        {comment.user_profile_logo ? (
                          <img
                            src={comment.user_profile_logo}
                            alt="Profile"
                            className="profilelogo"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "";
                              e.target.classList.add("whiteCircle");
                            }}
                          />
                        ) : (
                          <div className="whiteCircle"></div>
                        )}
                        <span>{comment.user_name || "UserName"}</span>
                        <div className="time">{comment.posted_time}</div>
                      </div>
                      <p>{comment.comment_text}</p>
                      <div className="comments_like">
                      {(comment.user_id !== parseInt(sessionStorage.getItem("user_id")) ||
                          comment.reply_count > 0) && (
                          <button onClick={() => handleReplyClick(comment.comment_id)}>
                            Reply
                          </button>
                        )}
                        <button
                          className="btn p-0"
                          onClick={() =>
                            handleCommentLike(
                              comment.comment_id,
                              popupItem.id,
                              comment.liked_by_current_user
                            )
                          }
                        >
                          <img
                            src={
                              comment.liked_by_current_user
                                ? likecon
                                : TM_likeIcon
                            }
                            style={{ width: "15px" }}
                            alt="Like"
                          />
                          <span className="comment_likes ms-1">
                            {comment.likes}
                          </span>
                        </button>
                        {comment.reply_count > 0 && (
                          <button
                            className="btn btn-link"
                            style={{
                              textDecoration: "none",
                              color: "#fff",
                              fontFamily: "Inter",
                              fontSize: "12px",
                            }}
                            onClick={() =>
                              toggleRepliesVisibility(comment.comment_id)
                            }
                          >
                            ({comment.reply_count}) Replies
                          </button>
                        )}
                      </div>
                      {repliesVisibility[comment.comment_id] &&
                        comment.replies && (
                          <div className="replies">
                            {comment.replies.map((reply) => (
                              <div
                                key={reply.comment_id}
                                className="reply-row align-items-center mb-2"
                              >
                                <div className="col-md-2 reply-user-profile d-flex">
                                  <img
                                    className="commemts_profile_logo"
                                    src={reply.user_profile_logo}
                                    alt="Profile"
                                  />
                                  <div className="reply-username">
                                    {reply.user_name}
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="reply-text">
                                    {reply.comment_text}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {activeReply === comment.comment_id && (
                        <div className="reply-input-container">
                          <input
                            type="text"
                            className="reply-input"
                            value={replyText[comment.comment_id] || ""}
                            onChange={(e) =>
                              handleReplyTextChange(
                                comment.comment_id,
                                e.target.value
                              )
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                handleSendReply(popupItem.id, comment.comment_id, e);
                              }
                            }}
                            placeholder="Write a reply..."
                          />
                          <div className="reply-buttons">
                            <button
                              className="reply-button send"
                              onClick={(e) =>
                                handleSendReply(
                                  popupItem.id,
                                  comment.comment_id,
                                  e
                                )
                              }
                            >
                              Send
                            </button>
                            <button
                              className="reply-button cancel"
                              onClick={handleCancelReply}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            </div> */}
              <div className="commentbox">
                {loadingComments ? (
                  <p>Loading comments...</p>
                ) : comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <div key={index} className="comment">
                      <div className="profilename">
                        {comment.user_profile_logo ? (
                          <img
                            src={comment.user_profile_logo}
                            alt="Profile"
                            className="profilelogo"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "";
                              e.target.classList.add("whiteCircle");
                            }}
                          />
                        ) : (
                          <div className="whiteCircle"></div>
                        )}
                        <span>{comment.user_name || "UserName"}</span>
                        <div className="time">{comment.posted_time}</div>
                      </div>
                      <p>{comment.comment_text}</p>
                      <div className="comments_like">
                        {(comment.user_id !==
                          parseInt(sessionStorage.getItem("user_id")) ||
                          comment.reply_count > 0) && (
                          <span
                            onClick={() => handleReplyClick(comment.comment_id)}
                          >
                            Reply
                          </span>
                        )}
                        <button
                          className="btn p-0"
                          onClick={() =>
                            handleCommentLike(
                              comment.comment_id,
                              popupItem.id,
                              comment.liked_by_current_user
                            )
                          }
                        >
                          <img
                            src={
                              comment.liked_by_current_user
                                ? likecon
                                : TM_likeIcon
                            }
                            style={{ width: "15px" }}
                            alt="Like"
                          />
                          <span className="comment_likes ms-1">
                            {comment.likes}
                          </span>
                        </button>
                        {comment.reply_count > 0 && (
                          <button
                            className="btn btn-link"
                            style={{
                              textDecoration: "none",
                              color: "#fff",
                              fontFamily: "Inter",
                              fontSize: "12px",
                            }}
                            onClick={() =>
                              toggleRepliesVisibility(comment.comment_id)
                            }
                          >
                            ({comment.reply_count}) Replies
                          </button>
                        )}
                      </div>
                      {repliesVisibility[comment.comment_id] &&
                        comment.replies && (
                          <div className="replies">
                            {comment.replies.map((reply) => (
                              <div
                                key={reply.comment_id}
                                className="reply-row align-items-center mb-2"
                              >
                                <div className="col-md-2 reply-user-profile d-flex">
                                  <img
                                    className="commemts_profile_logo"
                                    src={reply.user_profile_logo}
                                    alt="Profile"
                                  />
                                  <div className="reply-username">
                                    {reply.user_name}
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="reply-text">
                                    {reply.comment_text}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {activeReply === comment.comment_id && (
                        <div className="reply-input-container">
                          <input
                            type="text"
                            className="reply-input"
                            value={replyText[comment.comment_id] || ""}
                            onChange={(e) =>
                              handleReplyTextChange(
                                comment.comment_id,
                                e.target.value
                              )
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                handleSendReply(
                                  popupItem.id,
                                  comment.comment_id,
                                  e
                                );
                              }
                            }}
                            placeholder="Write a reply..."
                          />
                          <div className="reply-buttons">
                            <button
                              className="reply-button send"
                              onClick={(e) =>
                                handleSendReply(
                                  popupItem.id,
                                  comment.comment_id,
                                  e
                                )
                              }
                            >
                              Send
                            </button>
                            <button
                              className="reply-button cancel"
                              onClick={handleCancelReply}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            </div>

            <div className="input-section">
              <div className="horizonta-line"></div>
              <form onSubmit={handlePopupCommentSubmit}>
                <input
                  type="text"
                  placeholder="Add a comment"
                  value={popupCommentInput}
                  onChange={(e) => setPopupCommentInput(e.target.value)}
                  className="commentinput"
                  ref={commentInputRef}
                />
                <button type="submit" className="comment-submit m-3">
                  <img src={TM_Vector19} alt="Submit" />
                </button>
              </form>
            </div>
          </div>
        </div>

        <button
          onClick={toggleBoardVisibility}
          className="toggle-visibility-button"
        >
          {isPublic ? "Make Post Private" : "Make Post Public"}
        </button>
      </div>
    )
  );
};

export default PopupComponent;
