import React, { useRef, useState, useEffect } from "react";
import "./Boardeditpostpopup.css";
import message from "./../../../../Assets/Images/Ellipse 25.png";
import like from "./../../../../Assets/Images/Like Icon.png";
import Location from "./../../../../Assets/Images/locationpointer .png";
import bucketlist from "./../../../../Assets/Images/bucketlist.png";
import TM_Vector19 from "./../../../../Assets/Images/Vector 19.png";
import Config from "../../../../Assets/Config"; // Assuming this holds your API base URL
import axios from "axios";
import LocationSuggestions from "../../../../Location/LocationSuggestions";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";

function Boardeditpostpopup({ show, post, onClose, onSave }) {
  // Expanded editContent state to include possible description editing
  const [editContent, setEditContent] = useState({
    type: "",
    value: "",
    description: "",
    location: post.location || "", // Assuming location is a property of your post
    title: post.title || "",
  });
  const fileInputRef = useRef(null);
  const [loadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [popupCommentInput, setPopupCommentInput] = useState("");
  const [activeComment, setActiveComment] = useState(null);
  const [hashtags, setHashtags] = useState(editContent.hash_tags || []);

  const commentInputRef = useRef(null); // Ref for focusing the input on reply click

  const handleReplyClick = (commentId) => {
    setActiveComment(commentId);
    if (commentInputRef.current) {
      commentInputRef.current.focus();
    }
  };

  useEffect(() => {
    console.log("############################## Post data:", post);
  }, [post]); // The post data will be logged every time the 'post' object changes

  console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@editContent", editContent);
  const fetchCommentsByPostId = async (postId) => {
    setLoadingComments(true);
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`
      );
      console.log("setComments", response.data.data);
      setComments(response.data.data);
      setLoadingComments(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setLoadingComments(false);
    }
  };

  const handlePopupCommentSubmit = async (e) => {
    e.preventDefault();
    const commentText = popupCommentInput;
    if (!commentText.trim()) return;

    const postData = {
      post_id: editContent.id,
      parent_comment_id: activeComment || null,
      public_id: sessionStorage.getItem("public_id"),
      comment_text: commentText,
    };

    try {
      await axios.post(`${Config.apiBaseUrl}posts/comments`, postData);
      setPopupCommentInput("");
      await fetchCommentsByPostId(editContent.id);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  useEffect(() => {
    if (show) {
      // Set initial values for editContent based on post's current properties
      // Check if post_type is 'post' (for image) or 'note'
      const contentType = post.post_type === "note" ? "note" : "post";
      setHashtags(post.hash_tags || []); // Assuming `hash_tags` is the correct field

      // Use image_url if it's an image post, or description if it's a note post
      const contentValue =
        post.post_type === "note" ? post.description : post.url;

      const initialEditContent = {
        type: contentType,
        value: contentValue,
        description: post.description || "",
        location: post.location || "",
        id: post.post_id,
        title: post.title || "",

        user_logo: post.user_logo,
        username: post.user_name,
        likes_count: post.likes_count,
        bucket_list_count: post.bucket_list_count,
      };
      setEditContent(initialEditContent);

      // Fetch comments if needed
      fetchCommentsByPostId(post.id);
    } else {
      // Reset editContent when the popup is not shown
      setEditContent({
        type: "",
        value: "",
        description: "",
        location: "",
        id: null,
      });
    }
  }, [show, post]);

  const handleSave = async () => {
    // The endpoint should be the one your server expects for PUT requests
    const url = `${Config.apiBaseUrl}posts`;
    const formData = new FormData();

    // Append the post attributes to the formData object
    formData.append("post_id", editContent.id);
    formData.append("title", editContent.title || ""); // Assuming you have a title in your state
    formData.append("description", editContent.description);
    formData.append("link", editContent.link || ""); // Include link if you have one
    formData.append("post_type", editContent.type);
    formData.append("is_public", String(editContent.isPublic)); // Assuming you store a boolean isPublic in your state
    formData.append("location", editContent.location);

    // Handle hashtags, assuming you have them as an array
    if (editContent.hash_tags) {
      editContent.hash_tags.forEach((tag) => {
        formData.append("hash_tags[]", tag);
      });
    }

    // If you're uploading an image, append the image file to formData
    // Check if there's a file and if editContent.type is 'image'
    if (editContent.file && editContent.type === "post") {
      formData.append("image", editContent.file);
    }

    // Perform the PUT request with axios
    try {
      const response = await axios({
        method: "put",
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data", // This is important for files
        },
      });

      // Handle the response
      console.log("Post updated:", response.data);
      toast.success("Post updated successfully");
      onSave(response.data); // Update the parent state if necessary
      window.location.reload();
      onClose(); // Close the popup
    } catch (error) {
      toast.error("Error updating post:", error);
      console.error("Error updating post:", error);
    }
  };

  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");

  const handleLocationChange = (e) => {
    setEditContent({ ...editContent, location: e.target.value });
    setLocationInput(e.target.value);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setEditContent({ ...editContent, location: location });
  };

  const handleNoteChange = (e) => {
    setEditContent({ ...editContent, value: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setEditContent((prevState) => ({
      ...prevState,
      description: e.target.value,
      post_type: prevState.post_type === "post" ? "post" : "note", // Update post_type based on the previous value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png")
    ) {
      const imageUrl = URL.createObjectURL(file);
      setEditContent({ ...editContent, type: "post", value: imageUrl, file });
    } else {
      toast.info("Please select a valid image file (JPEG, JPG, PNG).");
    }
  };

  const handleClose = () => {
    setEditContent({ type: "", value: "", description: "" });
    onClose();
  };

  if (!show) return null;

  const handleHashtagChange = (e) => {
    if (e.key === "Enter") {
      let input = e.target.value.trim().replace(/\s+/g, " ");
      let tagList = input.split(" ");
      tagList = tagList.map((tag) => {
        if (!tag.startsWith("#")) {
          return `#${tag}`;
        }
        return `#${tag.slice(tag.lastIndexOf("#") + 1)}`;
      });
      tagList = tagList.filter((tag) => !hashtags.includes(tag));
      setHashtags((prevHashtags) => [...prevHashtags, ...tagList]);
      setEditContent((prevContent) => ({
        ...prevContent,
        hash_tags: [...(prevContent.hash_tags || []), ...tagList],
      }));
      e.target.value = "";
    }
  };

  const removeHashtag = (tag) => {
    setHashtags(hashtags.filter((t) => t !== tag));
  };

  return (
    <div className="popupoverlay d-flex">
      <div className="edit-popup-container">
        <button className="close-popup" onClick={handleClose}>
          X
        </button>

        {editContent.type === "post" ? (
          <div className="edit-post-popup-image-container">
            {editContent.value.includes("youtube.com") ? (
              <ReactPlayer
                url={editContent.value}
                controls
                className="edit-post-popup-image youtube_popup"
              />
            ) : (
              <>
                {editContent.value.endsWith(".mp4") ? (
                  <video
                    src={editContent.value}
                    alt="Edit"
                    className="edit-post-popup-image youtube_popup"
                    controls
                  />
                ) : (
                  <img
                    src={editContent.value}
                    alt="Edit"
                    className="edit-post-popup-image youtube_popup"
                  />
                )}
              </>
            )}

            <span
              className="edit-post-popup-select-image"
              onClick={() => fileInputRef.current.click()}
            >
              Change Post
            </span>
            <input
              type="file"
              onChange={handleFileChange}
              accept="image/jpeg, image/jpg, image/png"
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div className="location-text">
              <input
                type="text"
                value={editContent.location}
                onChange={handleLocationChange}
                placeholder="Edit location"
                className="edit-location-input"
              />
              <span className="pointer-image"></span>
              <div className="location_suggestions">
                <LocationSuggestions
                  location={locationInput}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  onSuggestionClick={handleLocationSelect}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="edit-note-container">
            <textarea
              className="edit-note-description-input"
              value={editContent.description}
              onChange={handleDescriptionChange}
              placeholder="Edit title and description"
            />
          </div>
        )}
      </div>
      <div className="vertical-line"></div>
      <div style={{ width: "90%" }}>
        <div className="top-section">
          <div className="profilename">
            <img
              src={editContent.user_logo}
              alt="Profile"
              className="profile-logo"
            />
            <span>{editContent.username || "UserName"}</span>
          </div>
          <div className="horizontal-line"></div>

          {editContent.type === "post" && (
            <div className="disc">
              <textarea
                type="text"
                value={editContent.description}
                onChange={handleDescriptionChange}
                placeholder="Edit description"
                className="edit-description-input"
              />
              <input
                type="text"
                onKeyDown={handleHashtagChange}
                placeholder="Add #tag"
                className="ep-hashtag-input"
              />
              <div className="edit-post-hashtag">
                <div className="ep-hashtags-container">
                  {hashtags.map((tag, index) => (
                    <div key={index} className="ep-hashtag">
                      {tag}
                      <button
                        onClick={() => removeHashtag(tag)}
                        className="ep-hashtag-remove-btn"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="input-section">
          <button
            onClick={handleSave}
            className="edit-post-popup-save-button-2"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default Boardeditpostpopup;
