import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BoardCreationModal = ({
  createboard,
  changeHandler,
  boardselectedimage,
  handleFileChange,
  triggerFileInput,
  postImageCost,
  submitHandler,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const enhancedSubmitHandler = (e) => {
    e.preventDefault();

    // Ensure that both an image and a board name have been provided
    if (!boardselectedimage || createboard.board_name.trim() === "") {
      toast.error("Both an image and a board name are required.");
      return;
    }

    if (isSubmitting) {
      return; // Prevent multiple submissions
    }

    setIsSubmitting(true);
    submitHandler(e).finally(() => setIsSubmitting(false));
  };

  const enhancedHandleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 0) {
      const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

      if (!allowedImageTypes.includes(file.type)) {
        toast.error("Only JPEG, JPG, and PNG files are accepted.");
        return;
      }

      handleFileChange(e); // Call the original handleFileChange function if the file type is allowed
    }
  };

  return (
    <div
      id="staticBackdrop1"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      className="modal fade"
      style={{ marginTop: "2%" }}
    >
      <div className="modal-dialog modal-dialog2">
        <div className="modal-content board_containtpopup">
          <div className="modal-header">
            <input
              type="text"
              className="new_board_Name"
              placeholder="New board name"
              value={createboard.board_name}
              name="board_name"
              onChange={changeHandler}
            />
          </div>
          <div className="modal-body">
            <div className="board_postcontaint board_postcontaint2">
              <div className="board_uploadimg board_uploadimg2">
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  accept="image/*,video/*"
                  onChange={enhancedHandleFileChange}
                />
                <label
                  onClick={triggerFileInput}
                  className="board_uploadcoverimg"
                >
                  Upload cover image
                </label>
                {boardselectedimage && (
                  <img
                    src={boardselectedimage}
                    alt="Selected Image"
                    className="boardselectedimage"
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div>
                  <button
                    onClick={postImageCost}
                    className="cancelbutton"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <button
                    type="submit"
                    className="createbutton ms-2"
                    onClick={enhancedSubmitHandler}
                    disabled={isSubmitting}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardCreationModal;
