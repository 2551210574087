import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../../../../Assets/Config";
import backbutton from "../../../../Assets/Images/Backbutton.png";
import Moreoption from "./../../../../Assets/Images/More_options.png";
// import editoption from "./../../../../Assets/Images/editoption.png";
import addButton from "./../../../../Assets/Images/addButton.png";
import DeleteSymbol from "./../../../../Assets/Images/Delete Symbol.png";
import Closeicon from "./../../../../Assets/Images/close.png"; // Make sure you have a close icon in your assets
import BoardFileUploadPopup from "./BoardFileUploadPopup/BoardFileUploadPopup";
import { useSearch } from "./../../../../Assets/queryprovider";
import NewNotePopup from "../../MyFiles/Posts/NewNotePopup";
import PostFilePopup from "../../MyFiles/Posts/PostFilePopup";
import FileUploadPopup from "../../MyFiles/Posts/FileUploadPopup";
import LinkPopup from "../../MyFiles/Posts/LinkPopup";
import Privatesymbol from "./../../../../Assets/Images/Privatesymbol.png";
import PublicSymbol from "./../../../../Assets/Images/PublicSymbol.png";
import useGlobalKeyListeners from "./../../../../useGlobalKeyListeners";
import "./BoardDetails.css";
import PostPopupofBoard from "./PostPopupofBoard/PostPopupofBoard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlayer from "react-player";

import {
  faEdit,
  faTrashAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Boardeditpostpopup from "./Boardeditpostpopup";
import { toast } from "react-toastify";
const BoardDetails = () => {
  const { board_id } = useParams();
  const navigate = useNavigate();
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [images, setImages] = useState([]);
  const [currentPopupIndex, setCurrentPopupIndex] = useState(-1); // -1 means no popup is shown
  const { boardPostsName } = useSearch();
  const [showLinkPopup, setShowLinkPopup] = useState(false); // state for showing the link popup
  const [showNewNotePopup, setShowNewNotePopup] = useState(false); // For new note popup
  const [showFilePostpopup, setShowFilePostpopup] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [userLocation, setUserLocation] = useState("");

  const [isPublic, setIsPublic] = useState(true);

  // const togglePrivacy = () => {
  //   setIsPublic(!isPublic);
  //   // Call the function to update the backend
  //   toggleBoardVisibility(board_id, isPublic);
  // };

  const fetchBoardDetails = async () => {
    console.log("board_id####################################", board_id);
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/board/${board_id}`
      );
      console.log(
        "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ board details@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",
        response.data.data,
        "esponse",
        response
      );

      if (response.data.data && response.data.data.length > 0) {
        setImages(
          response.data.data.map((item) => ({
            post_id: item.post_id,
            title: item.title,

            is_public: item.is_public,

            post_type: item.post_type,
            comment_count: item.comment_count,
            bucket_list_count: item.bucket_list_count,
            location: item.location,
            likes_count: item.likes_count,
            url: item.image_url,
            description: item.description,
            user_logo: item.user.user_profile_logo,
            user_name: item.user.username,
            hash_tags: item.hash_tags,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to fetch board details:", error);
    }
  };

  // Fetch board details initially and on board_id change
  useEffect(() => {
    fetchBoardDetails();
  }, [board_id]);

  // Effect for handling refresh

  const toggleNotePopup = () => {
    setShowFileUploadPopup(false);
    setShowNewNotePopup(true);
  };

  const toggleNewFile = () => {
    setShowFileUploadPopup(false);
    setShowFilePostpopup(true);
  };

  const onClickNewLink = () => {
    setShowLinkPopup(true);
    setShowFileUploadPopup(false);
  };

  const toggleBoardVisibility = () => {
    const updatedIsPublic = !isPublic;
    console.log("################################## isPublic", isPublic);
    // Prepare the data to be sent to the server
    const formData = new FormData();
    formData.append("board_id", board_id);
    formData.append("is_public", updatedIsPublic);

    // Make the API call
    axios
      .put(`${Config.apiBaseUrl}boards`, formData)
      .then((response) => {
        setIsPublic(updatedIsPublic); // Update local state to reflect the change
        console.log(
          "################################## updatedIsPublice",
          isPublic
        );
        // alert(
        //   `Board visibility updated to ${
        //     updatedIsPublic ? "Public" : "Private"
        //   }.`
        // );

        // Inform the user of the success
        console.log(
          `Board has been made ${updatedIsPublic ? "public" : "private"}.`
        );
        toast.info(`Board has been made ${updatedIsPublic ? "public" : "private"}.`);
      })
      .catch((error) => {
        // If there's an error, you might choose to revert the isPublic state
        setIsPublic(isPublic); // Revert the UI change

        // Log and alert the error
        console.error("Error updating board visibility:", error);
        toast.error("Failed to update board visibility.");
      });
  };

  // useEffect(() => {
  //   const fetchBoardDetails = async () => {
  //     console.log("board_id####################################", board_id);
  //     try {
  //       const response = await axios.get(
  //         `${Config.apiBaseUrl}boards/board/${board_id}`
  //       );
  //       console.log(
  //         "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ board details@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",
  //         response.data.data,
  //         "esponse",
  //         response
  //       );
  //       if (response.data.data && response.data.data.length > 0) {
  //         setImages(
  //           response.data.data.map((item) => ({
  //             post_id: item.post_id,
  //             post_type:item.post_type,
  //             comment_count: item.comment_count,
  //             bucket_list_count: item.bucket_list_count,
  //             location: item.location,
  //             likes_count: item.likes_count,
  //             url: item.image_url,
  //             description: item.description,
  //             user_logo: item.user.user_profile_logo,
  //             user_name: item.user.username,
  //             description: item.description,
  //             hash_tags: item.hash_tags,
  //           }))
  //         );
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch board details:", error);
  //     }
  //     console.log("images", images);
  //   };
  //   fetchBoardDetails();
  // }, [board_id]);

  const handleAddNewFileClick = () => {
    setShowFileUploadPopup(true);
    setSelectedBoardId(board_id); // Set the board_id when opening the file upload popup
  };

  const reverseGeocodeLocation = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;

    try {
      const response = await fetch(url, {
        headers: { "User-Agent": "your-app-name" },
      });
      if (!response.ok) throw new Error("Failed to fetch location name");

      const data = await response.json();
      if (data.address) {
        const locationName = `${data.address.country}`;
        setUserLocation(locationName);
        document.getElementById("location-input-top").value = locationName; // Automatically fill the location input
      }
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          reverseGeocodeLocation(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        () => {
          console.log("Location access denied by the user.");
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const tagInputChangeHandler = (e) => {
    const value = e.target.value.trim();
    if (e.key === "Enter" && value !== "") {
      setTags([...tags, "#" + value]);
      e.target.value = "";
      e.preventDefault();
    }
  };

  const [tags, setTags] = useState([]);
  const location = userLocation || "Location";
  const handleSaveToBucketlist = async ({
    description,
    selectedPostFile = null,
    tags,
    userLocation = "",
    title = "",
    postType,
  }) => {
    console.log("handleSaveToBucketlist called with xxxxx", {
      description,
      selectedPostFile,
      tags,
      userLocation,
      postType,
      title,
    });

    const descriptionText = description[0].text; // Get the text field from the first description object

    const formData = new FormData();
    formData.append("user_id", Number(sessionStorage.getItem("user_id")));
    formData.append("title", title);
    formData.append("description", descriptionText);
    formData.append("link", "");
    formData.append("post_type", postType);
    formData.append("is_public", "false");
    formData.append("location", userLocation);
    formData.append("board_id", selectedBoardId); // Include the selected board ID

    // formData.append("board_id", seletcedboard_id);
    // Convert tags array to a comma-separated string of hashtags

    // tags.forEach((tag) => {
    //   formData.append("hash_tags[]", `#${tag}`);
    // });

    const safeTags = Array.isArray(tags) ? tags : [];
    safeTags.forEach((tag) => {
      formData.append("hash_tags[]", `#${tag}`);
    });

    if (selectedsendPostFile) {
      formData.append("image", selectedsendPostFile);
    }
    console.log("formData", formData, "seletcedboard_id", selectedBoardId);
    try {
      const response = await fetch(`${Config.apiBaseUrl}posts`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        console.log("Bucketlist item saved successfully");
        toast.success("Bucketlist item saved successfully");
        // Instead of setRefresh(true);
        handleRefresh();
        // window.location.reload();
      } else {
        console.error("Failed to save Bucketlist item");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [selectedPostFile, setSelectedPostFile] = useState(null);
  const [finalPostTags, setFinalPostTags] = useState([]);

  const [selectedsendPostFile, setSelectedsendPostFile] = useState(null);

  const userProfile = sessionStorage.getItem("user_profile_logo");
  const username = sessionStorage.getItem("username");
  // const location = userLocation || "Location";
  const handleResetSelectedPostFile = () => {
    setSelectedPostFile(null); // Resetting the selected file
  };

  const handleFilePostChange = (event) => {
    const filePost = event.target.files[0];
    setSelectedsendPostFile(filePost);
    setSelectedPostFile(URL.createObjectURL(filePost));
  };

  const handleClickOnNewLink = () => {
    setShowLinkPopup(true); // Set showLinkPopup to true when the new link is clicked
  };
  const handleFileSelected = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setShowFileUploadPopup(false); // Close the upload popup after selection
  };
  const togglePopup = (index, post_id) => {
    console.log("############################## setSelectedPostId", post_id);
    if (currentPopupIndex === index) {
      // If the same popup is clicked again, hide it
      setCurrentPopupIndex(-1);
      setSelectedPostId("");
    } else {
      // Show the clicked popup and hide others
      setCurrentPopupIndex(index);
      setSelectedPostId(post_id);
    }
  };

  const [showPostPopup, setShowPostPopup] = useState(false);
  const [selectedPostItem, setSelectedPostItem] = useState(null);

  const formatDescription = (description) => {
    // Your logic to format the description
    return description;
  };

  const [selectedImageUrl, setSelectedImageUrl] = useState(null); // Holds the URL of the clicked image
  const handleImageClick = (image) => {
    setSelectedPostItem(image);
    // setboardid(board_id);
    setShowPostPopup(true);
    // setLocation(image.location);
  };

  // ############################################################################3

  const [isHamburgerPopupVisible, setIsHamburgerPopupVisible] = useState(false);
  const [isNewBoardPopupVisible, setIsNewBoardPopupVisible] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [isCreateNewBoardPopupVisible, setIsCreateNewBoardPopupVisible] =
    useState(false);
  const [existingBoards, setExistingBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [boardselectedimage, setBoardSelectedImage] = useState(null);
  const [selectedPostId, setSelectedPostId] = useState("");
  const [contentItems, setContentItems] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const toggleHamburgerPopup = () => {
    setIsHamburgerPopupVisible((prevState) => !prevState);
    if (isNewBoardPopupVisible) {
      setIsNewBoardPopupVisible(false);
    }
  };
  const toggleNewBoardPopup = () => {
    setIsNewBoardPopupVisible((prev) => !prev);
    if (isHamburgerPopupVisible) {
      setIsHamburgerPopupVisible(false);
    }
  };

  const toggleCreateNewBoardPopup = () => {
    setIsCreateNewBoardPopupVisible((prev) => !prev);
    // When opening the create new board popup, make sure to close the new board list popup if it's open
    if (isNewBoardPopupVisible) {
      setIsNewBoardPopupVisible(false);
    }
  };

  useEffect(() => {
    if (refresh) {
      console.log("refreshing the fetchBoardDetails");
      fetchBoardDetails();
      setRefresh(false); // Reset refresh state to false after actions are complete
    }
  }, [refresh]); // Dependency array includes refresh

  useEffect(() => {
    // Function to fetch existing boards
    const fetchExistingBoards = async () => {
      try {
        const publicId = sessionStorage.getItem("public_id"); // Replace with correct method to obtain id
        const response = await axios.get(
          `${Config.apiBaseUrl}bucketlist/boards/${publicId}`
        );
        console.log(
          "&&&&&&&&&&&&&&&&&&&&&&&&&&response.data.data.boards",
          response.data.data.boards
        );
        setExistingBoards(response.data.data.boards);
      } catch (error) {
        console.error("Error fetching existing boards: ", error);
        setError(error); // Assuming you have a state to handle errors
      }
    };

    fetchExistingBoards();
  }, []); // Empty dependency array to ensure this effect runs only on mount

  useEffect(() => {
    const numericBoardId = Number(board_id);

    const board = existingBoards.find((b) => b.id === numericBoardId);
    if (board) {
      setIsPublic(board.is_public);
    }
  }, [existingBoards]);

  const handleDeletePost = async (event) => {
    const flag = event === "r" ? "r" : null;

    if (!selectedPostId) {
      console.error("No selected post ID available for deletion.");
      return;
    }

    console.log("Attempting to delete post with ID:", selectedPostId);

    try {
      let url = `${Config.apiBaseUrl}posts/${selectedPostId}`;

      // Append flag as a query parameter if provided
      if (flag) {
        console.log("enter into flag", "flag", flag);
        url += `?flag=${flag}`;
      }

      const response = await axios.delete(url);

      // if (response === 200) {
      if (flag) {
        toast.success("Post removed successfully");
      } else {
        toast.success("Post deleted successfully");
      }
      window.location.reload();
      setIsHamburgerPopupVisible(false);
      // handleRefresh();
      // }

      const updatedContentItems = contentItems.filter(
        (item) => item.id !== selectedPostId
      );
      setContentItems(updatedContentItems);

      // If there are no posts left, show the welcome message
      setShowWelcomeMessage(updatedContentItems.length === 0);
      console.log("Post deleted successfully");

      // window.location.reload();
      // handleRefresh();
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  const handleBoardClick = (boardId, postId) => {
    // Define the postBody for the API call
    const postBody = {
      board_id: boardId,
      post_id: postId,
      user_id: sessionStorage.getItem("user_id"),
    };

    // Make the API call
    axios
      .post(`${Config.apiBaseUrl}boarditems`, postBody, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Success:", response.data);
        toast.success("Post added to board successfully!");
        // window.location.reload();
        handleRefresh();
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to add post to board");
      });
  };
  const [createboard, setCreateBoard] = useState({
    board_name: "",
    is_public: false,
    location: "",
    user_id: Number(sessionStorage.getItem("user_id")),
  });

  const changeHandler = (e) => {
    setCreateBoard({ ...createboard, [e.target.name]: e.target.value });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    debugger;
    console.log("createboard", createboard);
    const formData = new FormData();
    formData.append("user_id", createboard.user_id);
    formData.append("board_name", createboard.board_name);
    formData.append("image", selectedFile);
    formData.append("location", createboard.location);
    formData.append("is_public", createboard.is_public.toString());

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}boards`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Board created successfully", response.data);
        toast.success("Board created and post added successfully!");
        handleRefresh();
        const newBoardId = response.data.data.board_id;

        console.log("Board created", response.data.data.board_id);

        const postBody = {
          board_id: newBoardId,
          post_id: selectedPostId,
          user_id: createboard.user_id,
        };

        console.log("postBody", postBody);

        await axios.post(`${Config.apiBaseUrl}boarditems`, postBody, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        toast.success("Board created and post added successfully!");
        handleRefresh();
        // fetchPosts();
        // setShowPopup(false); // Close the popup after submission
      }
    } catch (error) {
      console.error("Error creating board or adding post:", error);
      toast.error("Failed to create board or add post");
      handleRefresh();
    }
  };

  const triggerFileInput = () => {
    document.getElementById("editPopup_fileInput").click();
  };

  const postImageCancel = (e) => {
    e.preventDefault();
    setBoardSelectedImage(null);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBoardSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("No image file selected");
    }
  };

  // ##############################################################################

  const closeAllPopups = () => {
    setShowFileUploadPopup(false);
    setShowLinkPopup(false);
    setShowNewNotePopup(false);
    setShowFilePostpopup(false);
    setIsHamburgerPopupVisible(false);
    setIsNewBoardPopupVisible(false);
    setIsCreateNewBoardPopupVisible(false);
    setBoardSelectedImage(false);
  };

  useGlobalKeyListeners(closeAllPopups);
  // ###################################################################################
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editPostData, setEditPostData] = useState(null);

  const handleEditClick = (postId, event) => {
    if (event && event.stopPropagation) {
      event.stopPropagation(); // Stop event propagation if the event object is available
    }
    const postToEdit = images.find((post) => post.post_id === postId);
    if (postToEdit) {
      setEditPostData(postToEdit); // Set the data for the post being edited
      setShowEditPopup(true); // Make the edit popup visible
    } else {
      console.error("Post not found!");
    }
  };
  const handleRefresh = () => {
    setRefresh(true); // Toggling the refresh state to ensure update
  };
  // ####################################################################################
  return (
    <div>
      <div className="d-flex" style={{ justifyContent: "space-between" }}>
        <div className="d-flex public-bd-name-back">
          <h1
            onClick={() => navigate(-1)}
            style={{ marginRight: 10, cursor: "pointer", color: "#FFF" }}
          >
            <img src={backbutton} alt="BackButton" />
          </h1>
          <h1 style={{ color: "#fff" }}>
            {boardPostsName.board_name || "Board Name"}
          </h1>
        </div>

        <div className="publicprivateimage" onClick={toggleBoardVisibility}>
          <img
            src={isPublic ? PublicSymbol : Privatesymbol}
            alt={isPublic ? "Public" : "Private"}
          />
          <span className="ms-2" style={{ color: "#FFF" }}>
            {isPublic ? "Public" : "Private"}
          </span>
        </div>
      </div>

      <div className=" postscontainer ">
        <button className="add_post_open_popup" onClick={handleAddNewFileClick}>
          + Add New File
        </button>

        {images.map((image, index) => (
          <div
            key={index}
            className={`image_grid postcard  ${
              image.post_type === "note" ? "boards_note " : ""
            }`}
          >
            {image.post_type === "note" ? (
              <div
                style={{ cursor: "pointer" }}
                className="bucklst-text-card  {`${textClass}`}"
                onClick={() => handleImageClick(image)}
              >
                <p>{image.title}</p>
                <span className="note_description">{image.description}</span>
                <button
                  className="hamburger_menu"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling
                    togglePopup(index, image.post_id);
                  }}
                >
                  <img
                    src={currentPopupIndex === index ? Closeicon : Moreoption}
                    alt={currentPopupIndex === index ? "Close" : "More options"}
                  />
                </button>
                {currentPopupIndex === index && (
                  <div className="hamburger-board-popup">
                    <div className="popup-board-box">
                      <div
                        className="popup-option"
                        onClick={(event) =>
                          handleEditClick(image.post_id, event)
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        <span>Edit</span>
                      </div>
                      <div
                        className="popup-option"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent event bubbling
                          handleDeletePost("r");
                        }}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        <span>Remove from board</span>
                      </div>
                      <div
                        className="popup-option"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent event bubbling
                          handleDeletePost();
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <span>Delete</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                {image.url.includes("youtube.com") ? (
                  <ReactPlayer
                    url={image.url}
                    className="react-player"
                    controls
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <img
                    className="boards_post_image"
                    src={image.url}
                    alt="image"
                    onClick={() => handleImageClick(image)}
                  />
                )}
                <button
                  className="hamburger_menu"
                  onClick={() => togglePopup(index, image.post_id)}
                >
                  <img
                    src={currentPopupIndex === index ? Closeicon : Moreoption}
                    alt={currentPopupIndex === index ? "Close" : "More options"}
                  />
                </button>

                {currentPopupIndex === index && (
                  <div className="hamburger-board-popup">
                    <div className="popup-board-box">
                      <div
                        className="popup-option"
                        onClick={() => handleEditClick(image.post_id)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                        <span>Edit</span>
                      </div>
                      <div
                        className="popup-option"
                        onClick={() => {
                          handleDeletePost("r");
                        }}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        <span>Remove from board</span>
                      </div>
                      <div className="popup-option" onClick={handleDeletePost}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                        <span>Delete</span>
                      </div>
                    </div>
                  </div>
                )}
                {showEditPopup && editPostData && (
                  <Boardeditpostpopup
                    show={showEditPopup}
                    post={editPostData}
                    onClose={() => setShowEditPopup(false)}
                    onSave={(updatedPost) => {
                      console.log("Updated Post:", updatedPost);
                      setShowEditPopup(false);
                      // Update the post data in your state or re-fetch from the server
                    }}
                    onRefresh={handleRefresh}
                  />
                )}
                {isNewBoardPopupVisible && (
                  <div className="add-board-overlay">
                    <div className="add-board-popup">
                      <span
                        className="close-popup"
                        onClick={toggleNewBoardPopup}
                      >
                        X
                      </span>
                      <div
                        className="add-board-action"
                        onClick={() => toggleCreateNewBoardPopup()}
                      >
                        <img src={addButton} alt="Add" />
                        <span>Create New board</span>
                      </div>
                      <div className="board-list">
                        <div className="board-list">
                          {isLoading ? (
                            <div>Loading...</div>
                          ) : error ? (
                            <div>Error: {error.message}</div>
                          ) : existingBoards.length > 0 ? (
                            existingBoards.map((board) => (
                              <div
                                className="board-item"
                                key={board.id}
                                onClick={() =>
                                  handleBoardClick(board.id, selectedPostId)
                                }
                              >
                                {board.name}
                              </div>
                            ))
                          ) : (
                            <div>No existing boards found.</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isCreateNewBoardPopupVisible && (
                  <div className="editPopup_overlay">
                    <div
                      className="editPopup_content"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <form onSubmit={submitHandler}>
                        <div className="editPopup_modalHeader">
                          <input
                            type="text"
                            className="editPopup_input"
                            placeholder="New board name"
                            value={createboard.board_name}
                            name="board_name"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="editPopup_modalBody">
                          <div
                            className="editPopup_imageContainer"
                            onClick={triggerFileInput}
                          >
                            {boardselectedimage ? (
                              <>
                                <img
                                  src={boardselectedimage}
                                  alt="Selected cover"
                                  className="editPopup_selectedImage"
                                />
                                <div
                                  className="editPopup_overlayText"
                                  style={{ color: "#FFF" }}
                                >
                                  Click to change
                                </div>
                              </>
                            ) : (
                              <div
                                className="editPopup_uploadText"
                                style={{ color: "#FFF" }}
                              >
                                Upload cover image
                              </div>
                            )}
                            <input
                              id="editPopup_fileInput"
                              type="file"
                              className="editPopup_hidden"
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                          </div>

                          <div className="editPopup_buttons">
                            <button
                              className="editPopup_cancelButton"
                              onClick={postImageCancel}
                            >
                              Cancel
                            </button>
                            <button
                              className="editPopup_createButton"
                              type="submit"
                            >
                              Create
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
        {showPostPopup && selectedPostItem && (
          <PostPopupofBoard
            isPopupVisible={showPostPopup}
            popupItem={selectedPostItem}
            images={images}
            setIsPopupVisible={setShowPostPopup}
            formatDescription={formatDescription}
          />
        )}
        {showFileUploadPopup && (
          <BoardFileUploadPopup
            setShowFileUploadPopup={setShowFileUploadPopup}
            toggleNewFile={toggleNewFile}
            toggleNotePopup={toggleNotePopup}
            // showLinkPopup = {showLinkPopup}
            onClickNewLink={onClickNewLink}
          />
        )}

        <PostFilePopup
          showFilePostPopup={showFilePostpopup}
          setShowFilePostPopup={setShowFilePostpopup}
          userProfile={userProfile}
          username={username}
          selectedPostFile={selectedPostFile}
          userLocation={location}
          handleResetSelectedPostFile={handleResetSelectedPostFile}
          toggleNewFile={toggleNewFile}
          handleFilePostChange={handleFilePostChange}
          finalPostTags={finalPostTags}
          onSaveToBucketlist={handleSaveToBucketlist}
          onRefresh={handleRefresh} // Instead of setRefresh(true);
          flag="board"
          selectedBoardId={selectedBoardId}
        />

        <NewNotePopup
          show={showNewNotePopup}
          onClose={() => setShowNewNotePopup(false)}
          setShowFilePostPopup={setShowFilePostpopup}
          userProfile={userProfile}
          username={username}
          userLocation={location}
          selectedPostId={selectedPostId}
          setShowNewNotePopup={setShowNewNotePopup}
          onSaveToBucketlist={handleSaveToBucketlist}
          onRefresh={handleRefresh} // Instead of setRefresh(true);
          flag="board"
          selectedBoardId={selectedBoardId}
        />
        <LinkPopup
          show={showLinkPopup}
          onClose={() => setShowLinkPopup(false)}
          userProfile={userProfile}
          username={username}
          onRefresh={handleRefresh}
          // userLocation={location}
          flag="board"
          selectedBoardId={selectedBoardId}
        />

        {showPostPopup && selectedPostItem && (
          <PostPopupofBoard
            isPopupVisible={showPostPopup}
            popupItem={selectedPostItem}
            setIsPopupVisible={setShowPostPopup}
            formatDescription={formatDescription}
            onRefresh={handleRefresh}
          />
        )}
      </div>
    </div>
  );
};

export default BoardDetails;
