import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Config from "./../../../Assets/Config";
import { useSearch } from "./../../../Assets/queryprovider";
import "./PopularTrips.css";
import PopularPost from "../PopularPosts/PopularPost";

// Debounce function to limit API requests
const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const PopularTrips = ({ onPopularPostsData }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popularPostsData, setPopularPostsData] = useState([]);
  const [showPopularPost, setShowPopularPost] = useState(false);

  const [boards, setBoards] = useState([]);
  const { query } = useSearch(); // Assuming 'query' is an array of strings
  const navigate = useNavigate();

  // Function to fetch boards based on the query
  const fetchBoards = useCallback(async () => {
    // Join query terms with commas for the API request
    const queryString =
      query.length > 0 ? query.map((q) => encodeURIComponent(q)).join(",") : "";

    // Construct the fetch URL based on whether there are query terms
    const fetchUrl = queryString
      ? `${Config.apiBaseUrl}hashtags/${queryString}`
      : `${Config.apiBaseUrl}boards/popular`;

    try {
      const response = await axios.get(fetchUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const fetchedData = response.data.data; // Adjust according to your API response structure
      console.log("Fetched data:", fetchedData);

      // Process boards for display, setting up grid spans and adding a 'See More' button
      const patterns = [
        [
          [1, 2],
          [2, 1],
        ],
        [
          [1, 2],
          [1, 1],
        ],
        [
          [1, 1],
          [1, 1],
        ],
      ];
      let patternIndex = 0,
        boardIndex = 0;

      const processedBoards = fetchedData.slice(0, 5).map((board) => {
        const [colSpan, rowSpan] = patterns[patternIndex][boardIndex++];
        if (boardIndex >= patterns[patternIndex].length) {
          boardIndex = 0;
          patternIndex = (patternIndex + 1) % patterns.length;
        }
        return { ...board, colSpan, rowSpan };
      });

      processedBoards.push({ isSeeMore: true }); // Append 'See More' button at the end
      setBoards(processedBoards);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [query]);

  // Debounced version of fetchBoards to reduce API calls on rapid query changes
  const debouncedFetchBoards = useCallback(debounce(fetchBoards, 500), [
    fetchBoards,
  ]);

  useEffect(() => {
    debouncedFetchBoards();
  }, [debouncedFetchBoards]);

  // Handler to fetch the board images
  const handleBoardClick = async (board_id, board_name) => {
    console.log("Board ID:", board_id);
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/board/${board_id}`
      );
      const boardPostsData = response.data.data;
      console.log("Board posts data:", boardPostsData);

      if (!boardPostsData || boardPostsData.length === 0) {
        setPopupMessage(`No board items available for "${board_name}"`);
        setShowPopup(true);
      } else {
        onPopularPostsData({ name: board_name, data: boardPostsData });
        // setShowPopularPost(true);
        setShowPopup(false);
      }
    } catch (error) {
      console.error("Error fetching board details:", error);
      setPopupMessage("Error fetching data. Please try again later.");
      setShowPopup(true);
    }
  };

  // Navigate to the 'PopularBoards' page
  const handleSeeMoreClick = () => {
    navigate("/popularboards");
    window.scrollTo(0, 0);
  };

  return (
    <div className="PopularTrips-container">
      {showPopup && (
        <div className="popup-overlay2">
          <div className="popup-content2">
            {popupMessage}
            <button
              className="close-button"
              onClick={() => setShowPopup(false)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
      {showPopularPost ? (
        <PopularPost hashtagData={{ data: popularPostsData }} />
      ) : (
        boards.map((item, index) =>
          item.isSeeMore ? (
            <button
              key={index}
              onClick={handleSeeMoreClick}
              className="see-more-button"
            >
              <span>See More</span>
            </button>
          ) : (
            <div
              key={index}
              className="boxbody"
              style={{
                gridColumn: `span ${item.colSpan}`,
                gridRow: `span ${item.rowSpan}`,
              }}
              onClick={() => handleBoardClick(item.board_id, item.title)}
            >
              <img src={item.image_url} alt={`Media ${index + 1}`} />
              <div className="image-title">{item.title}</div>
            </div>
          )
        )
      )}
    </div>
  );
};

export default PopularTrips;
