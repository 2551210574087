import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useSessionTimeout = (timeout = 30000000) => {
  // Default to 5 minutes
  const navigate = useNavigate();

  useEffect(() => {
    let timer = setTimeout(() => {
      sessionStorage.clear();
      navigate("/"); // Replace with your actual login route
    }, timeout);

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        sessionStorage.clear();
        navigate("/"); // Replace with your actual login route
      }, timeout);
    };

    window.addEventListener("load", resetTimer);
    document.addEventListener("mousedown", resetTimer);
    document.addEventListener("keypress", resetTimer);
    document.addEventListener("touchstart", resetTimer);
    document.addEventListener("mousemove", resetTimer);

    return () => {
      clearTimeout(timer);
      document.removeEventListener("mousedown", resetTimer);
      document.removeEventListener("keypress", resetTimer);
      document.removeEventListener("touchstart", resetTimer);
      document.removeEventListener("mousemove", resetTimer);
    };
  }, [navigate, timeout]);
};

export default useSessionTimeout;
