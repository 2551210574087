import React, { useRef } from "react";
import "./Bucketlist.css";
import UserBoards from "./UserBoards/UserBoards";
import MyFiles from "./MyFiles/MyFiles";
import PublicBoards from "./PublicBoards/PublicBoards";
import { useSearch } from "../../Assets/queryprovider";
const BucketList = () => {
  const myFilesRef = useRef(null);

  const scrollToMyFiles = () => {
    const headerOffset = 150; // Height of the header
    const elementPosition = myFilesRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const { posts, query, clearQuery, removeQuery } = useSearch(); //
  sessionStorage.removeItem("filterList");

  const handleClearFilter = (index) => {
    // clearQuery(); // Clearing the global search query

    removeQuery(index);
    // window.location.reload();
  };
  const renderFilterTag = () => {
    let filterItems = [];

    if (posts.length > 0) {
      filterItems = [...filterItems, ...posts.map((post) => post.boardName)];
    }

    if (query.length > 0) {
      filterItems = [...filterItems, ...query];
    }

    const hasFilters = filterItems.length > 0; // Check if there are any filters

    return (
      <>
        {hasFilters && (
          <div className="filter-tag">
            <span className="filter-text">Filter:</span>
            {filterItems.map((item, index) => (
              <div key={index} className="filter-query-container">
                <div className="filter-query">{item}</div>
                <button
                  className="clear-filter-button"
                  onClick={() => handleClearFilter(index)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="homebody">
      <div className="">
        <div className="col-12">
          <div className="row justify-content ms-5">
            <div className="col-sm-12 p-0">
              <div className="pb-4">
                <div className="d-flex justify-content-start align-items-center centercontaint">
                  <div className="col-4">
                    <div className="tittle">My Bucket Lists</div>
                  </div>
                  {/* <button
                    type="button"
                    className="rounded-pill bucket_tittles btn btn-dark"
                  >
                    {" "}
                    Everything{" "}
                  </button>
                  <button
                    type="button"
                    className="rounded-pill bucket_tittles btn btn-dark"
                  >
                    {" "}
                    My Boards{" "}
                  </button>
                  <button
                    type="button"
                    className="rounded-pill bucket_tittles btn btn-dark"
                    onClick={scrollToMyFiles}
                  >
                    My Files
                  </button> */}
                </div>
              </div>

              {renderFilterTag()}
              <div className="">
                <div>
                  {!posts ||
                    (posts.length === 0 && !query.length && <UserBoards />)}
                </div>
              </div>

              <div className="col-12 col-sm-12 centercontaint publicBoardWrapper">
                <div className="">
                  {!posts ||
                    (posts.length === 0 && !query.length && (
                      <div>
                        <span className="heddername">Public Trip Boards</span>
                        <PublicBoards />
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-12 col-8 myFilesWrapper" ref={myFilesRef}>
                {!posts ||
                  (posts.length === 0 && !query.length && (
                    <span className="heddername">My Files</span>
                  ))}
                <MyFiles />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BucketList;
