import React, { useEffect, useState } from "react";
import "./Footer.css";
import Currentyear from "../../Assets/Currentyear";

const Footer = () => {
  const currentyear = Currentyear();
  return (
    <div className="footer">
      <div className="d-flex mt-5">
        <p className="footertext">
          <a
            className="anchertag"
            href="https://nexttrip.com/terms-conditions.php"
            target="_blank"
          >
            Terms of Service
          </a>{" "}
          &nbsp;&nbsp;{" "}
          <a
            className="anchertag"
            href="https://nexttrip.com/privacy-policy.php"
            target="_blank"
          >
            Privacy Policy
          </a>
          &nbsp;&nbsp;
          <a
            className="anchertag"
            href="https://nexttrip.com/terms-conditions.php"
            target="_blank"
          >
            Cookie Policy
          </a>{" "}
          &nbsp;&nbsp;{" "}
          <a
            className="anchertag"
            href="https://nexttrip.com/terms-conditions.php"
            target="_blank"
          >
            Accessibility
          </a>
        </p>
        <p className="Holdings">© {currentyear} NextTrip Holdings, Inc.</p>
      </div>
    </div>
  );
};

export default Footer;
