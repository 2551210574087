import React, { useRef, useState, useEffect } from "react";
import "./ProfileInformation.css";
import axios from "axios";
import Config from "../../../Assets/Config";
import { toast } from "react-toastify";

const ProfileInformation = () => {
  const inputRef = useRef(null);
  const [selectedImagefile, setSelectedImagefile] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedFilepost, setselectedFilepost] = useState(null);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [originalFormData, setOriginalFormData] = useState({});
  const [formData, setFormData] = useState({
    user_id: Number(sessionStorage.getItem("user_id")),
    firstName: "",
    lastName: "",
    userName: "",
    logo: "",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const user_id = Number(sessionStorage.getItem("user_id"));
        const response = await axios.get(`${Config.apiBaseUrl}user/${user_id}`);
        const userData = response.data.data;

        setFormData({
          user_id,
          firstName: userData.first_name,
          lastName: userData.last_name,
          userName: userData.user_name,
          logo: userData.logo,
        });

        setOriginalFormData({
          user_id,
          firstName: userData.first_name,
          lastName: userData.last_name,
          userName: userData.user_name,
          logo: userData.logo,
        });

        setSelectedImagefile(userData.logo);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    }

    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => {
      const newFormData = { ...prevState, [name]: value };
      checkIfFormChanged(newFormData);
      return newFormData;
    });
  };

  const checkIfFormChanged = (newFormData) => {
    const isChanged =
      newFormData.firstName !== originalFormData.firstName ||
      newFormData.lastName !== originalFormData.lastName ||
      newFormData.userName !== originalFormData.userName ||
      selectedFilepost !== null;

    setIsFormChanged(isChanged);
  };

  const submitForm = async () => {
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("user_id", formData.user_id);
    formDataToSubmit.append("first_name", formData.firstName);
    formDataToSubmit.append("last_name", formData.lastName);
    formDataToSubmit.append("user_name", formData.userName);
    if (selectedFilepost) {
      formDataToSubmit.append("logo", selectedFilepost);
    }

    try {
      const response = await axios.put(
        `${Config.apiBaseUrl}user`,
        formDataToSubmit,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      toast.success("Profile Updated successfully!");

      const updatedUserData = response.data.data;
      sessionStorage.setItem("user_profile_logo", updatedUserData.logo);

      // Update the state with the new logo
      setFormData((prevState) => ({
        ...prevState,
        logo: updatedUserData.logo,
      }));
      // window.location.reload();
      setOriginalFormData((prevState) => ({
        ...prevState,
        logo: updatedUserData.logo,
      }));

      setSelectedImagefile(updatedUserData.logo);
      setselectedFilepost(null);
      setIsFormChanged(false);
    } catch (error) {
      console.error("Failed to submit form:", error);
      setErrors({ form: "Failed to submit form. Please try again." });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file && allowedTypes.includes(file.type)) {
      setselectedFilepost(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagefile(reader.result);
        setIsFormChanged(true); // Enable Save button on first image selection
      };
      reader.readAsDataURL(file);
    } else {
      setErrors({
        ...errors,
        logo: "Please select a JPEG, JPG, or PNG image file.",
      });
      toast.error("Allowed file types: JPEG, JPG, PNG");
    }
  };

  const handleProfilePictureChange = () => {
    inputRef.current.click();
  };

  return (
    <div className="row">
      <div className="col-12 col-md-12">
        <div className="profile_heading">Profile Information</div>
        <div>
          <div className="lablenames">Name</div>
          <input
            type="text"
            placeholder="FirstName"
            className="form-control nameinput"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />

          <input
            type="text"
            placeholder="LastName"
            className="form-control nameinput"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <div className="lablenames mt-4">Username</div>
          <input
            type="text"
            placeholder="Username"
            className="form-control nameinput"
            name="userName"
            value={formData.userName}
            onChange={handleInputChange}
          />
        </div>
        <div className="lablenames mt-4">Profile Photo</div>
        <div className="test">
          <div className="profile-container">
            <div style={{ padding: "10px" }}>
              <div
                className="uploadImg rounded-circle"
                onClick={handleProfilePictureChange} // Handle click on profile image container
              >
                {selectedImagefile && (
                  <img
                    src={selectedImagefile}
                    className="selectedimage"
                    alt="Selected Profile"
                  />
                )}
                <input
                  ref={inputRef}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                {errors.logo && (
                  <div
                    style={{ color: "red", fontSize: "14px", width: "200px" }}
                  >
                    {/* {errors.logo} */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="change-profile-container"
            onClick={handleProfilePictureChange}
          >
            <label className="change_profile_label">
              Change Profile Picture
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <button
              onClick={submitForm}
              className="savebutton"
              disabled={!isFormChanged} // Disable button if no changes were made
              style={{ backgroundColor: !isFormChanged ? "#272727" : null }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileInformation;
