import React, { useEffect, useState } from "react";
import TM_Img from "./../../../Assets/Images/Prague Board.png";
import TM_Random from "./../../../Assets/Images/Random Board.png";
import TM_Rectangle44 from "./../../../Assets/Images/Rectangle 58.png";
import TM_Rectangle61 from "./../../../Assets/Images/Rectangle 61.png";
import VikingCruise from "./../../../Assets/Images/Viking Cruise Ad.png";
import TheWay from "./../../../Assets/Images/TheWay.png";
import Alaska from "./../../../Assets/Images/Alaska.png";
import simonReeve from "./../../../Assets/Images/simonReeve.png";
import "./PopularAndTrending.css";
import axios from "axios";
import Config from "./../../../Assets/Config";
import MexicoBeach from "./../../../Assets/Images/MexicoBeach.png";
import Group1 from "./../../../Assets/Images/Group1.png";
import Group2 from "./../../../Assets/Images/Group2.png";
import Group3 from "./../../../Assets/Images/Group3.png";
import Group4 from "./../../../Assets/Images/Group4.png";
import locationpointer from "./../../../Assets/Images/locationpointer .png";
import {  useNavigate } from "react-router-dom";

const PopularAndTrending = ({ onHashtagSelect, setHashtagData }) => {
  // for 2 images with  data

  const imagecontainer = [
    {
      image: Group1,
      link: "https://www.travelmagazine.com/travel-buddy",
    },
    {
      image: Group4,
      link: "http://voyagetvdemo.com/",
    },
  ];
  const contentData = [
    {
      image: MexicoBeach,
      description: "Americans are flocking to Mexico in March. ",
      link: "https://www.travelmagazine.com/posts/8-adventures-that-you-should-definitely-not-go-on-in-mexico",
    },
    {
      image: TM_Img,
      description: " Another piece of content.  ",
      link: 'https://www.mexicancaribbean.travel/cancun/"',
    },
  ];

  const vediolen = [
    {
      image: Alaska,
      link: "http://www.voyagetvdemo.com/",
    },
    {
      image: TheWay,
      link: "http://www.voyagetvdemo.com/",
    },
    {
      image: simonReeve,
      link: "http://www.voyagetvdemo.com/",
    },
  ];

  const defaultPosts = [
    {
      description: "",
      image:
        "https://setupcustomer.s3.amazonaws.com/promotional-content/3f629dc0-8986-4d01-908d-e5977754ce49.jpg",
      link: "https://www.mexicancaribbean.travel/cancun/",
      location: "Cancun, Mexico",
      promotion_type: "post",
    },
    {
      description: "",
      image:
        "https://setupcustomer.s3.amazonaws.com/promotional-content/cffdcb63-9b86-4bed-a83f-5371d8b83a56.png",
      link: "",
      location: "Lake Tahoe, USA",
      promotion_type: "post",
    },
    {
      description: "",
      image:
        "https://setupcustomer.s3.amazonaws.com/promotional-content/a04eaae1-a2b6-42ce-9dd0-945658285e1b.png",
      link: "",
      location: "Kauai, USA",
      promotion_type: "post",
    },
  ];

  const [articles, setArticles] = useState([]);
  const [posts, setPosts] = useState([]);
  const [tv, setTv] = useState([]);
  const [video, setVideo] = useState([]);
  const [hashtags, setHashTags] = useState([]);
  const navigate = useNavigate();
  const handleHashtagClick = (hashtag) => {
    navigate("/filtersearch", {
      state: { filterSearch: hashtag, filterFlag: "h"},
    });
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${Config.apiBaseUrl}promotions`)
        .then((res) => {
          const responseData = res.data.data; // Assuming your API structure
          // Check if responseData has posts, if not use defaultPosts
          if (
            responseData &&
            responseData.post &&
            responseData.post.length > 0
          ) {
            setPosts(responseData.post);
          } else {
            // Fallback to defaultPosts if API returns no posts
            setPosts(defaultPosts);
          }
        })
        .catch((error) => {
          console.error("Error fetching the promotions data:", error);
          // Fallback to defaultPosts in case of any error
          setPosts(defaultPosts);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(`${Config.apiBaseUrl}hashtags/popular`)
      .then((res) => setHashTags(res.data))
      .catch((error) => console.error("Error fetching hashtags:", error));
  }, []);

  const Data = [
    {
      id: 1,
      image: TM_Random,
      board_name: "USA",
    },
    {
      id: 2,
      image: TM_Rectangle44,
      board_name: "china",
    },
    {
      id: 3,
      image: TM_Img,
      board_name: "Goa",
    },
  ];

  const Video_Data = [
    {
      id: 1,
      video:
        "https://player.vimeo.com/external/384761655.sd.mp4?s=383ab4dbc773cd0d5ece3af208d8f963368f67e4&profile_id=164&oauth2_token_id=57447761",
    },
  ];
  return (
    <div className="mbl-disp-none">
      <div className="rightcard ">
        <div className="centercard">
          <h4>Popular & Trending</h4>

          {imagecontainer.map((post, index) => (
            <div className="rgt-poplar-tread-card-img" key={index}>
              <a href={post.link} target="_blank">
                <img src={post.image} className="mb-3 " />
              </a>
            </div>
          ))}

          {contentData.map((content, index) => (
            <div className="popular_cards" key={index}>
              <div className="row">
                <div className="col-md-5">
                  <a href={content.link} target="_blank">
                    {" "}
                    <img src={content.image} className="centerimg" />
                  </a>
                </div>
                <div className="col-md-6 mt-2    pop_comment_trending">
                  <a
                    href={content.link}
                    target="_blank"
                    style={{ textDecoration: "none", color: "#FFF" }}
                  >
                    {content.description}
                  </a>
                </div>
              </div>
            </div>
          ))}

          {posts.map((post, index) => (
            <div className="cards" key={index}>
              <a href={post.link} target="_blank">
                <img src={post.image} className="centerimgfull" />
                <div className="pop_board_name mb-5">
                  <img src={locationpointer} alt="img" />
                  {post.location}
                </div>
              </a>
            </div>
          ))}

          <div className="cards">
            <div className="hashtags">
              {hashtags.map((tag, index) => (
                <div
                  key={index}
                  onClick={() => handleHashtagClick(tag)}
                  style={{ cursor: "pointer" }}
                >
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* {Video_Data.map((myvideo, index) => (
        <div className="cardvideo" key={index}>
          <video controls autoPlay loop className="card_Video_popular">
            <source src={myvideo.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ))} */}
      <div className="mt-5 VikingCruise-img">
        <img src={VikingCruise} alt="alt" />
      </div>
      {/* <h4 className="Vogyatv">COMPASS.TV</h4>
      {vediolen.map((tv, index) => (
        <div className="mt-5" key={index}>
          <a href={tv.link} target="_blank" rel="noopener noreferrer">
            <img
              src={tv.image || Alaska}
              alt={Alaska} 
              className="tvimgfull"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = Alaska;
              }}
            />
          </a>
        </div>
      ))} */}
    </div>
  );
};

export default PopularAndTrending;
