import React, { useState } from "react";
import { useSearch } from "../../../Assets/queryprovider";
import "./MyFile.css";
import UserPost from "./Posts/UserPost";

const MyFiles = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // state for showing the popup
  const { posts, query } = useSearch();

  const toggleDropdown = () => {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  };

  const togglePopup = () => {
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };

  return (
    <div>
      <div className="col-12 col-sm-12">
        <div className="innerContainer">
          <div className=" main-layout">
            <UserPost />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyFiles;
