import React, { useRef, useEffect, useState, useCallback } from "react";
import TM_likeIcon from "./../../../Assets/Images/Like Icon.png";
import TM_Ellipse from "./../../../Assets/Images/Ellipse 25.png";
import TM_bucketlist from "./../../../Assets/Images/bucketlist.png";
import TM_ShareIcon from "./../../../Assets/Images/Share Icon.png";
import TM_Vector19 from "./../../../Assets/Images/Vector 19.png";
import likecon from "./../../../Assets/Images/likecon.png";
import Vector47 from "./../../../Assets/Images/Vector 47.png";
import locationpointer from "./../../../Assets/Images/locationpointer .png";
import "./PopularPost.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { PostLikes } from "./../../../Assets/PostLikes";
import Config from "./../../../Assets/Config";
import { useSearch } from "../../../Assets/queryprovider";
import Circle from "./../../../Assets/Images/Circle.png";
import { useLocation, useNavigate } from "react-router-dom";
import BucketListPopup from "../../BucketList/MyFiles/Posts/BucketListPopup";
import useGlobalKeyListeners from "../../../useGlobalKeyListeners";
import ReactPlayer from "react-player";

const PopularPost = ({ hashtagData }) => {
  const location = useLocation();
  const { boardPostsData } = location.state || {};
  const userImage = "";
  const [isExpanded, setIsExpanded] = useState(false);
  // State to hold the search filter and flag
  const navigate = useNavigate();

  const handleLocationClick = (location) => {
    navigate("/filtersearch", {
      state: { filterSearch: location, filterFlag: "l" },
    });
  };

  const handleHashtagClick = (hashtag) => {
    navigate("/filtersearch", {
      state: { filterSearch: hashtag, filterFlag: "h" },
    });
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  // State to keep track of new comment inputs
  const [commentInputs, setCommentInputs] = useState({});
  // State to keep track of comments fetched from the server
  const [postComments, setPostComments] = useState({});
  const [popupCommentInput, setPopupCommentInput] = useState("");
  const [activeReplies, setActiveReplies] = useState({}); // This will be an object with post IDs as keys and the active comment ID as values
  const [activeComment, setActiveComment] = useState(null); // Track the active comment being replied to

  const [replies, setReplies] = useState([]);
  const [liked, setLiked] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState([]);
  // const { query, setQuery } = useSearch(""); // Accessing the query value

  const [postLike, setPostLike] = useState({
    like_type_id: "",
    like_type: "post",
    public_id: sessionStorage.getItem("public_id"), // Use sessionStorage to get the public_id value
  });
  const [likedPostss, setLikedPosts] = useState({});
  const { query, setQuery, removeQuery } = useSearch();
  const username = sessionStorage.getItem("username");
  const fetchPosts = useCallback(async () => {
    try {
      let url = `${Config.apiBaseUrl}posts/popular`;
      const queryString = query
        .map((querys) => encodeURIComponent(querys))
        .join(",");

      if (queryString.trim()) {
        url = `${Config.apiBaseUrl}hashtags/${queryString}`;
      }

      console.log("Fetching posts from:", url);

      const userId = sessionStorage.getItem("user_id");
      const config = {
        method: "get",
        url: url,
      };

      if (url === `${Config.apiBaseUrl}posts/popular`) {
        config.params = { user_id: userId };
      }

      const { data } = await axios(config);
      console.log(
        "##################Fetched data:###########################",
        data
      );

      // Update imageData with the fetched data
      setImageData(data.data || []);

      // Create an object mapping post IDs to their liked status
      const likedPostsObject = data.data.reduce((acc, post) => {
        acc[post.post_id] = post.liked_by_current_user;
        return acc;
      }, {});

      setLikedPosts(likedPostsObject);

      const firstPost = data.data[0];
      const likeTypeId = firstPost ? firstPost.post_id : null;
      console.log("likeTypeId", likeTypeId);
      setPostLike((prevState) => ({ ...prevState, like_type_id: likeTypeId }));
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }, [query]);

  const debouncedFetchPosts = useCallback(debounce(fetchPosts, 500), [
    fetchPosts,
  ]);
  useEffect(() => {
    debouncedFetchPosts();
  }, [debouncedFetchPosts]);

  const handleLikeClick = async (postId, isFromPopup = false) => {
    try {
      const isLiked = !likedPostss[postId]; // Determine the new liked status

      // Update the liked posts state
      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        [postId]: isLiked,
      }));

      // Update the image data state
      setImageData((currentImageData) =>
        currentImageData.map((image) => {
          if (image.post_id === postId) {
            return {
              ...image,
              liked: isLiked,
              likes_count: image.likes_count + (isLiked ? 1 : -1),
            };
          }
          return image;
        })
      );

      // If the like action came from the popup, update the selectedImage state
      if (isFromPopup && selectedImage && selectedImage.post_id === postId) {
        setSelectedImage((prevImage) => ({
          ...prevImage,
          liked: isLiked,
          likes_count: prevImage.likes_count + (isLiked ? 1 : -1),
        }));
      }
      // Make the API call to update the like status
      const likeData = {
        like_type_id: postId,
        like_type: "post",
        public_id: sessionStorage.getItem("public_id"),
      };
      await PostLikes(likeData);
    } catch (error) {
      console.error("Error handling like:", error);
    }
  };

  // ##########################################################################################################

  let contentData = [];
  if (
    hashtagData?.data &&
    Array.isArray(hashtagData.data) &&
    imageData &&
    Array.isArray(imageData)
  ) {
    // If both hashtagData and queryData exist, add their contents to contentData
    console.log(
      "Using posts from both 'hashtagData' and 'queryData' properties."
    );
    contentData = hashtagData.data;
  } else if (hashtagData?.data && Array.isArray(hashtagData.data)) {
    // At this point, posts.data.posts is confirmed to be an array
    if (hashtagData.data.length > 0) {
      // 'posts.data.posts' is defined and has one or more items
      console.log(
        "Using posts from the 'data.posts' property:",
        hashtagData.data
      );
      contentData = hashtagData.data;
    } else {
      // 'posts.data.posts' is defined but empty
      console.log("No posts available in 'posts.data.posts', showing empty");
      contentData = [];
    }
  } else {
    console.log("Fallback to 'contentItems':", imageData);
    contentData = imageData;
  }
  console.log(
    "#############################Final Data'contentItems':",
    contentData
  );
  // £££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££
  const [displayHashtag, setDisplayHashtag] = useState(true);
  // const { query, removeQuery } = useSearch();

  useEffect(() => {
    // Whenever the hashtagData prop changes, reset the display state
    setDisplayHashtag(!!hashtagData && query.length > 0);
  }, [hashtagData]);

  const handleRemoveHashtag = () => {
    setDisplayHashtag(false); // Simply hides the hashtag from the UI
    window.location.reload();
  };
  const handleRemoveQuery = (index) => {
    removeQuery(index);
  };

  // Renders individual filter tags for both hashtag and queries
  const renderFilterTag = () => {
    const filterTags = [];
    // debugger;

    const hasFilter =
      (hashtagData && hashtagData.name !== "board") || query.length > 0;

    // Render "Filter:" label if either hashtagData or query is present
    if (hasFilter) {
      filterTags.push(
        <div className="postfilter-tag" key="filter-label">
          <span className="postfilter-text">Filter:</span>
        </div>
      );
    }

    // Add hashtag filter tag if it exists
    if (hashtagData && hashtagData.name !== "board") {
      // debugger;
      filterTags.push(
        <div className="postfilter-tag" key="hashtag">
          {/* {hasFilter && <span className="postfilter-text">Filter:</span>} */}
          <div className="postfilter-query-container">
            <span className="postfilter-query">{hashtagData.name}</span>
            <button
              className="postclear-filter-button"
              onClick={handleRemoveHashtag}
            >
              X
            </button>
          </div>
        </div>
      );
    }

    // Add query filter tags
    query.forEach((querys, index) => {
      filterTags.push(
        <div className="postfilter-tag" key={`query-${index}`}>
          {/* {hasFilter && index === 0 && (
          <span className="postfilter-text">Filter:</span>
        )} */}
          <div className="postfilter-query-container">
            <span className="postfilter-query">{querys}</span>
            <button
              className="postclear-filter-button"
              onClick={() => handleRemoveQuery(index)}
            >
              X
            </button>
          </div>
        </div>
      );
    });

    return filterTags.length > 0 ? <div>{filterTags}</div> : null;
  };
  // £££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££££
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await PostLikes(postLike);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  console.log(postLike);

  const handleClearFilter = () => {
    setQuery("");
    window.location.reload();
  };

  // Popular Post Code Start

  const postImage = (image) => {
    // debugger;
    setSelectedImage(image);
  };

  const [activeReplyIds, setActiveReplyIds] = useState({});
  const [replyTexts, setReplyTexts] = useState({});
  const [newReplyPosted, setNewReplyPosted] = useState(false);
  const [repliesVisibility, setRepliesVisibility] = useState({});

  const [activeReply, setActiveReply] = useState(null);
  const [replyText, setReplyText] = useState({});

  // const handlePopupCommentChange = (e) => {
  //   if (!activeReply){
  //     setPopupCommentInput(e.target.value);
  //   }

  // };

  // Function to handle reply text change
  const handleReplyTextChange = (commentId, text) => {
    console.log("@@@@@@@@@@@@@@@@@2 commentId, text", commentId, text);
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [commentId]: text,
    }));
  };

  const handleSendReply = (postId, commentId, e) => {
    e.preventDefault();
    const replyTextContent = replyText[commentId];
    if (replyTextContent.trim()) {
      setActiveComment(commentId);
      handlePopupCommentSubmit(postId, e);
    }
  };

  const handleCommentChange = (e, postId) => {
    const newComment = e.target.value;
    setCommentInputs((prevInputs) => ({
      ...prevInputs,
      [postId]: newComment,
    }));
  };

  async function handleSeeMoreClick(post_id, commentId) {
    // Toggle visibility off if currently showing and no new reply has been posted
    if (repliesVisibility[commentId] && !newReplyPosted) {
      setRepliesVisibility((prev) => ({ ...prev, [commentId]: false }));
      return;
    }
    // Fetch the latest replies
    try {
      const response = await fetch(
        `${Config.apiBaseUrl}posts/comments/replies`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            post_id: post_id,
            commentId: commentId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const fetchedReplies = await response.json();
      setReplies((prevReplies) => ({
        ...prevReplies,
        [commentId]: fetchedReplies.data,
      }));
      // Ensure replies are visible
      setRepliesVisibility((prev) => ({ ...prev, [commentId]: true }));
      // Reset new reply flag if it was set
      if (newReplyPosted) setNewReplyPosted(false);
    } catch (error) {
      console.error("Error fetching replies:", error);
    }
  }

  // const handleCommentSubmit = async (postId, e) => {
  //   if (e) e.preventDefault(); // This checks if e is defined and calls preventDefault on it
  //   const commentText = commentInputs[postId] || "";
  //   console.log("commentText", commentText);
  //   if (!commentText.trim()) return; // Don't submit empty comments
  //   // Create the comment object to send to the server
  //   const postData = {
  //     post_id: postId,
  //     parent_comment_id: null,
  //     public_id: postLike.public_id,
  //     comment_text: commentText,
  //   };
  //   console.log("postData", postData);
  //   try {
  //     await axios.post(`${Config.apiBaseUrl}posts/comments`, postData);
  //     setCommentInputs((prevComments) => ({ ...prevComments, [postId]: "" }));
  //     fetchCommentsByPostId(postId);

  //     // Fetch new comments to update the comment count
  //     const { data } = await axios.get(
  //       `${Config.apiBaseUrl}posts/comments/${postId}`
  //     );
  //     const newCommentsCount = data.data.length;

  //     setImageData((prevData) =>
  //       prevData.map((post) =>
  //         post.post_id === postId
  //           ? { ...post, comments_count: post.comments_count + 1 }
  //           : post
  //       )
  //     );

  //     if (selectedImage && selectedImage.post_id === postId) {
  //       setSelectedImage((prev) => ({
  //         ...prev,
  //         comments_count: newCommentsCount,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error submitting comment:", error);
  //   }
  // };
  // const handleCommentSubmit = async (postId, e) => {
  //   e.preventDefault();
  //   const commentText = commentInputs[postId] || "";
  //   if (!commentText.trim()) return;

  //   const postData = {
  //     post_id: postId,
  //     parent_comment_id: null,
  //     public_id: sessionStorage.getItem("public_id"),
  //     comment_text: commentText,
  //   };

  //   try {
  //     await axios.post(`${Config.apiBaseUrl}posts/comments`, postData);
  //     setCommentInputs((prev) => ({ ...prev, [postId]: "" })); // Clear the input field

  //     // Fetch the updated comments count
  //     const response = await axios.get(
  //       `${Config.apiBaseUrl}posts/comments/${postId}`
  //     );
  //     const newCommentsCount = response.data.data.length;

  //     // Update the imageData and selectedImage in one go
  //     setImageData((currentImageData) => {
  //       const updatedImageData = currentImageData.map((image) => {
  //         if (image.post_id === postId) {
  //           return { ...image, comments_count: newCommentsCount };
  //         }
  //         return image;
  //       });

  //       // Check if the selectedImage needs to be updated
  //       if (selectedImage && selectedImage.post_id === postId) {
  //         setSelectedImage((prev) => ({
  //           ...prev,
  //           comments_count: newCommentsCount,
  //         }));
  //       }

  //       return updatedImageData;
  //     });
  //   } catch (error) {
  //     console.error("Error submitting comment:", error);
  //   }
  // };
  const updateCommentCount = async (postId, newCommentCount) => {
    try {
      const updatedPosts = imageData.map((post) => {
        if (post.post_id === postId) {
          return { ...post, comments_count: newCommentCount };
        }
        return post;
      });
      setImageData(updatedPosts);
      if (selectedImage && selectedImage.post_id === postId) {
        setSelectedImage({ ...selectedImage, comments_count: newCommentCount });
      }
    } catch (error) {
      console.error("Error updating comment count:", error);
    }
  };

  const handleCommentSubmit = async (postId, e) => {
    if (e) e.preventDefault();
    const commentText = commentInputs[postId] || "";
    console.log("commentText", commentText);
    if (!commentText.trim()) return;

    const postData = {
      post_id: postId,
      parent_comment_id: null,
      public_id: postLike.public_id,
      comment_text: commentText,
    };
    console.log("postData", postData);

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}posts/comments`,
        postData
      );
      setCommentInputs((prevComments) => ({ ...prevComments, [postId]: "" }));
      fetchCommentsByPostId(postId);

      const newCommentsCount =
        response.data.comments_count ||
        (imageData.find((image) => image.post_id === postId)?.comments_count ||
          0) + 1;
      updateCommentCount(postId, newCommentsCount);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handlePopupCommentSubmit = async (postId, e) => {
    e.preventDefault();
    const commentText = activeComment
      ? replyText[activeComment]
      : popupCommentInput;
    if (!commentText.trim()) return;

    const postData = {
      post_id: postId,
      parent_comment_id: activeComment,
      public_id: postLike.public_id,
      comment_text: commentText,
    };

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}posts/comments`,
        postData
      );
      if (activeComment) {
        setReplyText((prevTexts) => ({
          ...prevTexts,
          [activeComment]: "",
        }));
      } else {
        setPopupCommentInput(""); // Reset input field
      }
      setActiveComment(null); // Reset active comment after reply submission
      await fetchCommentsByPostId(postId);
      if (!activeComment) {
        const newCommentsCount =
          response.data.comments_count ||
          (selectedImage?.comments_count || 0) + 1;
        updateCommentCount(postId, newCommentsCount);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };
  // const handleKeyPress = (e, postId) => {
  //   if (e.key === "Enter" && !e.shiftKey) {
  //     e.preventDefault(); // Prevent form submission if enter key is pressed
  //     handleCommentSubmit(postId, e);
  //   }
  // };

  const handlePopupCommentChange = (e) => {
    setPopupCommentInput(e.target.value);
  };

  const fetchCommentsByPostId = async (postId) => {
    const userId = sessionStorage.getItem("user_id"); // Retrieve the user ID from session storage
    console.log("User ID being sent:", userId);

    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`,
        {
          params: {
            user_id: userId, // Pass the user ID as a query parameter
          },
        }
      );
      setPostComments((prevComments) => ({
        ...prevComments,
        [postId]: response.data.data,
      }));
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  //  ########################################################################
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [boards, setBoards] = useState([]);
  // const [showLogout, setShowLogout] = useState(false);

  // const togglePopupVisibility = () => {
  //   setShowPopup(!showPopup); // Assuming `showPopup` controls the visibility of the Popup
  // };
  const publicId = sessionStorage.getItem("public_id");
  useEffect(() => {
    async function fetchBoards() {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/user/${publicId}`
      ); //     const data = await response.json();
      console.log("boardsdata", response.data);
      setBoards(response.data.data);
    }

    fetchBoards();
  }, []);

  const togglePopup = (postId) => {
    console.log(
      "postid",
      postId,
      "selectedPostId",
      selectedPostId,
      imageData,
      selectedPostId === postId,
      selectedPostId
    );
    const selectedImageData = contentData.find(
      (image) => image.post_id === postId
    );

    if (selectedPostId === postId) {
      console.log("enterd to close the popup");
      // If the current post is already selected, hide the popup
      setIsPopupVisible(false);
      setSelectedPostId(null); // Reset the selected post ID
      // setSelectedImage(null); // Reset the selected image data
    } else {
      console.log("enterd to open the popup");

      // Show the popup for the new post
      setIsPopupVisible(true);
      setSelectedPostId(postId); // Set the new post ID
      setSelectedImage(selectedImageData); // Reset the selected image data
    }
  };

  // const handleBucketListSuccess = () => {
  //   closePopup(); // Close the post popup on bucket list success
  // };

  // #########################################################################

  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsPopupVisible(false);
        setSelectedPostId(null); // Reset the selected post ID
        closePopup();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".col-2") && !event.target.closest(".col-3")) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCommentLike = async (commentId, postId, isLiked) => {
    const updatedComments = postComments[postId].map((comment) => {
      if (comment.comment_id === commentId) {
        return {
          ...comment,
          liked_by_current_user: !isLiked,
          likes: isLiked ? comment.likes - 1 : comment.likes + 1,
        };
      }
      return comment;
    });

    setPostComments({
      ...postComments,
      [postId]: updatedComments,
    });

    // Prepare data for the API call with the specified format
    const likeData = {
      public_id: sessionStorage.getItem("public_id"),
      like_type_id: commentId,
      like_type: "comment",
    };
    console.log("######################", likeData);
    try {
      await PostLikes(likeData);
      console.log("Like status updated successfully.");
    } catch (error) {
      console.error("Error updating comment like status:", error);
    }
  };

  const toggleRepliesVisibility = (commentId) => {
    setRepliesVisibility((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // Toggle the visibility
    }));
    // setActiveReply(activeReply === null);
  };

  const postImageCost = () => {
    setSelectedImage(null);
    if (isPopupVisible) {
      closePopup();
    }
    handleReplyClick();
    setRepliesVisibility({});
    setPopupCommentInput("");
  };

  const handleCancelReply = () => {
    setActiveReply(null);
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [activeReply]: "",
    }));
    setRepliesVisibility({});
  };

  const handleReplyClick = (commentId) => {
    setActiveComment(commentId);
    setActiveReply(activeReply === commentId ? null : commentId);
    setReplyText("");
    // setRepliesVisibility({});
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setSelectedPostId(null);
  };
  const handlePostClick = (postId) => {
    fetchCommentsByPostId(postId);
    setCommentInputs("");
    if (isPopupVisible) {
      closePopup();
    }
  };

  return (
    <div>
      {renderFilterTag()}
      <div className="">
        {contentData.map((image) => (
          <div
            className="cardmain shadow-lg  mb-3 bg-body rounded"
            key={image.id}
            onClick={() => handlePostClick(image.post_id)}
          >
            <div className="row  mainprofileimg">
              <div className="col-1 d-flex ">
                <img
                  className="roundedImg"
                  src={image.user.user_profile_logo}
                  alt="Card image"
                />
                {/* <span className=""> {image.user.user_name}</span> */}
              </div>
              <div className="col mt-2 post_username">
                {image.user.username}
              </div>
              <div className="col  mt-3" style={{ textAlign: "right" }}>
                <label className="post_time">{image.posted_time}</label>
              </div>
              <div className="col-1 mt-3">{/* <label>...</label> */}</div>
            </div>
            {/* <img
              className="card-img-top"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              src={image.image_url}
              alt="Card image"
              onClick={() => postImage(image)}
            /> */}
            {/* <div
              className="card-img-top-container"
              style={{ position: "relative" }}
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <img
                className="card-img-top"
                src={image.image_url}
                alt="Card image"
                onClick={() => postImage(image)}
              />
              
            </div>
            <div
              className="post_location"
              onClick={() => handleLocationClick(image.location)}
            >
              <img src={locationpointer} alt="locationpointer" />
              {image.location}{" "}
            </div> */}
            {image.post_type === "post" ? (
              <div
                className="card-img-top-container"
                style={{ position: "relative" }}
              >
                {image.image_url.includes("youtube.com") ? (
                  <ReactPlayer
                    url={image.image_url}
                    className="card-img-top"
                    controls
                    onClick={() => postImage(image)}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />
                ) : (
                  <img
                    className="card-img-top"
                    src={image.image_url}
                    alt="Card"
                    onClick={() => postImage(image)}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  />
                )}
                <div
                  onClick={() => handleLocationClick(image.location)}
                  className="post_location"
                  // style={{
                  //   position: "absolute",
                  //   bottom: "8px",
                  //   left: "8px",
                  //   color: "white",
                  //   backgroundColor: "rgba(0, 0, 0, 0.5)",
                  //   padding: "3px 10px",
                  //   borderRadius: "20px",
                  //   fontSize: "0.9em",
                  //   cursor: "pointer",
                  // }}
                >
                  <img
                    src={locationpointer}
                    alt="Location pointer"
                    className="locationpointer"
                  />
                  {image.location}
                </div>
              </div>
            ) : (
              <div
                className=""
                style={{
                  padding: "20px",
                  color: "#FFF",
                  background: "#3a3a3a3a",
                  position: "relative",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "transparent transparent",
                  height: "350px",
                }}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => postImage(image)}
              >
                <h3>{image.title}</h3>
                <div>
                  {image.description.split(/(?=\d+\.\s)/).map((desc, index) => (
                    <p key={index}>{desc}</p>
                  ))}
                </div>
              </div>
            )}
            <div className="card-body mt-2">
              <div className="row   rowcomments">
                <div className="col-12">
                  <div className="row  ">
                    <div className="col-2" onClick={(e) => e.stopPropagation()}>
                      <img
                        className="iconsize"
                        // src={!likedPostss[image.post_id] && image.likes_count === 0 ? TM_likeIcon : likecon}
                        src={likedPostss[image.post_id] ? likecon : TM_likeIcon}
                        onClick={() => {
                          handleLikeClick(image.post_id);
                        }}
                        alt="Like"
                      />
                      <span className="ms-2 countsize">
                        {image.likes_count}
                      </span>
                    </div>
                    <div className="col-2">
                      <img className="TM_Ellipse iconsize" src={TM_Ellipse} />{" "}
                      <span className="ms-2 countsize">
                        {image.comments_count}
                      </span>
                    </div>
                    <div className="col-2  iconcontainer">
                      <img
                        className="TM_bucketlist iconsize"
                        src={TM_bucketlist}
                        onClick={() => togglePopup(image.post_id)}
                        alt="Bucket list icon"
                      />

                      <span className="ms-2 countsize">
                        {image.bucket_list_count}
                      </span>
                      {isPopupVisible && selectedPostId === image.post_id && (
                        <div>
                          <BucketListPopup
                            boards={boards}
                            postId={selectedPostId}
                            selectedImageData={selectedImage}
                            onRefresh={fetchPosts}
                            onClose={closePopup}
                            // onSuccess={handleBucketListSuccess}
                            // toggleBoardModal={toggleBoardModal} // Pass the function as a prop
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-6">
                      {/* <img className="TM_ShareIcon" src={TM_ShareIcon} /> */}
                    </div>
                  </div>
                </div>

                <div style={{ color: "white" }} className="card-text">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                    }}
                  >
                    {/* Conditional rendering of description based on isExpanded */}
                    <p
                      className="card-text"
                      style={{ margin: 0, paddingRight: "10px" }}
                    >
                      {isExpanded
                        ? image.description
                        : `${image.description.slice(0, 100)}...`}
                    </p>

                    {/* Check if image.hash_tags is an array and render each tag with padding */}
                    {Array.isArray(image.hash_tags) ? (
                      image.hash_tags.map((tag, index) => (
                        <span
                          key={index}
                          onClick={() => handleHashtagClick(tag)}
                          style={{
                            marginLeft: index > 0 ? "10px" : "0",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                            color: "#D2C2FF",
                          }}
                        >
                          {tag}
                        </span>
                      ))
                    ) : (
                      <span style={{ whiteSpace: "nowrap" }}>
                        {image.hash_tags}
                      </span>
                    )}

                    {/* Conditional rendering for "read more/read less" link based on description length */}
                    {image.description.length > 100 && (
                      <p
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleText();
                        }}
                        style={{ marginLeft: "10px" }}
                      >
                        {isExpanded ? "read less" : "read more"}
                      </p>
                    )}
                  </div>
                </div>

                <div className="comments mb-3">
                  <input
                    type="text"
                    className=""
                    placeholder="Write a comment..."
                    // value={typeof postComments[image.post_id] === 'string' ? postComments[image.post_id] : ''}
                    value={commentInputs[image?.post_id] || ""}
                    // Use the specific comment for this postId
                    onChange={(e) => handleCommentChange(e, image.post_id)} // Update the specific comment for this postId
                    // onKeyPress={(e) => handleKeyPress(e, image.post_id)}
                  />
                  <img
                    src={TM_Vector19}
                    alt="Submit Icon"
                    className="commentpost"
                    onClick={() => handleCommentSubmit(image.post_id)} // Submit the specific comment for this postId
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* PopupPopularPost  Start */}

      <div
        className="modal fade  model_w1000"
        id="staticBackdrop"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content  modelContent">
            <div className="modal-content-1">
              <div className=" popularContaintpopup ">
                <button
                  data-bs-dismiss="modal"
                  onClick={postImageCost}
                  className="popular_popup_closebutton"
                >
                  X
                </button>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="row  ">
                      <div className="col-2  popupProfileImg d-flex">
                        {/* <img
                          src={Vector47}
                          data-bs-dismiss="modal"
                          onClick={postImageCost}
                          style={{
                            height: "30px",
                            width: "15px",
                            marginTop: "15px",
                            padding: "2px",
                          }}
                        />
                        <img
                          className="popuproundedImg p-2"
                          src={selectedImage?.user.user_profile_logo}
                          alt="Card image"
                        /> */}
                        {/* <div className="profileimg m-1">
                          {userImage ? <img src={userImage} alt="User" /> : ""}
                        </div> */}
                      </div>
                      {/* <div className="col-5 mt-3 " style={{ color: "#fff" }}>
                        {selectedImage?.user.username}
                      </div>
                      <div
                        className="col-3  mt-3"
                        style={{ textAlign: "right" }}
                      >
                        <label className="" style={{ color: "#B2B2B2" }}>
                          {selectedImage?.posted_time}{" "}
                        </label>
                      </div> */}
                    </div>

                    <div
                      className="popupcard-img-top-container  popupcard-img-top-container-2"
                      style={{ position: "relative", display: "inline-block" }} // Ensures the container is properly sized and positioned
                    >
                      <img
                        className="popupcard-img-top ms-2"
                        src={selectedImage?.image_url}
                        alt="Selected Card"
                      />
                      {/* Location Text at the bottom right corner */}
                      <div
                        className="popuplocation-map-kd "
                        onClick={() =>
                          handleLocationClick(selectedImage?.location)
                        }
                        style={{
                          position: "absolute",
                          bottom: "8px",
                          left: "15px",
                          color: "white",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          padding: "5px",
                          borderRadius: "15px",
                          fontSize: "0.9em",
                          cursor: "pointer",
                        }}
                      >
                        <img src={locationpointer} alt="locationpointer" />
                        {selectedImage?.location}{" "}
                        {/* Assuming your selectedImage object includes a location property */}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row   popuprowcomments ">
                        <div className="col-12 mb-3 mt-2">
                          <div className="row ">
                            <div
                              className="col-3"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={
                                  likedPostss[selectedImage?.post_id]
                                    ? likecon
                                    : TM_likeIcon
                                }
                                onClick={() => {
                                  console.log(
                                    "Liked:",
                                    likedPostss[selectedImage?.post_id]
                                  );
                                  handleLikeClick(selectedImage?.post_id, true);
                                }}
                                alt="Like"
                              />

                              <span className="ms-2">
                                {selectedImage?.likes_count}
                              </span>
                            </div>
                            <div className="col-3 ">
                              <img className="TM_Ellipse" src={TM_Ellipse} />
                              <span className="ms-2">
                                {selectedImage?.comments_count}
                              </span>
                            </div>
                            <div className="col-3 iconcontainer">
                              <img
                                className="TM_bucketlist "
                                src={TM_bucketlist}
                                onClick={() =>
                                  togglePopup(selectedImage?.post_id)
                                }
                              />

                              <span className="ms-2">
                                {selectedImage?.bucket_list_count}
                              </span>

                              {isPopupVisible &&
                                selectedPostId === selectedImage?.post_id && (
                                  <div>
                                    <BucketListPopup
                                      boards={boards}
                                      postId={selectedPostId}
                                      selectedImageData={selectedImage}
                                      onRefresh={fetchPosts}
                                      onClose={closePopup}
                                      // onSuccess={handleBucketListSuccess}
                                    />
                                  </div>
                                )}
                            </div>

                            <div className="col-3">
                              {/* <img
                                className="TM_ShareIcon"
                                src={TM_ShareIcon}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* this is for Right side Comment Session */}
                  <div
                    className="col-lg-6 col-md-6 col-sm-12 mt-2 popupprofile-tp"
                    style={{ color: "white" }}
                  >
                    <div className="popupprofile">
                      <img
                        className="profilelogoimage ms-2"
                        src={selectedImage?.user.user_profile_logo}
                        alt="Card image"
                      />
                      {selectedImage?.user.username}
                    </div>
                    <div className="popupDescription">
                      {selectedImage?.description}
                      {Array.isArray(selectedImage?.hash_tags) ? (
                        selectedImage?.hash_tags.map((tag, index) => (
                          <span
                            className="ms-2"
                            key={index}
                            onClick={() => handleHashtagClick(tag)}
                            style={{
                              marginLeft: index > 0 ? "10px" : "0",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              color: "#D2C2FF",
                            }}
                          >
                            {tag}
                          </span>
                        ))
                      ) : (
                        <span style={{ whiteSpace: "nowrap" }}>
                          {selectedImage?.hash_tags}
                        </span>
                      )}
                    </div>

                    <div className="popupcommentscontaint">
                      {selectedImage &&
                      Array.isArray(postComments[selectedImage.post_id]) ? (
                        postComments[selectedImage.post_id].map((comment) => (
                          <div
                            className="comment-row mb-2"
                            key={comment.comment_id}
                          >
                            <div className="d-flex align-items-center">
                              <div className="comment-profile-img">
                                {comment.user_profile_logo ? (
                                  <img
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "50%",
                                    }}
                                    src={comment.user_profile_logo}
                                    alt="Profile"
                                  />
                                ) : (
                                  <div className="placeholder"></div>
                                )}
                              </div>
                              <div className="ms-2">
                                <div className="commentusername mb-1">
                                  {comment.user_name || "UserName"}
                                </div>
                              </div>
                              <div className="commenttimestamp">
                                {comment.time_since}
                              </div>
                            </div>
                            <div className="usercomment mb-1">
                              {comment.comment_text}
                            </div>
                            <div className="replycomment d-flex align-items-center">
                              <button
                                className="btn btn-link p-0 me-3"
                                style={{
                                  textDecoration: "none",
                                  color: "#B2B2B2",
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                }}
                                onClick={() =>
                                  handleReplyClick(comment.comment_id)
                                }
                              >
                                Reply
                              </button>
                              <button
                                className="btn p-0"
                                onClick={() =>
                                  handleCommentLike(
                                    comment.comment_id,
                                    selectedImage.post_id,
                                    comment.liked_by_current_user
                                  )
                                }
                              >
                                <img
                                  src={
                                    comment.liked_by_current_user
                                      ? likecon
                                      : TM_likeIcon
                                  }
                                  style={{ width: "15px" }}
                                  alt="Like"
                                />
                                <span className="comment_likes ms-1">
                                  {comment.likes}
                                </span>
                              </button>
                              {comment.reply_count > 0 && (
                                <button
                                  className="btn btn-link"
                                  style={{
                                    textDecoration: "none",
                                    color: "#fff",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                  }}
                                  onClick={() =>
                                    toggleRepliesVisibility(comment.comment_id)
                                  }
                                >
                                  ({comment.reply_count}) Replies
                                </button>
                              )}
                            </div>
                            {repliesVisibility[comment.comment_id] &&
                              comment.replies && (
                                <div className="replies">
                                  {comment.replies.map((reply) => (
                                    <div
                                      key={reply.comment_id}
                                      className="reply-row align-items-center mb-2"
                                    >
                                      <div className="col-md-2 reply-user-profile d-flex">
                                        <img
                                          className="commemts_profile_logo"
                                          src={reply.user_profile_logo}
                                          alt="Profile"
                                        />
                                        <div className="reply-username">
                                          {reply.user_name}
                                        </div>
                                      </div>
                                      <div className="col-md-10">
                                        <div className="reply-text">
                                          {reply.comment_text}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            {activeReply === comment.comment_id && (
                              <div className="reply-input-container">
                                <textarea
                                  type="text"
                                  className="reply-input" // Ensure your CSS styles this appropriately
                                  value={replyText[comment.comment_id] || ""}
                                  onChange={(e) =>
                                    handleReplyTextChange(
                                      comment.comment_id,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Write a reply..."
                                ></textarea>
                                <div className="reply-buttons">
                                  <button
                                    className="reply-button send"
                                    onClick={(e) =>
                                      handleSendReply(
                                        selectedImage.post_id,
                                        comment.comment_id,
                                        e
                                      )
                                    }
                                  >
                                    Send
                                  </button>
                                  <button
                                    className="reply-button cancel"
                                    onClick={handleCancelReply}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div>No comments to display.</div>
                      )}
                    </div>
                    <div className="popupcomments">
                      <form
                        onSubmit={(e) =>
                          handlePopupCommentSubmit(selectedImage?.post_id, e)
                        }
                      >
                        <input
                          // ref={commentInputRef}
                          type="text"
                          placeholder="Add a comment                              "
                          value={popupCommentInput}
                          onChange={handlePopupCommentChange}
                        />
                        <button type="submit" className="comment-submit">
                          <img src={TM_Vector19} alt="Submit" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularPost;
