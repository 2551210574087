import React, { useState } from "react";
import "./TextEditorToolbar.css";
import B from "./../../../../Assets/Images/B.png";
import I from "./../../../../Assets/Images/I.png";
import U from "./../../../../Assets/Images/U.png";
import Bulletlistsymbol from "./../../../../Assets/Images/Bullet list symbol.png";
import NumberedListsymbol from "./../../../../Assets/Images/Numbered List symbol.png";
import Link from "./../../../../Assets/Images/Hyperlink Symbol.png";

const TextEditorToolbar = ({ textAreaRef }) => {
  const [fontSize, setFontSize] = useState(11); // Starting with a default font size value of 11

  const execCommand = (command, argument = null) => {
    document.execCommand(command, false, argument);
  };

  const handleCommand =
    (command, argument = null) =>
    (e) => {
      e.preventDefault();
      textAreaRef.current.focus();
      execCommand(command, argument);
    };

  const increaseFontSize = () => {
    if (fontSize < 32) {
      const newFontSize = fontSize + 1;
      setFontSize(newFontSize);
      execCommand("fontSize", getHtmlFontSize(newFontSize));
    }
  };

  const decreaseFontSize = () => {
    if (fontSize > 8) {
      const newFontSize = fontSize - 1;
      setFontSize(newFontSize);
      execCommand("fontSize", getHtmlFontSize(newFontSize));
    }
  };

  const changeFontSize = (e) => {
    const newSize = parseInt(e.target.value);
    if (newSize >= 8 && newSize <= 32) {
      setFontSize(newSize);
      execCommand("fontSize", getHtmlFontSize(newSize));
    }
  };

  const getHtmlFontSize = (size) => {
    // Convert font size to HTML size attribute value
    return (size - 3).toString(); // MS Word's font size is 3 points larger than HTML's
  };

  return (
    <div className="newNotePopupToolbar">
      <button
        className="minusbutton"
        onMouseDown={(e) => e.preventDefault()}
        onClick={decreaseFontSize}
      >
        -
      </button>
      <input
       className="Note_edit_toolboar"
       
        value={fontSize}
        onChange={changeFontSize}
        min="8"
        max="32"
      />
      <button
        className="ms-2 minusbutton"
        onMouseDown={(e) => e.preventDefault()}
        onClick={increaseFontSize}
      >
        +
      </button>
      <button onMouseDown={handleCommand("bold")} className="notpadbutton">
        <img src={B} alt="Bold" />
      </button>
      <button onMouseDown={handleCommand("italic")} className="notpadbutton">
        <img src={I} alt="Italic" />
      </button>
      <button onMouseDown={handleCommand("underline")} className="notpadbutton">
        <img src={U} alt="Underline" />
      </button>
      <button
        onMouseDown={handleCommand("insertUnorderedList")}
        className="notpadbutton"
      >
        <img src={Bulletlistsymbol} alt="UnorderLoist" />
      </button>
      <button
        onMouseDown={handleCommand("insertOrderedList")}
        className="notpadbutton"
      >
        <img src={NumberedListsymbol} alt="OrderedLoist" />
      </button>
      <button
        className="notpadbutton"
        onMouseDown={(e) => {
          e.preventDefault();
          const url = prompt("Enter the link here: ", "http://");
          if (url) {
            textAreaRef.current.focus();
            execCommand("createLink", url);
          }
        }}
      >
        <img src={Link} alt="Link" />
      </button>

      {/* Add other buttons as needed */}
    </div>
  );
};

export default TextEditorToolbar;
