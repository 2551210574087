import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Signup from "./Components/Signup/Signup";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

// import Home from "./Components/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landingpage from "./Components/Landingpage/Landingpage";
import BucketList from "./Components/BucketList/BucketList";
import Profile from "./Components/Profile/Profile";
import Home from "./Components/UserHomepage/Home/Home";
import Likes from "./Components/Likes/Likes";
import Settings from "./Components/Settings/Settings";
import Signupprocess from "./Components/Signup/Signup_Process/Signupprocess";
import PublicTripBoards from "./Components/UserHomepage/PopularTrip/PublicTripBoards/PublicTripBoards";
import BoardDetails from "./Components/BucketList/UserBoards/BoardDetails/BoardDetails";
import SeeMorePublicBoards from "./Components/BucketList/PublicBoards/SeeMorePublicBoards";
import PublicAndPopularBoardDetails from "./Components/PublicAndPopularBoardDetails/PublicAndPopularBoardDetails";
import SearchPostandBoards from "./SearchComponent/SearchPostandBoards";
import useScrollToTop from "./ScrollComponent/useScrollToTop";

// import BucketList from "./Components/BucketList/BucketList";

const ScrollToTopController = () => {
  useScrollToTop();
  return null; // This component does not render anything.
};
const App = () => {
  return (
    <div>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastClassName="custom-toast"
        />

        <ScrollToTopController />
        <Routes>
          <Route path="/" element={<Signup />} />
          <Route path="signupprocess" element={<Signupprocess />} />
          <Route path="/" element={<Landingpage />}>
            <Route path="home" element={<Home />} />
            <Route path="bucketlist" element={<BucketList />} />
            <Route path="profile" element={<Profile />} />
            <Route path="likes" element={<Likes />} />
            <Route path="settings" element={<Settings />} />
            <Route path="popularboards" element={<PublicTripBoards />} />

            <Route path="board/:board_id" element={<BoardDetails />} />
            <Route path="publicboards" element={<SeeMorePublicBoards />} />
            <Route
              path="boarddetails/:board_id"
              elements={<PublicAndPopularBoardDetails />}
            />
            <Route path="filtersearch" element={<SearchPostandBoards />} />
          </Route>
          {/* <Route path="filtersearch" element={<SearchPostandBoards />} /> */}
        </Routes>
      </BrowserRouter>
      {/* <SearchPostandBoards /> */}
    </div>
  );
};

export default App;
