// hooks/useGlobalKeyListeners.js
import { useEffect } from "react";

const useGlobalKeyListeners = (closePopups) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closePopups();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [closePopups]);
};

export default useGlobalKeyListeners;
