import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../../../Assets/Config";
import "./EditPopup.css"; // Ensure you update your CSS file accordingly
import { toast } from "react-toastify";

const EditPopup = ({ onClose, boardId, onrefresh }) => {
  const [boardselectedimage, setBoardSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    console.log("Received boardId in EditPopup:", boardId);
    // You can now use boardId to load any specific board info if necessary
  }, [boardId]);

  console.log("onrefresh function:", onrefresh);

  const [createboard, setCreateBoard] = useState({
    board_id: boardId,
    board_name: "",
    is_public: false,
    location: "",
    user_id: Number(sessionStorage.getItem("user_id")),
  });

  const changeHandler = (e) => {
    setCreateBoard({ ...createboard, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("createboard", createboard);

    const formData = new FormData();
    formData.append("board_id", createboard.board_id);
    formData.append("user_id", createboard.user_id);
    formData.append("board_name", createboard.board_name);
    formData.append("image", selectedFile);
    formData.append("location", createboard.location);
    formData.append("is_public", createboard.is_public);

    try {
      const response = await axios.put(`${Config.apiBaseUrl}boards`, formData);
      if (response.status === 200) {
        console.log(response);
        toast.success("Board Updated Successfully");
        onClose();
        onrefresh && typeof onrefresh === "function" && onrefresh(true);
      }
    } catch (error) {
      console.error("Board Update failed", error);
      toast.error("Board Update failed");
    }
  };

  const triggerFileInput = () => {
    document.getElementById("editPopup_fileInput").click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (file && !allowedImageTypes.includes(file.type)) {
      toast.error("Only JPEG, JPG, and PNG files are accepted.");
      return;
    }
    setSelectedFile(file);
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBoardSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      console.log("No image file selected");
    }
  };

  const postImageCancel = (e) => {
    e.preventDefault();
    setBoardSelectedImage(null);
    onClose();
  };

  return (
    <div className="editPopup_overlay" onClick={onClose}>
      <div className="editPopup_content" onClick={(e) => e.stopPropagation()}>
        <form onSubmit={submitHandler}>
          <div className="editPopup_modalHeader">
            <input
              type="text"
              className="editPopup_input"
              placeholder="New board name"
              value={createboard.board_name}
              name="board_name"
              onChange={changeHandler}
            />
          </div>
          <div className="editPopup_modalBody">
            <div
              className="editPopup_imageContainer"
              onClick={triggerFileInput}
            >
              {boardselectedimage ? (
                <>
                  <img
                    src={boardselectedimage}
                    alt="Selected cover"
                    className="editPopup_selectedImage"
                  />
                  <div
                    className="editPopup_overlayText"
                    style={{ color: "#FFF" }}
                  >
                    Click to change
                  </div>
                </>
              ) : (
                <div className="editPopup_uploadText" style={{ color: "#FFF" }}>
                  Upload cover image
                </div>
              )}
              <input
                id="editPopup_fileInput"
                type="file"
                className="editPopup_hidden"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>

            <div className="editPopup_buttons">
              <button
                className="editPopup_cancelButton"
                onClick={postImageCancel}
              >
                Cancel
              </button>
              <button
                className="editPopup_createButton"
                type="submit"
                disabled={!createboard.board_name.trim() || !boardselectedimage}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPopup;
