import React, { useState, useEffect } from "react";
import "./MoreoptionPopup.css"; // Ensure your CSS file is correctly linked
import editoption from "./../../../../Assets/Images/editButton.png";
import addButton from "./../../../../Assets/Images/addButton.png";
import DeleteSymbol from "./../../../../Assets/Images/Delete Symbol.png";
import EditPopup from "./EditPopup"; // Ensure the path is correct
import BoardPublicPopup from "./BoardPublicPopup";
import useGlobalKeyListeners from "../../../../useGlobalKeyListeners";
const MoreoptionPopup = ({
  onClose,
  onMakePublic,
  onDelete,
  boardata,
  position,
  boardId,
  isPublic,
}) => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const handleEditClick = (e) => {
    e.stopPropagation();
    setShowEditPopup(true);
  };
  useEffect(() => {
    console.log("#########################################isPublic", boardata);
    // console.log("Received boardId in moreoption:", boardId);
    // You can now use boardId to load any specific board info if necessary
  }, [boardId]);

  const [showBoardPublicPopup, setShowBoardPublicPopup] = useState(false);

  const handlePublicPrivateClick = () => {
    setShowBoardPublicPopup(true);
  };

  const closeAllPopups = () => {
    onClose(); // Assuming onClose will close the MoreoptionPopup
    setShowEditPopup(false); // Also make sure to close internal popups if they are open
    setShowBoardPublicPopup(false);
  };

  // Use the custom hook
  useGlobalKeyListeners(closeAllPopups);
  return (
    <div className=" edit_popup_container">
      <div className="edit_popup" onClick={(e) => e.stopPropagation()}>
        <div onClick={handleEditClick}>
          <img src={editoption} alt="Edit" />
          <span>Edit</span>
        </div>
        <div onClick={handlePublicPrivateClick}>
          <img src={addButton} alt="Add" />
          <span>{isPublic ? "Make Board Private" : "Make Board Public"}</span>

          {/* {showBoardPublicPopup && (
            <BoardPublicPopup
              onClose={() => setShowBoardPublicPopup(false)}
              boardId={boardId}
              boardata = {boardata}
            />
          )} */}
        </div>

        <div onClick={onDelete}>
          <img src={DeleteSymbol} alt="Delete" />
          <span>Delete</span>
        </div>
      </div>
      {showEditPopup && (
        <EditPopup onClose={() => setShowEditPopup(false)} boardId={boardId} />
      )}

      {showBoardPublicPopup && (
        <BoardPublicPopup
          onClose={() => setShowBoardPublicPopup(false)}
          // boardId={boardId}
          boardata={boardata}
          // isPublic ={isPublic}
        />
      )}
    </div>
  );
};

export default MoreoptionPopup;
