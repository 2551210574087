import React, { useEffect, useState } from "react";
import Config from "../../../Assets/Config"; // Make sure this has the correct API base URL
import axios from "axios";
// import Like from "Assets/Imagees/Like.png";
import Like from "../../../Assets/Images/Like.png";
import bucketlist from "../../../Assets/Images/bucketlist.png";
import { useNavigate } from "react-router-dom";
import locationpointer from "./../../../Assets/Images/locationpointer .png";
import { PostLikes } from "./../../../Assets/PostLikes";
import likecon from "./../../../Assets/Images/likecon.png";
 
import "./PublicBoards.css";
 
const PublicBoards = ({ onPopularPostsData }) => {
  const [boards, setBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [likedPostss, setLikedPosts] = useState({});
 
  useEffect(() => {
    setIsLoading(true);
    setError(null);
 
    const userId = sessionStorage.getItem("user_id");
    const url = `${Config.apiBaseUrl}boards`;
 
    axios
      .get(url, {
        params: {
          user_id: userId,
        },
      })
      .then((response) => {
        console.log("boards data", response.data.data);
        setBoards(response.data.data);
        // Create an object mapping post IDs to their liked status
        const likedPostsObject = response.data.data.reduce((acc, board) => {
          acc[board.board_id] = board.liked_by_current_user;
          return acc;
        }, {});
        setLikedPosts(likedPostsObject);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
 
  if (isLoading) {
    return <div>Loading...</div>;
  }
 
  if (error) {
    return <div>Error: {error.message}</div>;
  }
 
  const handelSeeMore = () => {
    navigate("/publicboards");
  };
  const handleLikeClick = async (boardId) => {
    try {
      const isLiked = !likedPostss[boardId]; // Determine the new liked status
 
      // Update the liked posts state
      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        [boardId]: isLiked,
      }));
 
      // Update the boards state
      setBoards((currentBoards) =>
        currentBoards.map((board) => {
          if (board.board_id === boardId) {
            return {
              ...board,
              liked_by_current_user: isLiked,
              like_count: isLiked ? board.like_count + 1 : board.like_count - 1,
            };
          }
          return board;
        })
      );
 
      // Make the API call to update the like status
      const likeData = {
        like_type_id: boardId,
        like_type: "board",
        public_id: sessionStorage.getItem("public_id"),
      };
      await PostLikes(likeData);
    } catch (error) {
      console.error("Error updating like status: ", error);
    }
  };
 
  // const handleLikeClick = async (boardId) => {
  //   try {
  //     console.log("$$$$$$$$$$$$$ boardId", boardId)
  //     const isLiked = !likedPostss[boardId]; // Determine the new liked status
  //     console.log("$$$$$$$$$$$$$ isLiked", isLiked)
  //     // Update the liked posts state
  //     setLikedPosts((prevLikedPosts) => ({
  //       ...prevLikedPosts,
  //       [boardId]: isLiked,
  //     }));
 
  //     // Update the image data state
  //     setBoards((currentImageData) =>
  //       currentImageData.map((board) => {
  //         if (board.board_id === boardId) {
  //           return {
  //             ...board,
  //             liked: isLiked,
  //             likes_count: board.likes_count + (isLiked ? 1 : -1),
  //           };
  //         }
  //         return board;
  //       })
  //     );
 
  //     // setBoardsData(updatedBoardsData);
 
  //     // // Assuming PostLikes function is adapted for board likes
  //     // await PostLikes({
  //     //   like_type_id: boardId,
  //     //   like_type: "board", // Specifying the like type as "board"
  //     //   public_id: sessionStorage.getItem("public_id"),
  //     // });
 
  //     // Make the API call to update the like status
  //     const likeData = {
  //       like_type_id: boardId,
  //       like_type: "board",
  //       public_id: sessionStorage.getItem("public_id"),
  //     };
  //     await PostLikes(likeData);
  //   } catch (error) {
  //     console.error("Error updating like status: ", error);
  //     // Handle error
  //   }
  // };
 
  // Handler to fetch the board images
  const handleBoardClick = async (boardName) => {
    console.log("Board Name:", boardName);
    console.log(
      "Get board images API:",
      `${Config.apiBaseUrl}hashtags/${encodeURIComponent(boardName)}`
    );
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}hashtags/${encodeURIComponent(boardName)}`
      );
      const popularPostsData = response.data.data;
      onPopularPostsData({ name: boardName, data: popularPostsData });
    } catch (error) {
      console.error("Error fetching popular posts:", error);
    }
  };
 
return (
  <div className="row">
    <div className="d-flex flexWrapItems">
      {boards.length > 0 ? (
        boards.slice(0, 3).map((board, index) => (
          <div key={index}>
            <div className="imageboard">
              <div
                className="image-container"
              >
                {/* Separate onClick handlers for different elements */}
                <img
                  src={board.image_url}
                  alt="Board"
                  className="bimage"
                  onClick={() => handleBoardClick(board.title)}
                />
                <div className="overlay">
                  <div className="top-bar">
                    <img
                      src={board.user.user_profile_logo}
                      alt="User"
                      className="user-logo"
                    />
                    <span className="user-name">{board.user.username}</span>
                    {/* Like button onClick handler */}
                    <span
                      className="like-btn"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent parent click event
                        handleLikeClick(board.board_id);
                      }}
                    >
                      <img
                        src={likedPostss[board.board_id] ? likecon : Like}
                        alt="Like Icon"
                      />
                      <span>{board.like_count}</span>
                    </span>
                    <span className="bucket-list-btn">
                      <img src={bucketlist} alt="alt" />
                      <span>{board.bucket_list_count}</span>
                    </span>
                  </div>
                  {/* Location info */}
                  {board.location && (
                    <div className="location-info ">
                      <img src={locationpointer} alt="location" />
                      {board.location}
                    </div>
                  )}
                  <div className="public_board_title">{board.title}</div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No boards found.</p>
      )}
      {/* 'See More' element */}
      <div className="">
        <div className="imageboard" onClick={handelSeeMore}>
          <div className="blank-card">
            <p>See More</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};
export default PublicBoards;
 