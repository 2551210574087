import React, { useState } from "react";
import newlink from "./../../../../Assets/Images/urlSymbol.png";
import newfile from "./../../../../Assets/Images/noteSymbol.png";
import newnote from "./../../../../Assets/Images/fileSymbol.png";

function FileUploadPopup({
  setShowFileUploadPopup,
  toggleNewFile,
  toggleNotePopup,
  togglePopup,
  showLinkPopup,
  onClickNewLink, 
}) {
  const [selectedFilePost, setSelectedFilePost] = useState(null);
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

  const handleFilePostChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.target.files[0];
    if (file) {
      setSelectedFilePost(file);
      const imageUrl = URL.createObjectURL(file);
      setShowPopup(true); // Show the popup when a file is selected
    }
  };

  // Render the popup with the desired styles

  return (
    <div className="dropdown-content">
      <button
        className="close-popup"
        onClick={() => setShowFileUploadPopup(false)}
      >
        X
      </button>
      <div className="dropdown-item" onClick={toggleNewFile}>
        <img src={newnote} alt="File Icon" />
        <span>New File</span>
      </div>
      <div className="dropdown-item" onClick={toggleNotePopup}>
        <img src={newlink} alt="Note Icon" />
        <span>New Note</span>
      </div>
      <div className="dropdown-item" onClick={() => { togglePopup(); onClickNewLink(); }}>
        <img src={newfile} alt="Link Icon" />
        <span>New Link</span>
      </div>
    </div>
  );
}

export default FileUploadPopup;
