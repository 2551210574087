import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Config from './../Assets/Config';

const LocationSuggestions = ({
  location,
  selectedLocation,
  setSelectedLocation,
  onSuggestionClick,
}) => {
  const [locationSuggestions, setLocationSuggestions] = useState([]);

  const fetchLocationSuggestions = useCallback(async (location) => {
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}search?query=${location}`
      );
      const { cities, countries } = response.data;
      const combinedSuggestions = [...cities, ...countries];
      setLocationSuggestions(combinedSuggestions);
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
    }
  }, []);

  useEffect(() => {
    let delayDebounceFn;

    if (selectedLocation === '') {
      if (location.length > 3) {
        delayDebounceFn = setTimeout(() => {
          fetchLocationSuggestions(location);
        }, 300);
      } else {
        setLocationSuggestions([]);
      }
    }

    return () => clearTimeout(delayDebounceFn);
  }, [location, fetchLocationSuggestions, selectedLocation]);

  const handleSuggestionClick = (suggestion) => {
    setSelectedLocation(suggestion);
    onSuggestionClick(suggestion);
    setLocationSuggestions([]); // Clear the location suggestions
  };

  return (
    <>
      {selectedLocation === '' && locationSuggestions.length > 0 && (
        <div className="location-suggestions">
          {locationSuggestions.map((suggestion, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="location-suggestion"
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </>
  );
};


export default LocationSuggestions;