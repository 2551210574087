import React from "react";
import "./Notification.css";
import Likes from "../../Likes/Likes";

const Notifications = () => {
  return (
    <div>
      <div className="notification-heading">Email Notifaction</div>
      <div>
        <div class="input-box-container">
          <div
            type="text"
            id="email-notification"
            class="input_box form-control"
            value="Likes"
          >
            Likes
          </div>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round "></span>
          </label>
        </div>
        <div class="input-box-container">
          <div
            type="text"
            id="email-notification"
            class="input_box form-control"
            value="comments"
          >
            comments
          </div>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round "></span>
          </label>
        </div>
        <div class="input-box-container">
          <div
            type="text"
            id="email-notification"
            class="input_box form-control"
            value="Saves"
          >
            Saves
          </div>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round "></span>
          </label>
        </div>
      </div>

      <div className="notification-heading">Push Notifaction</div>
      <div>
        <div class="input-box-container">
          <div
            type="text"
            id="email-notification"
            class="input_box form-control"
            value="Likes"
          >
            Likes
          </div>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round "></span>
          </label>
        </div>
        <div class="input-box-container">
          <div
            type="text"
            id="email-notification"
            class="input_box form-control"
            value="comments"
          >
            Comments
          </div>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round "></span>
          </label>
        </div>
        <div class="input-box-container">
          <div
            type="text"
            id="email-notification"
            class="input_box form-control"
            value="Saves"
          >
            Saves
          </div>
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round "></span>
          </label>
        </div>
      </div>

      <div>
        <button className="form-control savebutton">Save</button>
      </div>
    </div>
  );
};

export default Notifications;
