import React, { useState, useEffect, useRef, useCallback } from "react";

import message from "./../../../../../Assets/Images/Ellipse 25.png";
import like from "./../../../../../Assets/Images/Like Icon.png";
import locationPointer from "./../../../../../Assets/Images/locationpointer .png";

import Location from "./../../../../../Assets/Images/locationpointer .png";
import bucketlist from "./../../../../../Assets/Images/bucketlist.png";
import TM_Vector19 from "./../../../../../Assets/Images/Vector 19.png";
import Config from "./../../../../../Assets/Config"; // Assuming this holds your API base URL
import axios from "axios";
import Circle from "./../../../../../Assets/Images/Circle.png";
import "./PostPopupofBoard.css";
import useGlobalKeyListeners from "../../../../../useGlobalKeyListeners";
import { Navigate, useNavigate } from "react-router-dom";
import { useSearch } from "../../../../../Assets/queryprovider";
import { PostLikes } from "../../../../../Assets/PostLikes";
import likecon from "./../../../../../Assets/Images/likecon.png";

import TM_likeIcon from "./../../../../../Assets/Images/Like Icon.png";
import { toast } from "react-toastify";

const PostPopupofBoard = ({
  isPopupVisible,
  popupItem,
  setIsPopupVisible,
  formatDescription,
  onRefresh,
}) => {
  const imageUrl = popupItem.image_url;

  console.log("popupItem .8imageUrl______________________________", popupItem);

  console.log("imageUrl+++++++++++++++++++++++++++++++", popupItem.url);
  const userProfileLogo = popupItem.user_logo || "default-logo.png"; // fallback to a default image if none
  const username = popupItem.user_name || "Unknown User";

  const [loadingComments, setLoadingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [popupCommentInput, setPopupCommentInput] = useState("");
  const [activeComment, setActiveComment] = useState(null); // Track the active comment being replied to
  const commentInputRef = useRef(null); // Ref for focusing the input on reply click
  const [repliesVisibility, setRepliesVisibility] = useState({});
  const [replyText, setReplyText] = useState({});
  const [activeReply, setActiveReply] = useState(null);

  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState([]);
  // const { query, setQuery } = useSearch(""); // Accessing the query value
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const [postLike, setPostLike] = useState({
    like_type_id: "",
    like_type: "post",
    public_id: sessionStorage.getItem("public_id"), // Use sessionStorage to get the public_id value
  });
  const [likedPostss, setLikedPosts] = useState({});
  const { query, setQuery, removeQuery } = useSearch();
  console.log("popupItem", popupItem);
  useEffect(() => {
    if (popupItem && popupItem.post_id) {
      fetchCommentsByPostId(popupItem.post_id);
    }
  }, [popupItem]);
  const fetchPosts = useCallback(async () => {
    try {
      let url = `${Config.apiBaseUrl}posts/popular`;
      const queryString = query
        .map((querys) => encodeURIComponent(querys))
        .join(",");

      if (queryString.trim()) {
        url = `${Config.apiBaseUrl}hashtags/${queryString}`;
      }

      console.log("Fetching posts from:", url);

      const userId = sessionStorage.getItem("user_id");
      const config = {
        method: "get",
        url: url,
      };

      if (url === `${Config.apiBaseUrl}posts/popular`) {
        config.params = { user_id: userId };
      }

      const { data } = await axios(config);
      console.log(
        "##################Fetched data:###########################",
        data
      );

      // Update imageData with the fetched data
      setImageData(data.data || []);

      // Create an object mapping post IDs to their liked status
      const likedPostsObject = data.data.reduce((acc, post) => {
        acc[post.post_id] = post.liked_by_current_user;
        return acc;
      }, {});

      setLikedPosts(likedPostsObject);

      const firstPost = data.data[0];
      const likeTypeId = firstPost ? firstPost.post_id : null;
      console.log("likeTypeId", likeTypeId);
      setPostLike((prevState) => ({ ...prevState, like_type_id: likeTypeId }));
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }, [query]);

  const debouncedFetchPosts = useCallback(debounce(fetchPosts, 500), [
    fetchPosts,
  ]);
  useEffect(() => {
    debouncedFetchPosts();
  }, [debouncedFetchPosts]);

  // Handlers for clicking on location and hashtag
  const handleLocationClick = (location) => {
    navigate("/filtersearch", {
      state: { filterSearch: location, filterFlag: "l" },
    });
    // Pass these to another component or store them as needed
  };

  const handleHashtagClick = (hashtag) => {
    navigate("/filtersearch", {
      state: { filterSearch: hashtag, filterFlag: "h" },
    });
    // Pass these to another component or store them as needed
  };

  console.log("popupItem", popupItem);
  useEffect(() => {
    if (popupItem && popupItem.post_id) {
      fetchCommentsByPostId(popupItem.post_id);
    }
  }, [popupItem]);

  const updateCommentCount = async (postId, newCommentCount) => {
    try {
      const updatedPosts = imageData.map((post) => {
        if (post.post_id === postId) {
          return { ...post, comments_count: newCommentCount };
        }
        return post;
      });
      setImageData(updatedPosts);
      if (selectedImage && selectedImage.post_id === postId) {
        setSelectedImage({ ...selectedImage, comments_count: newCommentCount });
      }
    } catch (error) {
      console.error("Error updating comment count:", error);
    }
  };
  const handlePopupCommentSubmit = async (event) => {
    if (event && event.preventDefault) event.preventDefault();

    const commentText = activeComment
      ? replyText[activeComment]
      : popupCommentInput;

    if (!commentText.trim()) return;

    const postData = {
      post_id: popupItem.post_id,
      parent_comment_id: activeComment || null,
      public_id: sessionStorage.getItem("public_id"),
      comment_text: commentText,
    };

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}posts/comments`,
        postData
      );

      if (activeComment) {
        setReplyText((prevTexts) => ({
          ...prevTexts,
          [activeComment]: "",
        }));
      } else {
        setPopupCommentInput("");
      }
      setActiveComment(null);
      await fetchCommentsByPostId(popupItem.post_id);
      if (!activeComment) {
        const newCommentsCount =
          response.data.comments_count ||
          (selectedImage?.comments_count || 0) + 1;
        updateCommentCount(popupItem.id, newCommentsCount);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const fetchCommentsByPostId = async (postId) => {
    setLoadingComments(true);
    const userId = sessionStorage.getItem("user_id"); // Retrieve the user ID from session storage
    console.log("User ID being sent:", userId);

    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`,
        {
          params: {
            user_id: userId,
          },
        }
      );
      console.log("responseresponseresponseresponseresponse", response);

      setComments(response.data.data);
      setLoadingComments(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setLoadingComments(false);
    }
  };

  // const userProfileLogo = popupItem.user?.logo || "default-logo.png"; // Path to a default logo image
  // const username = popupItem.user?.user_name || "Unknown User";

  const closeAllPopups = () => {
    setIsPopupVisible(false);
  };
  const handleCommentLike = async (commentId, postId, isLiked) => {
    const updatedComments = comments.map((comment) => {
      if (comment.comment_id === commentId) {
        return {
          ...comment,
          liked_by_current_user: !isLiked,
          likes: isLiked ? comment.likes - 1 : comment.likes + 1,
        };
      }
      return comment;
    });

    setComments(updatedComments);

    const likeData = {
      public_id: sessionStorage.getItem("public_id"),
      like_type_id: commentId,
      like_type: "comment",
    };
    console.log("######################", likeData);
    try {
      await PostLikes(likeData);
      console.log("Like status updated successfully.");
    } catch (error) {
      console.error("Error updating comment like status:", error);
    }
  };

  const handleSendReply = (postId, commentId, e) => {
    e.preventDefault();
    const replyTextContent = replyText[commentId];

    if (replyTextContent.trim()) {
      setActiveComment(commentId);
      handlePopupCommentSubmit({
        preventDefault: () => {},
      });
    }
  };

  const toggleRepliesVisibility = (commentId) => {
    setRepliesVisibility((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
    setActiveReply(activeReply === null);
  };

  const handleReplyTextChange = (commentId, text) => {
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [commentId]: text,
    }));
  };
  const handleReplyClick = (commentId) => {
    console.log("handleReplyClick commentId", commentId);
    setActiveComment(commentId);
    setActiveReply(commentId);
  };

  const handleCancelReply = () => {
    setActiveReply(null);
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [activeReply]: "",
    }));
    setRepliesVisibility({});
  };
  const closePopup = () => {
    setIsPopupVisible(false);
    // setSelectedPostId(null);
  };
  const [isPublic, setIsPublic] = useState(true);

  useEffect(() => {
    setIsPublic(popupItem?.is_public);
  }, [popupItem]);

  const toggleBoardVisibility = () => {
    const updatedIsPublic = !popupItem.is_public;
    console.log(
      "################################## isPublic",
      isPublic,
      popupItem?.post_id
    );
    // Prepare the data to be sent to the server
    const formData = new FormData();
    formData.append("post_id", popupItem.post_id);
    formData.append("title", popupItem.title); // Ensure to append title
    formData.append("description", popupItem.description); // Ensure to append description
    formData.append("link", popupItem.link); // Ensure to append link
    formData.append("post_type", popupItem.post_type); // Ensure to append post type
    formData.append("location", popupItem.location); // Ensure to append location
    formData.append("is_public", updatedIsPublic);

    axios
      .put(`${Config.apiBaseUrl}posts`, formData)
      .then((response) => {
        setIsPublic(updatedIsPublic);

        console.log(
          "################################## updatedIsPublice",
          isPublic
        );

        console.log(
          `Board has been made ${updatedIsPublic ? "public" : "private"}.`
        );
        toast.info(
          `This post has been marked as ${
            updatedIsPublic ? "public" : "private"
          }.`
        );
      })
      .catch((error) => {
        setIsPublic(isPublic);

        console.error("Error updating post visibility:", error);
        toast.error("Failed to update post visibility.");
      });
  };
  useGlobalKeyListeners(closeAllPopups);
  return (
    isPopupVisible && (
      <div className="popupoverlay">
        <div className="popupcontainer" onClick={(e) => e.stopPropagation()}>
          <button
            className="close-popup"
            onClick={() => setIsPopupVisible(false)}
          >
            X
          </button>
          {/* <div className="popupuserinfo">
            <img src={userProfileLogo} alt="Content" className="popupimage" />
            <span className="popup-username">{username}</span>
          </div> */}

          <div className="content-container">
            {popupItem.post_type === "note" ? (
              <div className="popup-note" style={{ color: "white" }}>
                <p className="popuptitle"> {popupItem.title}</p>
                <span> {popupItem.description}</span>
              </div>
            ) : null}
          </div>

          {popupItem.post_type !== "note" &&
          popupItem.url &&
          popupItem.url.endsWith(".mp4") ? (
            <video
              controls
              className="popup-video"
              style={{ width: "500px", height: "500px" }}
            >
              <source src={popupItem.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : null}

          {popupItem.post_type !== "note" &&
            !popupItem.url.endsWith(".mp4") && (
              <img
                src={popupItem.url}
                alt="Content"
                className="popup-image-2"
                // style={{ width: '500px', height: '540px', borderRadius: '25px' }}
              />
            )}

          {popupItem.post_type !== "note" && (
            <div
              className="locationtext"
              onClick={() => handleLocationClick(popupItem.location)}
            >
              <img src={Location} alt="Location" />
              {popupItem.location}
            </div>
          )}
          {/* <span >{popupItem.location}</span> */}
          {/* {!popupItem.image_url && !popupItem.video_url && (
            <div className="popuptext" style={{ backgroundColor: "#272727" }}>
              <div
                style={{ fontSize: "18px", fontWeight: "600", padding: "10px" }}
              >
                {popupItem.title}
              </div>
              {formatDescription(popupItem.description)}
            </div>
          )} */}
          <div className="action-icons">
            <img src={like} alt="Like" className="action-icon" />
            <span style={{ color: "#FFF" }}> {popupItem.likes_count}</span>
            <img src={message} alt="Message" className="action-icon" />
            <span style={{ color: "#FFF" }}> {comments.length}</span>
            <img src={bucketlist} alt="Bucketlist" className="action-icon" />
            <span style={{ color: "#FFF" }}>
              {" "}
              {popupItem.bucket_list_count}
            </span>
          </div>
        </div>
        <div className="vertical-line"></div>
        <div>
          <div className="popup-text-overlay">
            <div className="top-section">
              <div className="profilename">
                <img
                  src={userProfileLogo}
                  alt="User"
                  className="popup-user-logo"
                />
                <span>{username}</span>
              </div>
              {popupItem.post_type !== "note" && (
                <div className="disc1 ">
                  {popupItem.description}

                  <span
                    className="ms-2"
                    onClick={() =>
                      handleHashtagClick(popupItem.hash_tags.join(" "))
                    }
                    style={{
                      cursor: "pointer",
                      color: "#D2C2FF",
                    }}
                  >
                    {popupItem.hash_tags.join(" ")}
                  </span>
                </div>
              )}
              <div className="horizonta-line"></div>
              {/* <div className="commentbox">
                {loadingComments ? (
                  <p>Loading comments...</p>
                ) : comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <div key={index} className="comment">
                      <div className="profilename">
                        {comment.user_profile_logo ? (
                          <img
                            src={comment.user_profile_logo}
                            alt="Profile"
                            className="profilelogo"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "";
                              e.target.classList.add("whiteCircle");
                            }}
                          />
                        ) : (
                          <div className="whiteCircle"></div>
                        )}
                        <span>{comment.user_name || "UserName"}</span>
                        <div className="time">{comment.posted_time}</div>
                      </div>
                      <p>{comment.comment_text}</p>
                      <div className="comments_like">
                      {(comment.user_id !== parseInt(sessionStorage.getItem("user_id")) ||
                          comment.reply_count > 0) && (
                          <button onClick={() => handleReplyClick(comment.comment_id)}>
                            Reply
                          </button>
                        )}
                        <button
                          className="btn p-0"
                          onClick={() =>
                            handleCommentLike(
                              comment.comment_id,
                              popupItem.post_id,
                              comment.liked_by_current_user
                            )
                          }
                        >
                          <img
                            src={
                              comment.liked_by_current_user
                                ? likecon
                                : TM_likeIcon
                            }
                            style={{ width: "15px" }}
                            alt="Like"
                          />
                          <span className="comment_likes ms-1">
                            {comment.likes}
                          </span>
                        </button>
                        {comment.reply_count > 0 && (
                          <button
                            className="btn btn-link"
                            style={{
                              textDecoration: "none",
                              color: "#fff",
                              fontFamily: "Inter",
                              fontSize: "12px",
                            }}
                            onClick={() =>
                              toggleRepliesVisibility(comment.comment_id)
                            }
                          >
                            ({comment.reply_count}) Replies
                          </button>
                        )}
                      </div>
                      {repliesVisibility[comment.comment_id] &&
                        comment.replies && (
                          <div className="replies">
                            {comment.replies.map((reply) => (
                              <div
                                key={reply.comment_id}
                                className="reply-row align-items-center mb-2"
                              >
                                <div className="col-md-2 reply-user-profile d-flex">
                                  <img
                                    className="commemts_profile_logo"
                                    src={reply.user_profile_logo}
                                    alt="Profile"
                                  />
                                  <div className="reply-username">
                                    {reply.user_name}
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="reply-text">
                                    {reply.comment_text}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {activeReply === comment.comment_id && (
                        <div className="reply-input-container">
                          <input
                            type="text"
                            className="reply-input"
                            value={replyText[comment.comment_id] || ""}
                            onChange={(e) =>
                              handleReplyTextChange(
                                comment.comment_id,
                                e.target.value
                              )
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                handleSendReply(popupItem.post_id, comment.comment_id, e);
                              }
                            }}
                            placeholder="Write a reply..."
                          />
                          <div className="reply-buttons">
                            <button
                              className="reply-button send"
                              onClick={(e) =>
                                handleSendReply(
                                  popupItem.post_id ,
                                  comment.comment_id,
                                  e
                                )
                              }
                            >
                              Send
                            </button>
                            <button
                              className="reply-button cancel"
                              onClick={handleCancelReply}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            </div> */}
              <div className="commentbox">
                {loadingComments ? (
                  <p>Loading comments...</p>
                ) : comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <div key={index} className="comment">
                      <div className="profilename">
                        {comment.user_profile_logo ? (
                          <img
                            src={comment.user_profile_logo}
                            alt="Profile"
                            className=" profilelogo profilelogo1"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "";
                              e.target.classList.add("whiteCircle");
                            }}
                          />
                        ) : (
                          <div className="whiteCircle"></div>
                        )}
                        <span>{comment.user_name || "UserName"}</span>
                        <div className="time">{comment.posted_time}</div>
                      </div>
                      <p>{comment.comment_text}</p>
                      <div className="comments_like">
                        {(comment.user_id !==
                          parseInt(sessionStorage.getItem("user_id")) ||
                          comment.reply_count > 0) && (
                          <span
                            onClick={() => handleReplyClick(comment.comment_id)}
                          >
                            Reply
                          </span>
                        )}
                        <button
                          className="btn p-0"
                          onClick={() =>
                            handleCommentLike(
                              comment.comment_id,
                              popupItem.post_id,
                              comment.liked_by_current_user
                            )
                          }
                        >
                          <img
                            src={
                              comment.liked_by_current_user
                                ? likecon
                                : TM_likeIcon
                            }
                            style={{ width: "15px" }}
                            alt="Like"
                          />
                          <span className="comment_likes ms-1">
                            {comment.likes}
                          </span>
                        </button>
                        {comment.reply_count > 0 && (
                          <button
                            className="btn btn-link rplies"
                            // style={{
                            //   textDecoration: "none",
                            //   color: "#fff",
                            //   fontFamily: "Inter",
                            //   fontSize: "12px",
                            // }}
                            onClick={() =>
                              toggleRepliesVisibility(comment.comment_id)
                            }
                          >
                            ({comment.reply_count}) Replies
                          </button>
                        )}
                      </div>
                      {repliesVisibility[comment.comment_id] &&
                        comment.replies && (
                          <div className="replies">
                            {comment.replies.map((reply) => (
                              <div
                                key={reply.comment_id}
                                className="reply-row align-items-center mb-2"
                              >
                                <div className="col-md-2 reply-user-profile d-flex">
                                  <img
                                    className="commemts_profile_logo"
                                    src={reply.user_profile_logo}
                                    alt="Profile"
                                  />
                                  <div className="reply-username">
                                    {reply.user_name}
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <div className="reply-text">
                                    {reply.comment_text}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      {activeReply === comment.comment_id && (
                        <div className="reply-input-container">
                          <textarea
                            type="text"
                            className="reply-input"
                            value={replyText[comment.comment_id] || ""}
                            onChange={(e) =>
                              handleReplyTextChange(
                                comment.comment_id,
                                e.target.value
                              )
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                handleSendReply(
                                  popupItem.post_id,
                                  comment.comment_id,
                                  e
                                );
                              }
                            }}
                            placeholder="Write a reply..."
                          ></textarea>
                          <div className="reply-buttons">
                            <button
                              className="reply-button send"
                              onClick={(e) =>
                                handleSendReply(
                                  popupItem.post_id,
                                  comment.comment_id,
                                  e
                                )
                              }
                            >
                              Send
                            </button>
                            <button
                              className="reply-button cancel"
                              onClick={handleCancelReply}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            </div>

            <div className="input-section">
              <div className="horizonta-line"></div>
              <form onSubmit={handlePopupCommentSubmit}>
                <input
                  type="text"
                  placeholder="Add a comment"
                  value={popupCommentInput}
                  onChange={(e) => setPopupCommentInput(e.target.value)}
                  className="commentinput"
                  ref={commentInputRef}
                />
                <button type="submit" className="comment-submit m-3">
                  <img src={TM_Vector19} alt="Submit" />
                </button>
              </form>
            </div>
          </div>
        </div>

        <button
          onClick={toggleBoardVisibility}
          className="toggle-visibility-button"
          // style={{
          //   position: "absolute",

          //   bottom: "10px",
          //   left: "339px",

          //   backgroundColor: "blue",
          //   color: "white",
          //   border: "none",
          //   borderRadius: "5px",
          //   padding: "10px 20px",
          //   cursor: "pointer",
          // }}
        >
          {isPublic ? "Make Post Private" : "Make Post Public"}
        </button>
      </div>
    )
  );
};

export default PostPopupofBoard;
