import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSearch } from "../../../../Assets/queryprovider";
import { PostLikes } from "../../../../Assets/PostLikes";
import PopularPost from "../../PopularPosts/PopularPost";
import Config from "../../../../Assets/Config";
import "./PublicTripBoards.css";
import like from "./../../../../Assets/Images/Like.png";
import bucketList from "../../../../Assets/Images/bucketlist.png";
import Location from "./../../../../Assets/Images/loaction_pointer.png";
import PopularAndTrending from "../../Popular_Trending/PopularAndTrending";
import likecon from "./../../../../Assets/Images/likecon.png";
import Backbutton from "./../../../../Assets/Images/Backbutton.png";

const PublicTripBoards = () => {
  const [likedBoards, setLikedBoards] = useState({});
  const [boardsData, setBoardsData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [boardName, setBoardName] = useState(""); // Local state for board name
  const [showPopularPost, setShowPopularPost] = useState(false);
  const [popularPostsData, setPopularPostsData] = useState(null);

  const {
    posts,
    setPosts,
    query,
    setQuery,
    boardPostsName,
    setBoardPostsName,
    setboardname,
  } = useSearch();

  const navigate = useNavigate();

  const handleLocationClick = (location) => {
    navigate("/filtersearch", {
      state: { filterSearch: location, filterFlag: "l" },
    });
  };

  const handleHashtagClick = (hashtag) => {
    navigate("/filtersearch", {
      state: { filterSearch: hashtag, filterFlag: "h" },
    });
  };

  const [likedPostss, setLikedPosts] = useState({});
  const handleLikeBoard = async (boardId) => {
    console.log("######################3", boardId);
    try {
      const isLiked = !likedPostss[boardId]; // Determine the new liked status

      // Update the liked posts state
      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        [boardId]: isLiked,
      }));

      // Update the boards state
      setBoardsData((currentBoards) =>
        currentBoards.map((board) => {
          if (board.board_id === boardId) {
            return {
              ...board,
              liked_by_current_user: isLiked,
              like_count: isLiked ? board.like_count + 1 : board.like_count - 1,
            };
          }
          return board;
        })
      );

      // Make the API call to update the like status
      const likeData = {
        like_type_id: boardId,
        like_type: "board",
        public_id: sessionStorage.getItem("public_id"),
      };
      await PostLikes(likeData);
    } catch (error) {
      console.error("Error updating like status: ", error);
    }
  };

  useEffect(() => {
    const fetchBoardsData = () => {
      axios
        .get(`${Config.apiBaseUrl}boards/popular`, {
          params: {
            user_id: Number(sessionStorage.getItem("user_id")),
          },
        })
        .then((res) => {
          setBoardsData(res.data.data);
          // Create an object mapping post IDs to their liked status
          const likedPostsObject = res.data.data.reduce((acc, board) => {
            acc[board.board_id] = board.liked_by_current_user;
            return acc;
          }, {});
          setLikedPosts(likedPostsObject);
          console.log("res.data.data", res.data.data);
        })
        .catch((error) => {
          console.error("Error fetching boards data:", error);
        });
    };

    fetchBoardsData();
  }, []);

  const navigatePopularBordPosts = async (board_id, board_name) => {
    console.log(`Navigating to board with ID: ${board_id}`);

    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/board/${board_id}`
      );
      const boardPostsData = response.data.data;
      if (!boardPostsData || boardPostsData.length === 0) {
        setPopupMessage(
          `No board items available for Board Name: ${board_name}`
        );
        setShowPopup(true);
      } else {
        setBoardName(board_name); // Set the local state
        setPopularPostsData(boardPostsData);
        setShowPopularPost(true);
        window.scroll(0, 0);
      }
    } catch (error) {
      console.error("Error fetching board posts:", error);
      setPopupMessage("Error fetching data. Please try again later.");
      setShowPopup(true);
    }
  };

  return (
    <div className="homebody ">
      {showPopup && (
        <div className="popup-overlay2">
          <div className="popup-content2">
            <button
              className="close-button"
              onClick={() => setShowPopup(false)}
            >
              &times;
            </button>

            {popupMessage}
          </div>
        </div>
      )}

      {showPopularPost ? (
        <div>
          <div className="d-flex">
            <span
              onClick={() => setShowPopularPost(false)}
              style={{ fontSize: "24px" }}
            >
              <img src={Backbutton} alt="backbutton" />
            </span>
            <h2 style={{ color: "#FFF" }} className="ms-3">
              {boardName}
            </h2>
          </div>
          <PopularPost
            hashtagData={{
              data: popularPostsData,
              name: "board",
            }}
          />
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="container-1">
              <h2 className="trip_board_tittle">Popular Trip Boards</h2>
              <div className="Filter_searchbtn">
                <input type="text" placeholder="Filter:" />
              </div>

              {boardsData.map((board, index) => (
                <div className="Popular_boards_box" key={index}>
                  <div className="profile-info">
                    <div className="profile-pic">
                      {board.user.user_profile_logo ? (
                        <img src={board.user.user_profile_logo} alt="Profile" />
                      ) : (
                        <div className="default_profileimage"></div>
                      )}
                    </div>
                    <div className="username">
                      {board.user.username || "Username"}
                    </div>
                    {board.location ? (
                      <div
                        className="location"
                        onClick={() => handleLocationClick(board.location)}
                      >
                        <img src={Location} alt="Location" /> &ensp;{" "}
                        {board.location}
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                  <div
                    className="board-image"
                    onClick={() =>
                      navigatePopularBordPosts(board.board_id, board.title)
                    }
                  >
                    <img src={board.image_url} alt="Board" />
                  </div>
                  <div className="overlay-board">
                    <div
                      className="icon-container"
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent parent click event
                        handleLikeBoard(board.board_id);
                      }}
                    >
                      <img
                        src={likedPostss[board.board_id] ? likecon : like}
                        alt="Like icon"
                        className="like-icon"
                      />
                      <span className="count">{board.like_count}</span>
                    </div>
                    <div className="icon-container">
                      <img
                        src={bucketList}
                        alt="Bucket List"
                        className="bucket-icon"
                      />
                      <span className="count">{board.bucket_list_count}</span>
                    </div>
                  </div>
                  <div className="board-info">
                    <div className="public-board-name">{board.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="rightnav">
              <PopularAndTrending />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PublicTripBoards;
