import React, { useState, useEffect } from "react";
import "./PostFilePopup.css";
import location from "./../../../../Assets/Images/loaction_pointer.png";
import Delete from "./../../../../Assets/Images/Delete Symbol.png";
import PlusIcon from "./../../../../Assets/Images/Add to board symbol .png";
import roundlogo from "./../../../../Assets/Images/roundlogo.png";
import Circle from "./../../../../Assets/Images/Circle.png";
import Config from "../../../../Assets/Config";
import axios from "axios";
import addButton from "./../../../../Assets/Images/addButton.png";
import { Title } from "@mui/icons-material";
import LocationSuggestions from "../../../../Location/LocationSuggestions";
import { toast } from "react-toastify";

function PostFilePopup({
  onClose,
  showFilePostPopup,
  setShowFilePostPopup,
  userProfile,
  username,
  userLocation,
  handleResetSelectedPostFile,
  onSaveToBucketlist,
  onRefresh,
  flag,
  selectedBoardId,
}) {
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState([]);
  const [descriptionInput, setDescriptionInput] = useState("");
  const [tagInput, setTagInput] = useState("");
  const [isDescriptionAdded, setIsDescriptionAdded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [locationInput, setLocationInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  // ...

  const handleLocationChange = (e) => {
    setLocationInput(e.target.value);
    if (selectedLocation) {
      setSelectedLocation(""); // Clear selected location if user edits the input
    }
  };

  const handleLocationSelect = (address) => {
    setLocationInput(address);
    setSelectedLocation(address);
  };

  const resetPopupState = () => {
    setTags([]);
    setDescription([]);
    setDescriptionInput("");
    setTagInput("");
    setIsImageSelected(false);
    setIsDescriptionAdded(false);
    setselectedPostFile(null);
    setFilePreviewUrl(null);
    setBoardBackgroundImage(null);
    setBoardBackgroundImagePreview(null);
    setIsNewBoardPopupVisible(false);
    setIsCreateNewBoardPopupVisible(false);
    setCreateBoard({
      board_name: "",
      is_public: false,
      location: selectedLocation,
      post_type: "post",
      user_id: Number(sessionStorage.getItem("user_id")),
    });
  };

  const handleSaveToBucketlist = async () => {
    const descriptionText = description[0]?.text || "";
    const formData = new FormData();
    formData.append("user_id", sessionStorage.getItem("user_id"));
    formData.append("description", descriptionText);
    formData.append("location", selectedLocation);
    formData.append("post_type", "post");
    tags.forEach((tag) => {
      formData.append("hash_tags[]", `#${tag}`);
    });
    if (selectedFile) {
      formData.append("image", selectedFile);
    }
    try {
      const response = await axios.post(`${Config.apiBaseUrl}posts`, formData);
      if (response.status === 200) {
        console.log("Post added Successfully");
        if (!flag) {
          toast.success("Post added successfully");
        }
        if (flag === "board") {
          const postId = response.data.data.post_id;
          const boardId = selectedBoardId;
          const userId = sessionStorage.getItem("user_id");
          const boardItemData = {
            board_id: boardId,
            post_id: postId,
            user_id: userId,
          };
          const boardItemResponse = await axios.post(
            `${Config.apiBaseUrl}boarditems`,
            boardItemData
          );
          if (boardItemResponse.status === 201) {
            toast.success("Post added to board successfully");
          } else {
            console.error("Failed to add post to board");
          }
        }
        resetPopupState();
        setShowFilePostPopup(false);
        onRefresh();
      } else {
        toast.error("Failed to save post");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while saving the post.");
    }
  };

  const [isNewBoardPopupVisible, setIsNewBoardPopupVisible] = useState(false);
  const [isCreateNewBoardPopupVisible, setIsCreateNewBoardPopupVisible] =
    useState(false);

  const [createBoard, setCreateBoard] = useState({
    board_name: "",
    is_public: false,
    location: selectedLocation,
    post_type: "post",
    user_id: Number(sessionStorage.getItem("user_id")),
  });
  const toggleCreateNewBoardPopup = () => {
    setIsCreateNewBoardPopupVisible((prev) => !prev);
  };

  const [selectedPostFile, setselectedPostFile] = useState(null); // State to store the actual file object
  const [filePreviewUrl, setFilePreviewUrl] = useState(null); // State to store the Blob URL for preview
  const [isImageSelected, setIsImageSelected] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (file && !allowedImageTypes.includes(file.type)) {
      toast.error("Only JPEG, JPG, and PNG files are accepted.");
      return;
    }
    if (file && file.type.startsWith("image/")) {
      setSelectedFile(file);
      setIsImageSelected(true);
      const previewUrl = URL.createObjectURL(file);
      setFilePreviewUrl(previewUrl);
    } else {
      setSelectedFile(null);
      setIsImageSelected(false);
      setFilePreviewUrl(null);
    }
  };

  const [boardBackgroundImage, setBoardBackgroundImage] = useState(null);
  const [isboardBackgroundImage, setisboardBackgroundImage] = useState(false);
  const [boardBackgroundImagePreview, setBoardBackgroundImagePreview] =
    useState(null);

  const handleBoardImageChange = (e) => {
    const file = e.target.files[0];
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (file && !allowedImageTypes.includes(file.type)) {
      toast.error("Only JPEG, JPG, and PNG files are accepted.");
      return;
    }
    if (file && file.type.startsWith("image/")) {
      setBoardBackgroundImage(file);
      setisboardBackgroundImage(true);
      const previewUrl = URL.createObjectURL(file);
      setBoardBackgroundImagePreview(previewUrl);
    } else {
      setBoardBackgroundImage(null);
      setBoardBackgroundImagePreview(null);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("editPopup_fileInput").click();
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const descriptionText = description[0].text;
    // Step 1: Submit the post
    const postFormData = new FormData();
    postFormData.append("title", "");
    postFormData.append("description", descriptionText);
    postFormData.append("location", selectedLocation);
    postFormData.append("post_type", createBoard.post_type);
    postFormData.append("user_id", createBoard.user_id);
    if (selectedPostFile) postFormData.append("image", selectedPostFile);
    try {
      const postResponse = await axios.post(
        `${Config.apiBaseUrl}posts`,
        postFormData
      );
      if (postResponse.status === 200) {
        const postId = postResponse.data.data.post_id;
        const boardFormData = new FormData();
        boardFormData.append("board_name", createBoard.board_name);
        boardFormData.append("user_id", createBoard.user_id);
        if (boardBackgroundImage)
          boardFormData.append("image", boardBackgroundImage);
        boardFormData.append("is_public", createBoard.is_public);
        boardFormData.append("location", createBoard.location);
        const boardResponse = await axios.post(
          `${Config.apiBaseUrl}boards`,
          boardFormData
        );
        if (boardResponse.status === 200) {
          const boardId = boardResponse.data.data.board_id;
          const postBody = {
            board_id: boardId,
            post_id: postId,
            user_id: sessionStorage.getItem("user_id"),
          };
          try {
            const response = await axios.post(
              `${Config.apiBaseUrl}boarditems`,
              postBody
            );
            toast.success("Post added to board successfully!");
            setShowFilePostPopup(false);
            resetPopupState();
            onRefresh();
          } catch (error) {
            toast.error("Failed to add post to board");
          }
        } else {
          toast.error("Failed to create board.");
        }
      } else {
        toast.error("Failed to save post.");
      }
    } catch (error) {
      toast.error("An error occurred while saving the post.");
    }
  };

  const [boardSelectedImage, setBoardSelectedImage] = useState(null);

  const handleClosePopup = (e) => {
    e?.stopPropagation();
    setShowFilePostPopup(false);
    resetPopupState();
  };

  const toggleNewBoardPopup = () => {
    setIsNewBoardPopupVisible(!isNewBoardPopupVisible);
  };

  const handleOuterClick = (e) => {
    onClose(e);
    handleClosePopup(e);
  };

  const [boardNotePosts, setBoardNotePosts] = useState([]);
  const publicId = sessionStorage.getItem("public_id");
  useEffect(() => {
    axios
      .get(`${Config.apiBaseUrl}bucketlist/boards/${publicId}`)
      .then((res) => {
        if (res.data.data.boards) {
          setBoardNotePosts(res.data.data.boards);
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const handleDescriptionSubmit = (e) => {
    if (e.key === "Enter" && descriptionInput.trim() !== "") {
      setDescription([
        ...description,
        { text: descriptionInput, isEditing: false },
      ]);
      setDescriptionInput("");
      setIsDescriptionAdded(true);
    }
  };

  const handleDescriptionKeyDown = (e) => {
    if (e.key === "Enter" && description.trim() !== "") {
      setDescription(description.trim());
      setIsDescriptionAdded(true);
    }
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === "Enter") {
      const newTags = tagInput
        .split(/\s+/)
        .map((tag) => tag.replace("#", ""))
        .filter((tag) => tag.trim() !== "");
      if (newTags.length > 0) {
        setTags([...tags, ...newTags]);
        setTagInput("");
      }
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const toggleEdit = (index) => {
    setDescription(
      description.map((desc, idx) => {
        if (idx === index) {
          return { ...desc, isEditing: !desc.isEditing };
        }
        return desc;
      })
    );
  };

  const updateDescription = (text, index) => {
    setDescription(
      description.map((desc, idx) => {
        if (idx === index) {
          return { ...desc, text };
        }
        return desc;
      })
    );
  };

  const handleBoardClick = async (id) => {
    const PostFormData = new FormData();
    PostFormData.append("title", "");
    const descriptionText = description[0]?.text || "";
    PostFormData.append("description", descriptionText);
    PostFormData.append("location", selectedLocation);
    PostFormData.append("post_type", createBoard.post_type);
    PostFormData.append("user_id", createBoard.user_id);
    if (selectedPostFile) PostFormData.append("image", selectedPostFile);
    tags.forEach((tag) => {
      PostFormData.append("hash_tags[]", `#${tag}`);
    });
    try {
      const noteResponse = await axios.post(
        `${Config.apiBaseUrl}posts`,
        PostFormData
      );
      if (noteResponse.status === 200) {
        const postId = noteResponse.data.data.post_id;
        const postBody = {
          board_id: id,
          post_id: postId,
          user_id: sessionStorage.getItem("user_id"),
        };
        try {
          const response = await axios.post(
            `${Config.apiBaseUrl}boarditems`,
            postBody
          );
          toast.success("Post added to board successfully!");
          resetPopupState();
          onRefresh();
        } catch (error) {
          toast.error("Failed to add post to board");
        }
      }
    } catch (error) {
      toast.error("An error occurred while saving the note.");
    }
  };

  const postImageCancel = (e) => {
    e.preventDefault();
    setBoardSelectedImage(null);
    setCreateBoard({ ...createBoard, board_name: "" });
    setIsCreateNewBoardPopupVisible(false);
  };

  if (!showFilePostPopup) return null;

  const isPostComplete = () => {
    return description.length > 0 && isImageSelected && tags.length > 0;
  };

  const isBoardComplete = () => {
    return createBoard.board_name.trim() !== "" && isboardBackgroundImage;
  };

  return (
    <div className="postFilePopup" onClick={onClose}>
      <button className="close-popup" onClick={handleClosePopup}>
        X
      </button>
      <div className="left-column">
        <div className="profile-section">
          <img
            src={userProfile}
            alt={Circle}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = Circle;
            }}
            className="profile-logo"
          />
          <span className="profile-name">{username}</span>
        </div>
        <div className="postImagecard">
          <div>
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
            />
            <label htmlFor="file-input" className="file-input-label">
              Select Image
            </label>
            {filePreviewUrl && (
              <img
                src={filePreviewUrl}
                alt="Selected"
                className="selected-popup-image"
              />
            )}
          </div>
        </div>
        <div className="newNotePopupActions pt-3">
          <div className="d-flex">
            <button onClick={handleClosePopup} className="action-button ">
              <img src={Delete} alt="Delete" />
              <span>Delete</span>
            </button>
            {flag !== "board" && (
              <button
                className={`action-button add-button ms-5 ${
                  isPostComplete() ? "" : "disabled"
                }`}
                onClick={toggleNewBoardPopup}
                disabled={!isPostComplete()}
              >
                <img src={PlusIcon} alt="Add to Board" />
                <span>Add to Board</span>
              </button>
            )}
            <button className="action-button ms-5 ">
              <img src={roundlogo} alt="Share to Main Feed" />
              <span>Share to main feed</span>
            </button>
          </div>
        </div>
      </div>
      <div className="vertical-line"></div>
      <div className="right-column">
        <div className="current_Location1">
          <input
            type="text"
            value={locationInput}
            onChange={handleLocationChange}
            placeholder="Location"
            className="location_input2 "
          />
          <span className="location_icon ">
            {/* <img src={location} alt="Location Icon" /> */}
          </span>
          <div className="location_suggestions">
            <LocationSuggestions
              location={locationInput}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              onSuggestionClick={handleLocationSelect}
            />
          </div>
        </div>
        {/* <div className="current_Location ">
          <img src={locationPointer} alt="Location" />
          {userLocation}
        </div> */}
        <div className="horizontal-line"></div>
        <div className="description_container">
          {description.map((desc, index) => (
            <div key={index}>
              {desc.isEditing ? (
                <textarea
                  style={{ width: "100%", height: "250px" }}
                  type="text"
                  value={desc.text}
                  onChange={(e) => updateDescription(e.target.value, index)}
                  onBlur={() => toggleEdit(index)}
                  autoFocus
                ></textarea>
              ) : (
                <div onClick={() => toggleEdit(index)}>{desc.text}</div>
              )}
            </div>
          ))}
        </div>
        {!isDescriptionAdded && (
          <textarea
            type="text"
            value={descriptionInput}
            onChange={(e) => setDescriptionInput(e.target.value)}
            onKeyDown={handleDescriptionSubmit}
            placeholder="Add description..."
            className="description-input"
          ></textarea>
        )}
        <div className="tag-input-container ">
          <input
            type="text"
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
            placeholder="# Add tags"
            className="hash_tag_input"
          />
          <div className="tag-container">
            {tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag}
                <button
                  className="remove-tag-button"
                  onClick={() => removeTag(index)}
                >
                  x
                </button>
              </span>
            ))}
          </div>
        </div>
        <div className="horizontal-line"></div>
        <button
          className={`Bucketlist_button ${
            description.length > 0 && tags.length > 0 ? "highlighted" : ""
          }`}
          onClick={handleSaveToBucketlist}
          disabled={!isPostComplete()}
        >
          {flag === "board" ? "Save to Board" : "Save to Bucketlist"}
        </button>
      </div>

      {/* Board Management Popup */}
      {isNewBoardPopupVisible && (
        <div className="boardmanagementpopup">
          <button className="close-popup" onClick={toggleNewBoardPopup}>
            X
          </button>
          <div className="createnewboardactions">
            <button onClick={toggleCreateNewBoardPopup}>
              <img src={addButton} alt="Create New Board" />
              <span>Create New Board</span>
            </button>
          </div>
          <div className="board-list">
            {boardNotePosts.length > 0 ? (
              boardNotePosts.map((board) => (
                <div
                  key={board.id}
                  className="board-item"
                  onClick={() => handleBoardClick(board.id)}
                >
                  {board.name}
                </div>
              ))
            ) : (
              <div>No existing boards found.</div>
            )}
          </div>
        </div>
      )}

      {isCreateNewBoardPopupVisible && (
        <div className="editPopup_overlay" onClick={handleClosePopup}>
          <div
            className="editPopup_content"
            onClick={(e) => e.stopPropagation()}
          >
            <form onSubmit={submitHandler}>
              <div className="editPopup_modalHeader">
                <input
                  type="text"
                  className="editPopup_input"
                  placeholder="New board name"
                  value={createBoard.board_name}
                  name="board_name"
                  onChange={(e) =>
                    setCreateBoard({
                      ...createBoard,
                      board_name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="editPopup_modalBody">
                <div
                  className="editPopup_imageContainer"
                  onClick={() =>
                    document.getElementById("boardImageInput").click()
                  }
                >
                  {boardBackgroundImagePreview ? (
                    <>
                      <img
                        src={boardBackgroundImagePreview}
                        alt="Selected cover"
                        className="editPopup_selectedImage"
                      />
                      <div
                        className="editPopup_overlayText"
                        style={{ color: "#FFF" }}
                      >
                        Click to change
                      </div>
                    </>
                  ) : (
                    <div
                      className="editPopup_uploadText"
                      style={{ color: "#FFF" }}
                    >
                      Upload cover image
                    </div>
                  )}
                  <input
                    id="boardImageInput"
                    type="file"
                    className="editPopup_hidden"
                    accept="image/*"
                    onChange={handleBoardImageChange}
                  />
                </div>

                <div className="editPopup_buttons">
                  <button
                    className="editPopup_cancelButton"
                    onClick={postImageCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className={`editPopup_createButton ${
                      isBoardComplete() ? "" : "disabled"
                    }`}
                    type="submit"
                    disabled={!isBoardComplete()}
                  >
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default PostFilePopup;
