// export default Getboards;
import React, { useState, useEffect } from "react";
import Config from "../../../../Assets/Config";
import Moreoption from "./../../../../Assets/Images/More_options.png";
import Closeicon from "./../../../../Assets/Images/close.png";

import axios from "axios";
import "./Getboards.css";
import MoreoptionPopup from "./MoreoptionPopup";
import EditPopup from "./EditPopup"; // Make sure this path is correct
import { useSearch } from "./../../../../Assets/queryprovider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Getboards = () => {
  const [getBoards, setGetBoards] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false); // State to control the visibility of EditPopup
  const [currentBoard, setCurrentBoard] = useState(null);
  const [currentImage, setCurrentImage] = useState(""); // State to hold the current image URL
  const [welcomeMessage, setWelcomeMessage] = useState(""); // State for the welcome message

  const [refresh, setRefresh] = useState(false);

  const {
    posts,
    setPosts,
    query,
    setQuery,
    boardPostsName,
    setBoardPostsName,
  } = useSearch();

  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const publicId = sessionStorage.getItem("public_id");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${Config.apiBaseUrl}bucketlist/boards/${publicId}`)
      .then((res) => {
        if (res.data.data.boards) {
          console.log("setGetBoards", res.data.data.boards);
          setGetBoards(res.data.data.boards);
        } else if (res.data.data.description) {
          setWelcomeMessage(res.data.data || []); // Set the welcome message if no boards
        }
      })
      .catch((err) => console.error(err));
  }, []);

  const handleBoardClick = (boardname) => {
    axios
      .get(`${Config.apiBaseUrl}search/${encodeURIComponent(boardname)}`)
      .then((res) => {
        console.log(
          `${Config.apiBaseUrl}search/${encodeURIComponent(boardname)}`
        );
        console.log("inside getboards posts", res.data.data);
        const boardData = {
          boardName: boardname,
          data: res.data.data,
        };
        setPosts(boardData); // Pass the fetched posts back to the parent component
      })
      .catch((error) => console.error("Error fetching board posts:", error));
  };

  const handleClosePopup = () => {
    // setShowPopup(false);
    setShowEditPopup(false);
  };

  const handleEdit = () => {
    setShowEditPopup(true); // Open EditPopup
    // Assuming you're setting the currentImage somewhere before calling handleEdit
  };

  // Other handler functions...

  // const handleMoreOptionsClick = (event, board, ind) => {
  //   event.preventDefault();
  //   console.log("board", board);
  //   const element = document.getElementById("popUp" + (ind + 1));

  //   if (event.target.src != Closeicon) {
  //     event.target.src = Closeicon;
  //   } else {
  //     event.target.src = Moreoption;
  //   }

  //   element.classList.toggle("active");
  //   console.log("setCurrentBoard", setCurrentBoard);
  //   setCurrentBoard(board); // Set the current board for actions
  // };

  // const handleMoreOptionsClick = (event, board, ind) => {
  //   event.preventDefault();
  //   const element = document.getElementById("popUp" + (ind + 1));
  //   const allPopups = document.querySelectorAll(".moreOptionsWrapper");

  //   // Iterate over all popups and close them
  //   allPopups.forEach((popup) => {
  //     if (popup.id !== element.id && popup.classList.contains("active")) {
  //       popup.classList.remove("active");
  //       const imgElement = popup.previousSibling.querySelector("img");
  //       if (imgElement && imgElement.src.includes("close.png")) {
  //         imgElement.src = Moreoption; // Reset the icon to Moreoption
  //       }
  //     }
  //   });

  //   // Toggle the clicked popup and the icon
  //   if (element.classList.contains("active")) {
  //     event.target.src = Moreoption;
  //     element.classList.remove("active");
  //   } else {
  //     event.target.src = Closeicon;
  //     element.classList.add("active");
  //   }

  //   setCurrentBoard(board); // Set the current board for actions
  // };

  const handleMoreOptionsClick = (event, board, ind) => {
    event.preventDefault();
    const element = document.getElementById("popUp" + (ind + 1));
    const allPopups = document.querySelectorAll(".moreOptionsWrapper");

    let isCurrentPopupActive = element.classList.contains("active");

    // Close all popups and reset their icons
    allPopups.forEach((popup) => {
      popup.classList.remove("active");
      const imgElement = popup.previousSibling.querySelector("img");
      if (imgElement) {
        imgElement.src = Moreoption; // Reset the icon to Moreoption
      }
    });

    // If the current popup was not active before, open it and change the icon
    if (!isCurrentPopupActive) {
      event.target.src = Closeicon; // Set the icon to Closeicon
      element.classList.add("active");
    } else {
      event.target.src = Moreoption; // The popup is being closed, reset the icon
    }

    setCurrentBoard(board); // Set the current board for actions
  };

  const handleClickOnBoard = (board_id, board_name) => {
    const boarddata = {
      board_name: board_name,
    };
    console.log("board_name", boarddata, board_name);
    setBoardPostsName(boarddata);
    navigate(`/board/${board_id}`); // Navigate to the detailed page
  };
  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const deleteBoard = (boardId) => {
    if (window.confirm("Are you sure you want to delete this board?")) {
      axios
        .delete(`${Config.apiBaseUrl}boards/board/${boardId}`)
        .then(() => {
          const updatedBoards = getBoards.filter(
            (board) => board.id !== boardId
          );
          setGetBoards(updatedBoards);
          handleClosePopup();
          toast.success("Board deleted successfully");
          setRefresh(true);
          // window.location.reload();
        })
        .catch((err) => {
          console.error("Error deleting the board:", err);
        });
    }
  };

  const toggleBoardVisibility = (boardId, is_public) => {
    // Toggle the visibility
    console.log("boardId", boardId, "is_public", is_public);
    const updatedIsPublic = !is_public;
    console.log("updatedIsPublic", updatedIsPublic, "is_public", is_public);
    // Construct the updated board data including all required fields
    const formData = new FormData();
    formData.append("board_id", boardId);
    formData.append("is_public", updatedIsPublic);

    axios
      .put(`${Config.apiBaseUrl}boards`, formData)
      .then(() => {
        // Update local state to reflect the change
        setGetBoards((currentBoards) =>
          currentBoards.map((board) =>
            board.id === boardId
              ? { ...board, is_public: updatedIsPublic }
              : board
          )
        );
        console.log("updatedIsPublic", updatedIsPublic);
        console.log(
          `Board has been made ${updatedIsPublic ? "public" : "private"}.`
        );
        toast.info(`Board has been made ${updatedIsPublic ? "public" : "private"}.`);
        setRefresh(true)
        // window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating board visibility:", error);
        toast.error("Failed to update board visibility.");
        // window.location.reload();
      });
  };
  const refreshBoardList = () => {
    setRefresh(true); // Assuming you use this to trigger a re-fetch or update state that reflects changes
  };
  return (
    <div className="bucketlist_boards">
      <div className=" boards newBoard">
        <button
          className="addNewboard"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop1"
        >
          + Add New Board
        </button>
      </div>

      {getBoards.length > 0 ? (
        getBoards.map((item, id) => (
          <div key={id} className={`boards ${id}`}>
            <img
              onClick={() => handleClickOnBoard(item.id, item.title)}
              className="image_board"
              src={item.image_url}
              alt={`Board ${id}`}
            />

            <span
              className="more-options"
              onClick={(e) => handleMoreOptionsClick(e, item, id)}
            >
              <img src={Moreoption} alt="More options" />
            </span>

            <div id={`popUp${id + 1}`} className={`moreOptionsWrapper`}>
              <MoreoptionPopup
                key={id}
                onClose={handleClosePopup}
                boardata={currentBoard}
                onEdit={() => {
                  setShowEditPopup(true);
                  setCurrentImage(currentBoard?.image_url);
                }}
                onMakePublic={() =>
                  toggleBoardVisibility(
                    currentBoard?.id,
                    currentBoard?.is_public
                  )
                }
                onDelete={() => deleteBoard(currentBoard?.id)}
                boardId={currentBoard?.id}
                isPublic={currentBoard?.is_public}
               
              />
            </div>

            <span
              className="board-name"
              onClick={() => handleBoardClick(item.title)}
            >
              {" "}
              {item.title}{" "}
            </span>

            {showEditPopup && (
              <EditPopup  onClose={handleClosePopup} imageSrc={currentImage} onrefresh = {refreshBoardList} />
            )}
          </div>
        ))
      ) : (
        <div className="bordercard">
          <div className="welcome-message">
            <div>{welcomeMessage.title}</div>
            <p>{welcomeMessage.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Getboards;
