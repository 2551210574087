import React, { useState, useEffect } from "react";
import editoption from "./../../../../Assets/Images/editButton.png";
import addButton from "./../../../../Assets/Images/addButton.png";
import DeleteSymbol from "./../../../../Assets/Images/Delete Symbol.png";
// import EditPopup from "../../UserBoards/GetBoards/EditPopup";

import "./Morepostoption.css";

const Morepostoption = ({
  onClose,
  onMakePublic,
  onDelete,
  onEdit,
  position,
  onAddToBoard,
  boardId,
  postId,
}) => {
  useEffect(() => {
    console.log("Received boardId in Morepostoption:", postId);
  }, [boardId]);

  return (
    <div className="popup-container">
      <div className="popup-body" onClick={(e) => e.stopPropagation()}>
        <div className="option-item" onClick={onEdit}>
          <img src={editoption} alt="Edit" />
          <span> Edit</span>
        </div>
        <div className="option-item" onClick={onAddToBoard}>
          <img src={addButton} alt="Add" />
          <span>Add to Board</span>
        </div>
        <div className="option-item" onClick={onDelete}>
          <img src={DeleteSymbol} alt="Delete" />
          <span>Delete</span>
        </div>
      </div>
    </div>
  );
};

export default Morepostoption;
