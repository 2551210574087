import React, { useRef, useEffect, useState, useCallback } from "react";
import TM_likeIcon from "./../../../Assets/Images/Like Icon.png";
import TM_Ellipse from "./../../../Assets/Images/Ellipse 25.png";
import TM_bucketlist from "./../../../Assets/Images/bucketlist.png";
import TM_ShareIcon from "./../../../Assets/Images/Share Icon.png";
import TM_Vector19 from "./../../../Assets/Images/Vector 19.png";
import likecon from "./../../../Assets/Images/likecon.png";
import Vector47 from "./../../../Assets/Images/Vector 47.png";
import locationpointer from "./../../../Assets/Images/locationpointer .png";
// import BoardCreationModal from "../../BucketList/BoardCreationModal/BoardCreationModal";

import "./RecentPosts.css";
import Config from "./../../../Assets/Config";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useSearch } from "./../../../Assets/queryprovider";
import { PostLikes } from "../../../Assets/PostLikes";
import BucketListPopup from "../../BucketList/MyFiles/Posts/BucketListPopup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const RecentPosts = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const username = sessionStorage.getItem("username");
  const { query, setQuery, removeQuery } = useSearch();
  const [showLogout, setShowLogout] = useState(false);
  const toggleLogoutOption = () => {
    setShowLogout(!showLogout);
  };

  const firstLetter = username ? username.charAt(0).toUpperCase() : "";

  // State to hold the search filter and flag
  const navigate = useNavigate();
  // Handlers for clicking on location and hashtag
  const handleLocationClick = (location) => {
    navigate("/filtersearch", {
      state: { filterSearch: location, filterFlag: "l" },
    });
  };

  const handleHashtagClick = (hashtag) => {
    navigate("/filtersearch", {
      state: { filterSearch: hashtag, filterFlag: "h" },
    });
  };

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };
  const [popuarPost, setRecentPosts] = useState([]);
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageDatarecent, setImageDatarecent] = useState([]);
  // const fetchPosts = useCallback(async () => {
  //   const url = query.trim()
  //     ? `${Config.apiBaseUrl}hashtags${encodeURIComponent(query)}`
  //     : `${Config.apiBaseUrl}posts/recent`;

  //   try {
  //     console.log(url);

  //     const response = await axios.get(url);
  //     console.log(query);
  //     console.log("response.data.data", response.data.data);
  //     setImageDatarecent(response.data.data || []);
  //   } catch (error) {
  //     console.error("Error fetching posts:", error);
  //   }
  // }, [query]);
  const [likedPostss, setLikedPosts] = useState({});
  const fetchPosts = useCallback(async () => {
    // Determine the base URL
    const baseUrl = `${Config.apiBaseUrl}posts/recent`;

    try {
      console.log("recent Fetching posts from URL:", baseUrl);

      // Prepare the request configuration
      const config = {
        method: "get",
        url: baseUrl,
      };

      // Add the user_id as a query parameter
      const userId = sessionStorage.getItem("user_id"); // Assuming the user ID is stored in sessionStorage
      config.params = { user_id: userId };

      const response = await axios(config);
      console.log("recent posts Response data:", response.data.data);

      // Create an object mapping post IDs to their liked status
      const likedPostsObject = response.data.data.reduce((acc, post) => {
        acc[post.post_id] = post.liked_by_current_user; // Map post_id to liked_by_current_user
        return acc;
      }, {});

      setLikedPosts(likedPostsObject); // Update your state with this new mapping
      setImageDatarecent(response.data.data); // Assuming you also want to update some state with the fetched posts
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  }, []);

  const debouncedFetchPosts = useCallback(debounce(fetchPosts, 500), [
    fetchPosts,
  ]);

  useEffect(() => {
    debouncedFetchPosts();
  }, [debouncedFetchPosts]);
  // #########################################################################
  // const [likedPostss, setLikedPosts] = useState({});

  //   const fetchUserLikes = async () => {
  //     const userId = sessionStorage.getItem("user_id");
  //     try {
  //       const response = await axios.get(`${Config.apiBaseUrl}likes/liked-posts/${userId}`);
  //       const likedPosts = response.data;
  //       const likedPostsObject = likedPosts.reduce((acc, post) => {
  //         acc[post.post_id] = true; // Set the post as liked
  //         console.log("acc", acc)
  //         return acc;
  //       }, {});
  //       setLikedPosts(likedPostsObject);
  //     } catch (error) {
  //       console.error("Error fetching user likes:", error);
  //     }
  //   };

  // useEffect(() => {
  //   fetchUserLikes();
  // }, []);

  // #####################
  const handleLikeClick = async (postId, isFromPopup = false) => {
    try {
      const isLiked = !likedPostss[postId]; // Determine the new liked status

      // Update the liked posts state
      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        [postId]: isLiked,
      }));

      // Update the image data state
      setImageDatarecent((currentImageDatarecent) =>
        currentImageDatarecent.map((image) => {
          if (image.post_id === postId) {
            return {
              ...image,
              liked: isLiked,
              likes_count: image.likes_count + (isLiked ? 1 : -1),
            };
          }
          return image;
        })
      );

      // If the like action came from the popup, update the selectedImage state
      if (isFromPopup && selectedImage && selectedImage.post_id === postId) {
        setSelectedImage((prevImage) => ({
          ...prevImage,
          liked: isLiked,
          likes_count: prevImage.likes_count + (isLiked ? 1 : -1),
        }));
      }
      // Make the API call to update the like status
      const likeData = {
        like_type_id: postId,
        like_type: "post",
        public_id: sessionStorage.getItem("public_id"),
      };
      console.log("likeData", likeData);
      await PostLikes(likeData);
    } catch (error) {
      console.error("Error handling like:", error);
    }
  };

  const handleCommentLike = async (commentId, postId, isLiked) => {
    // Update local state for immediate UI feedback
    const updatedComments = postComments[postId].map((comment) => {
      if (comment.comment_id === commentId) {
        return {
          ...comment,
          liked_by_current_user: !isLiked,
          likes: isLiked ? comment.likes - 1 : comment.likes + 1,
        };
      }
      return comment;
    });

    setPostComments({
      ...postComments,
      [postId]: updatedComments,
    });

    // Prepare data for the API call
    const likeData = {
      public_id: sessionStorage.getItem("public_id"),
      like_type_id: commentId,
      like_type: "comment",
    };

    try {
      await PostLikes(likeData); // Assuming PostLikes is a function to toggle likes in the backend
    } catch (error) {
      console.error("Error updating comment like status:", error);
    }
  };

  // ########################################################################
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [boards, setBoards] = useState([]);
  // const [showLogout, setShowLogout] = useState(false);

  // const togglePopupVisibility = () => {
  //   setShowPopup(!showPopup); // Assuming `showPopup` controls the visibility of the Popup
  // };
  const publicId = sessionStorage.getItem("public_id");
  useEffect(() => {
    async function fetchBoards() {
      const response = await axios.get(
        `${Config.apiBaseUrl}boards/user/${publicId}`
      ); //     const data = await response.json();
      console.log("boardsdata", response.data);
      setBoards(response.data.data);
    }

    fetchBoards();
  }, []);

  const togglePopup = (postId) => {
    console.log(
      "postid",
      postId,
      imageDatarecent,
      selectedPostId === postId,
      selectedPostId
    );
    const selectedImageDatarecent = imageDatarecent.find(
      (image) => image.post_id === postId
    );

    if (selectedPostId === postId && isPopupVisible) {
      console.log("entered into stopr popups");
      // If the current post is already selected, hide the popup
      setIsPopupVisible(false);
      setSelectedPostId(null);
    } else {
      console.log("entered into open popups");
      // Show the popup for the new post
      setIsPopupVisible(true);
      setSelectedPostId(postId);
      setSelectedImage(selectedImageDatarecent);
    }
  };

  // ########################################################################
  // Popular Post Code Start

  const postImage = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".col-2") && !event.target.closest(".col-3")) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [commentInputs, setCommentInputs] = useState({});
  // State to keep track of comments fetched from the server
  const [postComments, setPostComments] = useState({});
  const [popupCommentInput, setPopupCommentInput] = useState("");
  const [activeComment, setActiveComment] = useState(null); // Track the active comment being replied to

  const [replies, setReplies] = useState([]);
  const [replyTexts, setReplyTexts] = useState({});
  const [newReplyPosted, setNewReplyPosted] = useState(false);
  const [repliesVisibility, setRepliesVisibility] = useState({});
  const [postLike, setPostLike] = useState({
    like_type_id: "",
    like_type: "post",
    public_id: sessionStorage.getItem("public_id"), // Use sessionStorage to get the public_id value
  });

  // Update the reply click to set the active comment and focus the input
  const commentInputRef = useRef(null); // Ref for focusing the input on reply click

  const handleCommentChange = (e, postId) => {
    const newComment = e.target.value;
    setCommentInputs((prevInputs) => ({
      ...prevInputs,
      [postId]: newComment,
    }));
  };

  const updateCommentCount = async (postId, newCommentCount) => {
    try {
      const updatedPosts = imageDatarecent.map((post) => {
        if (post.post_id === postId) {
          return { ...post, comments_count: newCommentCount };
        }
        return post;
      });
      setImageDatarecent(updatedPosts);
      if (selectedImage && selectedImage.post_id === postId) {
        setSelectedImage({ ...selectedImage, comments_count: newCommentCount });
      }
    } catch (error) {
      console.error("Error updating comment count:", error);
    }
  };

  const handleCommentSubmit = async (postId, e) => {
    if (e) e.preventDefault();
    const commentText = commentInputs[postId] || "";
    console.log("commentText", commentText);
    if (!commentText.trim()) return;

    const postData = {
      post_id: postId,
      parent_comment_id: null,
      public_id: postLike.public_id,
      comment_text: commentText,
    };
    console.log("postData", postData);

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}posts/comments`,
        postData
      );
      setCommentInputs((prevComments) => ({ ...prevComments, [postId]: "" }));
      fetchCommentsByPostId(postId);

      const newCommentsCount =
        response.data.comments_count ||
        (imageDatarecent.find((image) => image.post_id === postId)
          ?.comments_count || 0) + 1;
      updateCommentCount(postId, newCommentsCount);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handlePopupCommentSubmit = async (postId, e) => {
    e.preventDefault();
    const commentText = activeComment
      ? replyText[activeComment]
      : popupCommentInput;
    if (!commentText.trim()) return;

    const postData = {
      post_id: postId,
      parent_comment_id: null,
      public_id: postLike.public_id,
      comment_text: commentText,
    };
    if (activeComment) {
      postData.parent_comment_id = activeComment;
    }
    console.log("replay postData", postData);

    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}posts/comments`,
        postData
      );
      if (activeComment) {
        setReplyText((prevTexts) => ({
          ...prevTexts,
          [activeComment]: "",
        }));
      } else {
        setPopupCommentInput(""); // Reset input field
      }
      setActiveComment(null);

      await fetchCommentsByPostId(postId);
      if (!activeComment) {
        const newCommentsCount =
          response.data.comments_count ||
          (selectedImage?.comments_count || 0) + 1;
        console.log("@@@@@@@@@@@@@@@@@@ newCommentsCount", newCommentsCount);
        updateCommentCount(postId, newCommentsCount);
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleKeyPress = (e, postId) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the form from submitting naturally
      handleCommentSubmit(postId, e);
    }
  };

  const handlePopupCommentChange = (e) => {
    setPopupCommentInput(e.target.value);
  };

  const fetchCommentsByPostId = async (postId) => {
    const userId = sessionStorage.getItem("user_id"); // Retrieve the user ID from session storage
    console.log("User ID being sent:", userId);

    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}posts/comments/${postId}`,
        {
          params: {
            user_id: userId, // Pass the user ID as a query parameter
          },
        }
      );
      setPostComments((prevComments) => ({
        ...prevComments,
        [postId]: response.data.data,
      }));
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  // ########################################################################
  const popupRef = useRef();

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsPopupVisible(false);
        setSelectedPostId(null); // Reset the selected post ID
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".col-2") && !event.target.closest(".col-3")) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [activeReply, setActiveReply] = useState(null);
  const [replyText, setReplyText] = useState({});

  // Function to handle reply text change
  const handleReplyTextChange = (commentId, text) => {
    console.log("@@@@@@@@@@@@@@@@@2 commentId, text", commentId, text);
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [commentId]: text,
    }));
  };

  const handleSendReply = (postId, commentId, e) => {
    e.preventDefault();
    const replyTextContent = replyText[commentId];
    if (replyTextContent.trim()) {
      setActiveComment(commentId);
      handlePopupCommentSubmit(postId, e);
    }
  };

  const toggleRepliesVisibility = (commentId) => {
    setRepliesVisibility((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // Toggle the visibility
    }));
    // setActiveReply(activeReply ===  null);
  };

  const postImageCost = () => {
    setSelectedImage(null);
    if (isPopupVisible) {
      closePopup();
    }
    handleReplyClick();
    setRepliesVisibility({});
    setPopupCommentInput("");
  };

  const handleCancelReply = () => {
    setActiveReply(null);
    setReplyText((prevTexts) => ({
      ...prevTexts,
      [activeReply]: "",
    }));
    setRepliesVisibility({});
  };

  const handleReplyClick = (commentId) => {
    setActiveComment(commentId);
    setActiveReply(activeReply === commentId ? null : commentId);
    setReplyText("");
    // setRepliesVisibility({});
  };

  const closePopup = () => {
    setIsPopupVisible(false);
    setSelectedPostId(null);
  };
  const handlePostClick = (postId) => {
    fetchCommentsByPostId(postId);
    setCommentInputs("");
    if (isPopupVisible) {
      closePopup();
    }
  };

  const [loadingMore, setLoadingMore] = useState(false); // State for tracking loading status
  const handleSeeMoreClick = async () => {
    if (loadingMore) return; // Prevent multiple clicks while loading
    setLoadingMore(true);

    try {
      if (imageDatarecent.length === 0) return;
      const smallestDate = Math.min(
        ...imageDatarecent.map((post) => new Date(post.create_at).getTime())
      );
      console.log(
        "smallestDatesmallestDatesmallestDatesmallestDate",
        smallestDate,
        imageDatarecent
      );
      const userId = sessionStorage.getItem("user_id"); // Get user ID

      const response = await axios.get(`${Config.apiBaseUrl}posts/recent`, {
        params: {
          before: new Date(smallestDate).toISOString(),
          user_id: userId,
        },
      });

      const newPosts = response.data.data;
      if (newPosts.length === 0) {
        toast.info("No more posts available.");
        setLoadingMore(false);
        return;
      }

      setImageDatarecent((prevPosts) => [...prevPosts, ...newPosts]);

      const likedPostsObject = newPosts.reduce((acc, post) => {
        acc[post.post_id] = post.liked_by_current_user;
        return acc;
      }, {});

      setLikedPosts((prevLikedPosts) => ({
        ...prevLikedPosts,
        ...likedPostsObject,
      }));
    } catch (error) {
      console.error("Error fetching more posts:", error);
    }
    setLoadingMore(false);
  };

  return (
    <div>
      {/* {renderFilterTag()} */}
      <div className=" main">
        {imageDatarecent.map((image) => (
          <div
            className="cardmain shadow-lg  mb-3 bg-body rounded"
            key={image.id}
            onClick={() => handlePostClick(image.post_id)}
          >
            <div className="row  mainprofileimg">
              <div className="col-1 d-flex ">
                <img
                  className="roundedImg"
                  src={image.user.user_profile_logo}
                  alt="Card image"
                />

                {/* <span className=""> {image.user.user_name}</span> */}
              </div>
              <div className="col mt-2 post_username">
                {image.user.username}
              </div>
              <div className="col  mt-3" style={{ textAlign: "right" }}>
                <label className="post_time">{image.posted_time}</label>
              </div>
              <div className="col-1 mt-3">{/* <label>...</label> */}</div>
            </div>

            {image.post_type === "post" ? (
              <div
                className="card-img-top-container"
                style={{ position: "relative" }}
              >
                <img
                  className="card-img-top"
                  src={image.image_url}
                  alt="Card"
                  onClick={() => postImage(image)}
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop3"
                />
                <div
                  onClick={() => handleLocationClick(image.location)}
                  className="post_location"
                  // style={{
                  //   position: "absolute",
                  //   bottom: "8px",
                  //   left: "8px",
                  //   color: "white",
                  //   backgroundColor: "rgba(0, 0, 0, 0.5)",
                  //   padding: "3px 10px",
                  //   borderRadius: "20px",
                  //   fontSize: "0.9em",
                  //   cursor: "pointer",
                  // }}
                >
                  <img
                    src={locationpointer}
                    alt="Location pointer"
                    className="locationpointer"
                  />
                  {image.location}
                </div>
              </div>
            ) : (
              <div
                className=""
                style={{
                  padding: "20px",
                  color: "#FFF",
                  background: "#3a3a3a3a",
                  position: "relative",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarColor: "transparent transparent",
                  height: "350px",
                }}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop3"
                onClick={() => postImage(image)}
              >
                <h3>{image.title}</h3>
                <div>
                  {image.description.split(/(?=\d+\.\s)/).map((desc, index) => (
                    <p key={index}>{desc}</p>
                  ))}
                </div>
              </div>
            )}

            <div className="card-body">
              <div className="row   rowcomments">
                <div className="col-12 mb-2 mt-3">
                  <div className="row ">
                    <div
                      className="col-2 "
                      onClick={(e) => e.stopPropagation()}
                    >
                      <img
                        className="iconsize"
                        src={likedPostss[image.post_id] ? likecon : TM_likeIcon}
                        onClick={() => {
                          handleLikeClick(image.post_id);
                        }}
                        alt="Like"
                      />
                      <span className="ms-2 countsize">
                        {image.likes_count}
                      </span>
                    </div>

                    <div className="col-2 ">
                      <img className="TM_Ellipse iconsize" src={TM_Ellipse} />
                      <span className="ms-2 countsize">
                        {image.comments_count}
                      </span>
                    </div>
                    <div className="col-2 iconcontainer">
                      <img
                        className="TM_bucketlist iconsize"
                        src={TM_bucketlist}
                        onClick={() => togglePopup(image.post_id)}
                        alt="Bucket list icon"
                      />

                      <span className="ms-2 countsize">
                        {image.bucket_list_count}
                      </span>
                      {isPopupVisible && selectedPostId === image.post_id && (
                        <div>
                          <BucketListPopup
                            boards={boards}
                            postId={selectedPostId}
                            selectedImageData={selectedImage}
                            onRefresh={fetchPosts}
                            onClose={closePopup}

                            // onClose={setIsPopupVisible(false)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-6">
                      {/* <img className="TM_ShareIcon" src={TM_ShareIcon} /> */}
                    </div>
                  </div>
                </div>

                <div style={{ color: "white" }} className="card-text">
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                    }}
                  >
                    {/* Conditional rendering based on post type */}
                    {image.post_type !== "note" && (
                      <>
                        <p style={{ margin: 0, paddingRight: "10px" }}>
                          {isExpanded
                            ? image.description
                            : `${image.description.slice(0, 100)}...`}
                        </p>

                        {Array.isArray(image.hash_tags) ? (
                          image.hash_tags.map((tag, index) => (
                            <span
                              key={index}
                              onClick={() => handleHashtagClick(tag)}
                              style={{
                                marginLeft: index > 0 ? "10px" : "0",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                color: "#D2C2FF",
                              }}
                            >
                              {tag}
                            </span>
                          ))
                        ) : (
                          <span style={{ whiteSpace: "nowrap" }}>
                            {image.hash_tags}
                          </span>
                        )}

                        {image.description.length > 100 && (
                          <p
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleText();
                            }}
                            style={{ marginLeft: "10px" }}
                          >
                            {isExpanded ? "read less" : "read more"}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="comments">
                  <input
                    type="text"
                    className=""
                    placeholder="Write a comment..."
                    // value={typeof postComments[image.post_id] === 'string' ? postComments[image.post_id] : ''}
                    value={commentInputs[image?.post_id] || ""}
                    onChange={(e) => handleCommentChange(e, image.post_id)} // Update the specific comment for this postId
                    onKeyPress={(e) => handleKeyPress(e, image.post_id)}
                  />
                  <img
                    src={TM_Vector19}
                    alt="Submit Icon"
                    className="commentpost"
                    onClick={() => handleCommentSubmit(image.post_id)} // Submit the specific comment for this postId
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button
        className="see-more-option"
        onClick={handleSeeMoreClick}
        disabled={loadingMore}
      >
        {loadingMore ? "Loading..." : "See More"}
      </button>

      {/* PopupRecentPosts  Start */}

      <div
        className="modal fade  model_w1000"
        id="staticBackdrop3"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content  modelContent">
            <div className="modal-content-1">
              <div className=" popularContaintpopup ">
                {/* PopupPost Start */}
                <button
                  data-bs-dismiss="modal"
                  onClick={postImageCost}
                  className="popular_popup_closebutton"
                >
                  X
                </button>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 ">
                    <div
                      className="popupcard-img-top-container popupcard-img-top-container-2"
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      {selectedImage?.post_type === "post" ? (
                        <>
                          <img
                            className="popupcard-img-top  ms-2"
                            src={selectedImage?.image_url}
                            alt="Selected Card"
                          />
                          <div
                            className="ms-2 popuplocation-map-kd"
                            onClick={() =>
                              handleLocationClick(selectedImage?.location)
                            }
                            style={{
                              position: "absolute",
                              bottom: "8px",
                              left: "8px",
                              color: "white",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              padding: "3px 9px",
                              borderRadius: "15px",
                              fontSize: "0.9em",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={locationpointer}
                              alt="Location pointer"
                              className="popup_post_Location"
                            />
                            {selectedImage?.location}
                          </div>
                        </>
                      ) : (
                        <div
                          className="description-popup-content"
                          style={{
                            color: "white",
                            padding: "20px",
                            // textAlign: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "15px",
                            margin: "10px",
                            fontSize: "0.9em",
                            height: "500px",
                            width: "520px",
                            paddingLeft: "15px",
                            overflowY: "scroll",
                            scrollbarWidth: "thin",
                            scrollbarColor: "transparent transparent",
                          }}
                        >
                          <h3>{selectedImage?.title}</h3>

                          {/* <p>{selectedImage?.description}</p> */}
                          <div>
                            {selectedImage?.description
                              .split(/(?=\d+\.\s)/)
                              .map((desc, index) => (
                                <p key={index}>{desc}</p>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="card-body">
                      <div className="row   popuprowcomments ">
                        <div className="col-12 mb-3 mt-1">
                          <div className="row ">
                            <div
                              className="col"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <img
                                src={
                                  likedPostss[selectedImage?.post_id]
                                    ? likecon
                                    : TM_likeIcon
                                }
                                onClick={() => {
                                  console.log(
                                    "Liked:",
                                    likedPostss[selectedImage?.post_id]
                                  );
                                  handleLikeClick(selectedImage?.post_id, true);
                                }}
                                alt="Like"
                              />

                              <span className="ms-2">
                                {selectedImage?.likes_count}
                              </span>
                            </div>

                            <div className="col">
                              <img className="TM_Ellipse" src={TM_Ellipse} />{" "}
                              <span className="ms-2">
                                {selectedImage?.comments_count}
                              </span>
                            </div>
                            <div className="col-3 iconcontainer">
                              <img
                                className="TM_bucketlist "
                                src={TM_bucketlist}
                                onClick={() =>
                                  togglePopup(selectedImage?.post_id)
                                }
                              />

                              <span className="ms-2">
                                {selectedImage?.bucket_list_count}
                              </span>
                              {isPopupVisible &&
                                selectedPostId === selectedImage?.post_id && (
                                  <div>
                                    <BucketListPopup
                                      boards={boards}
                                      postId={selectedPostId}
                                      selectedImageData={selectedImage}
                                      onRefresh={fetchPosts}
                                      onClose={closePopup}

                                      // onClose={setIsPopupVisible(false)}
                                    />
                                  </div>
                                )}
                            </div>
                            <div className="col-3">
                              {/* <img
                                className="TM_ShareIcon"
                                src={TM_ShareIcon}
                              /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-lg-6 col-md-6 col-sm-12 mt-2 popupprofile-tp"
                    style={{ color: "white" }}
                  >
                    <div className="popupprofile">
                      <img
                        className="profilelogoimage ms-2"
                        src={selectedImage?.user.user_profile_logo}
                        alt="Card image"
                      />
                      {selectedImage?.user.username}
                    </div>
                    <div className="popupDescription">
                      {selectedImage?.post_type !== "note" &&
                        selectedImage?.description}
                      {/* {selectedImage?.hash_tags} */}
                      {Array.isArray(selectedImage?.hash_tags) ? (
                        selectedImage?.hash_tags.map((tag, index) => (
                          <span
                            className="ms-2"
                            key={index}
                            onClick={() => handleHashtagClick(tag)}
                            style={{
                              marginLeft: index > 0 ? "10px" : "0",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              color: "#D2C2FF",
                            }}
                          >
                            {tag}
                          </span>
                        ))
                      ) : (
                        <span className="ms-1" style={{ whiteSpace: "nowrap" }}>
                          {selectedImage?.hash_tags}
                        </span>
                      )}
                    </div>

                    <div className="popupcommentscontaint">
                      {selectedImage &&
                      Array.isArray(postComments[selectedImage.post_id]) ? (
                        postComments[selectedImage.post_id].map((comment) => (
                          <div
                            className="comment-row mb-2"
                            key={comment.comment_id}
                          >
                            <div className="d-flex align-items-center">
                              <div className="comment-profile-img">
                                {comment.user_profile_logo ? (
                                  <img
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      borderRadius: "50%",
                                    }}
                                    className="commemts_profile_logo"
                                    src={comment.user_profile_logo}
                                    alt="Profile"
                                  />
                                ) : (
                                  <div className="placeholder"></div>
                                )}
                              </div>
                              <div className="ms-2">
                                <div className="commentusername mb-1">
                                  {comment.user_name || "UserName"}
                                </div>
                              </div>
                              <div className="commenttimestamp">
                                {comment.time_since}
                              </div>
                            </div>
                            <div className="usercomment mb-1">
                              {comment.comment_text}
                            </div>
                            <div className="replycomment d-flex align-items-center">
                              <button
                                className="btn btn-link p-0 me-3"
                                style={{
                                  textDecoration: "none",
                                  color: "#B2B2B2",
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                }}
                                onClick={() =>
                                  handleReplyClick(comment.comment_id)
                                }
                              >
                                Reply
                              </button>
                              <button
                                className="btn p-0"
                                onClick={() =>
                                  handleCommentLike(
                                    comment.comment_id,
                                    selectedImage.post_id,
                                    comment.liked_by_current_user
                                  )
                                }
                              >
                                <img
                                  src={
                                    comment.liked_by_current_user
                                      ? likecon
                                      : TM_likeIcon
                                  }
                                  style={{ width: "15px" }}
                                  alt="Like"
                                />
                                <span className="comment_likes ms-1">
                                  {comment.likes}
                                </span>
                              </button>
                              {comment.reply_count > 0 && (
                                <button
                                  className="btn btn-link"
                                  style={{
                                    textDecoration: "none",
                                    color: "#fff",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                  }}
                                  onClick={() =>
                                    toggleRepliesVisibility(comment.comment_id)
                                  }
                                >
                                  ({comment.reply_count}) Replies
                                </button>
                              )}
                            </div>
                            {repliesVisibility[comment.comment_id] &&
                              comment.replies && (
                                <div className="replies">
                                  {comment.replies.map((reply) => (
                                    <div
                                      key={reply.comment_id}
                                      className="reply-row align-items-center mb-2"
                                    >
                                      <div className="col-md-2 reply-user-profile d-flex">
                                        <img
                                          className="commemts_profile_logo"
                                          src={reply.user_profile_logo}
                                          alt="Profile"
                                        />
                                        <div className="reply-username">
                                          {reply.user_name}
                                        </div>
                                      </div>
                                      <div className="col-md-10">
                                        <div className="reply-text">
                                          {reply.comment_text}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            {activeReply === comment.comment_id && (
                              <div className="reply-input-container">
                                <textarea
                                  type="text"
                                  className="reply-input"
                                  value={replyText[comment.comment_id] || ""}
                                  onChange={(e) =>
                                    handleReplyTextChange(
                                      comment.comment_id,
                                      e.target.value
                                    )
                                  }
                                  placeholder="Write a reply..."
                                ></textarea>
                                <div className="reply-buttons">
                                  <button
                                    className="reply-button send"
                                    onClick={(e) =>
                                      handleSendReply(
                                        selectedImage.post_id,
                                        comment.comment_id,
                                        e
                                      )
                                    }
                                  >
                                    Send
                                  </button>
                                  <button
                                    className="reply-button cancel"
                                    onClick={handleCancelReply}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <div>No comments to display.</div>
                      )}
                    </div>
                    <div className="popupcomments mb-3">
                      <form
                        onSubmit={(e) =>
                          handlePopupCommentSubmit(selectedImage?.post_id, e)
                        }
                      >
                        <input
                          ref={commentInputRef}
                          type="text"
                          placeholder="Add a comment"
                          value={popupCommentInput}
                          onChange={handlePopupCommentChange}
                        />
                        <button type="submit" className="comment-submit">
                          <img src={TM_Vector19} alt="Submit" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentPosts;
