import React, { useState, useEffect } from "react";
// import AddIcon from "@mui/icons-material/Add";
import "./UserBoards.css";
import axios from "axios";
import Getboards from "./GetBoards/Getboards";
import Config from "../../../Assets/Config";
import BoardCreationModal from "../BoardCreationModal/BoardCreationModal";
import { toast } from "react-toastify";
const UserBoards = () => {
  const [boardselectedimage, setboardSelectedImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [createboard, setCreateBoard] = useState({
    board_name: "",
    is_public: false,
    location: "",
    user_id: Number(sessionStorage.getItem("user_id")),
  });
  const [existingBoards, setExistingBoards] = useState([]);
  const publicId = sessionStorage.getItem("public_id");

  useEffect(() => {
    const fetchBoards = async () => {
      try {
        const response = await axios.get(
          `${Config.apiBaseUrl}bucketlist/boards/${publicId}`
        );
        setExistingBoards(response.data.data.boards || []);
      } catch (error) {
        console.error("Error fetching existing boards:", error);
      }
    };
    fetchBoards();
  }, []);

  const changeHnadler = (e) => {
    setCreateBoard({ ...createboard, [e.target.name]: e.target.value });
  };
  const closeboardpopup = () => {
    createboard.board_name = "";
    setboardSelectedImage(null);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    // Check if the board name already exists
    const boardExists = existingBoards.some(
      (board) =>
        board.title.toLowerCase() === createboard.board_name.toLowerCase()
    );
    if (boardExists) {
      toast.info("Board name already exists");
      return;
    }
    console.log("createboard", createboard);
    const formData = new FormData();
    formData.append("user_id", createboard.user_id);
    formData.append("board_name", createboard.board_name);
    formData.append("image", selectedFile);
    formData.append("location", createboard.location);
    formData.append("is_public", "false");
    console.log("formData", formData);
    try {
      //
      const response = await axios.post(`${Config.apiBaseUrl}boards`, formData);

      if (response.status === 200) {
        toast.success("Board Created Successfully");
        closeboardpopup();
        window.location.reload();
        setRefresh(true);
      }
    } catch (error) {
      console.error("Post failed", error);
      toast.error("Board failed to Create");
      setRefresh(true);
    }
  };

  // const triggerFileInput = () => {
  //   document.getElementById("fileInput").click();
  // };
  const triggerFileInput = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setboardSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      // Update the board state to include the new image file
      setCreateBoard((prevBoard) => ({
        ...prevBoard,
        image: file,
      }));
    } else {
      console.log("No image file selected");
    }

    // Reset the file input
    e.target.value = "";
  };

  useEffect(() => {
    // Logic to refresh after adding a new board
    if (refresh) {
      // You may fetch new board data here if needed
      setRefresh(false); // Reset the refresh trigger
    }
  }, [refresh]);

  const postImageCost = (e) => {
    e.preventDefault();

    setboardSelectedImage(null);
  };

  const postImageCancel = (e) => {
    e.preventDefault();
  };

  return (
    <div className="d-flex">
      <Getboards />

      <form onSubmit={submitHandler}>
        <BoardCreationModal
          createboard={createboard}
          changeHandler={changeHnadler}
          boardselectedimage={boardselectedimage}
          handleFileChange={handleFileChange}
          triggerFileInput={triggerFileInput}
          postImageCost={postImageCost}
          submitHandler={submitHandler}
        />
      </form>
    </div>
  );
};

export default UserBoards;
