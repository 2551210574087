import axios from "axios";
import Config from "./Config";
export const PostLikes = async (likeData) => {
  console.log("######################", likeData);
  try {
    const response = await axios.post(`${Config.apiBaseUrl}likes`, likeData);
    console.log("######################", response);
    return response.data;
  } catch (error) {
    throw error;
  }
};
