import React, { useState, useEffect } from "react";
import "./Signupprocess.css";
import TM_Logo from "./../../../Assets/Images/TM_Logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer";
import axios from "axios";
import Config from "../../../Assets/Config";
import SignupBackButton from "./../../../Assets/Images/Backbutton.png";
import Profileselect from "../../../Assets/Images/Profileselect.png";
import { toast } from "react-toastify";

const Signupprocess = () => {
  const [usernameCondition, setUsernameCondition] = useState(false);
  const [firstNameCondition, setFirstNameCondition] = useState(true);
  const [profileCondition, setProfileCondition] = useState(false);

  const [selectedFilepost, setselectedFilepost] = useState(null);
  const [selectedImagefile, setSelectedImagefile] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = () => {
    if (profileCondition) {
      setProfileCondition(false);
      setUsernameCondition(true);
    } else if (usernameCondition) {
      setUsernameCondition(false);
      setFirstNameCondition(true);
    } else {
      navigate(-1);
    }
  };

  const [formData, setFormData] = useState({
    user_id: "",
    firstName: "",
    lastName: "",
    userName: "",
    logo: "",
  });

  useEffect(() => {
    if (location.state && location.state.user_id) {
      setFormData((prevData) => ({
        ...prevData,
        user_id: location.state.user_id,
      }));
    }
  }, [location.state]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitForm = async () => {
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("user_id", formData.user_id);
    formDataToSubmit.append("first_name", formData.firstName);
    formDataToSubmit.append("last_name", formData.lastName);
    formDataToSubmit.append("user_name", formData.userName);
    if (selectedFilepost) {
      formDataToSubmit.append("logo", selectedFilepost);
    }

    try {
      const response = await axios.put(
        `${Config.apiBaseUrl}user`,
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Form submitted successfully!");
      navigate("/");
    } catch (error) {
      console.error("Failed to submit form:", error);
      setErrors({ form: "Failed to submit form. Please try again." });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (file && allowedTypes.includes(file.type)) {
      setselectedFilepost(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImagefile(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setErrors({
        ...errors,
        file: "Please select a JPEG, JPG, or PNG image file.",
      });
      toast.error("Only JPEG, JPG, and PNG files are accepted.");
    }
  };

  const handleNextClick = () => {
    if (firstNameCondition) {
      if (!formData.firstName.trim()) {
        setErrors({ ...errors, firstName: "First Name is required" });
        return;
      }
      if (!formData.lastName.trim()) {
        setErrors({ ...errors, lastName: "Last Name is required" });
        return;
      }
      setFirstNameCondition(false);
      setUsernameCondition(true);
    } else if (usernameCondition) {
      if (!formData.userName.trim()) {
        setErrors({ ...errors, userName: "User Name is required" });
        return;
      }
      setUsernameCondition(false);
      setProfileCondition(true);
    } else {
      if (!selectedFilepost) {
        setErrors({ ...errors, profile: "Profile picture is required" });
        return;
      }
      submitForm();
    }
  };

  return (
    <div className="signup12-proc">
      <div className="sifnup_fixedNav">
        <nav className=" topnav_color">
          <img src={TM_Logo} alt="TM Logo" />
        </nav>
      </div>

      <div className="signupprocess">
        <center>
          {firstNameCondition && (
            <div className="Signup_padding">
              <div className="back-button" onClick={handleBackClick}>
                <img
                  src={SignupBackButton}
                  alt="Back Button"
                  className="image_backButton"
                />
              </div>
              <div className="heading">What's your name?</div>
              <div>
                <div>
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Firstname"
                    className="inputfield mb-5 "
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && (
                    <div className="Signup_error_msg">{errors.firstName}</div>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Lastname"
                    className="inputfield "
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName && (
                    <div className="Signup_error_msg">{errors.lastName}</div>
                  )}
                </div>
                <div>
                  <button
                    className="signupbutton m-5"
                    onClick={handleNextClick}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          )}

          {usernameCondition && (
            <div className="UsereName_padding">
              <div className="back-button" onClick={handleBackClick}>
                <img
                  src={SignupBackButton}
                  alt="img"
                  className="image_backButton"
                />
              </div>
              <div className="heading">What's your username?</div>
              <div>
                <input
                  type="text"
                  name="userName"
                  placeholder="Username"
                  className="inputfield mb-5"
                  value={formData.userName}
                  onChange={handleChange}
                />
                {errors.userName && (
                  <div className="Signup_error_msg">{errors.userName}</div>
                )}
              </div>
              <div>
                <button className="signupbutton" onClick={handleNextClick}>
                  Next
                </button>
              </div>
            </div>
          )}

          {profileCondition && (
            <div className="image_selcet_option">
              <div className="back-button" onClick={handleBackClick}>
                <img
                  src={SignupBackButton}
                  alt="Back Button"
                  className="image_backButton"
                />
              </div>
              <div
                className="rounded-circle"
                onClick={() => document.getElementById("fileInput").click()}
              >
                {selectedImagefile ? (
                  <img
                    src={selectedImagefile}
                    className="selected_image rounded-circle"
                    alt="Selected Profile"
                  />
                ) : (
                  <img
                    src={Profileselect}
                    className="rounded-circle"
                    alt="Default Profile"
                  />
                )}
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              <div className="heading">
                {selectedImagefile
                  ? "Lookin' good!"
                  : "Add your profile picture"}
              </div>
              {selectedImagefile && (
                <div>
                  <button className="signupbutton" onClick={submitForm}>
                    Let's go!
                  </button>
                </div>
              )}
            </div>
          )}
        </center>
      </div>
      <Footer />
    </div>
  );
};

export default Signupprocess;
