import React, { useState } from "react";
// import Rectangle59 from "./../../../Assets/Images/Rectangle 59.png";
import TheWay from "./../../../Assets/Images/TheWay.png";
import topdiscoveries2 from "./../../../Assets/Images/topdiscoveries2.png";
import topdiscoveries1 from "./../../../Assets/Images/topdiscoveries1.png";
import topdiscoveries from "./../../../Assets/Images/topdiscoveries.png";
import Alaska from "./../../../Assets/Images/Alaska.png";
import simonReeve from "./../../../Assets/Images/simonReeve.png";
import "./TopDiscoveries.css"; // Make sure the path is correct for your project structure

const TopDiscoveries = () => {
  const [pramotions, setpramotions] = useState([]);
  const vediolen = [
    {
      image: topdiscoveries,
      link: "http://www.voyagetvdemo.com/",
    },
    {
      image: topdiscoveries1,
      link: "http://www.voyagetvdemo.com/",
    },
    {
      image: topdiscoveries2,
      link: "http://www.voyagetvdemo.com/",
    },
  ];
  return (
    <div className="imagewidthContainer">
      {vediolen.map((tv, index) => (
        <div className="" key={index}>
          <a href={tv.link} target="_blank" rel="noopener noreferrer">
            <img
              src={tv.image || Alaska}
              alt={Alaska}
              className=""
              
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = Alaska;
              }}
            />
          </a>
        </div>
      ))}
    </div>
  );
};

export default TopDiscoveries;
